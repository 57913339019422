import React from 'react'
import { FormattedMessage } from 'react-intl'

export const firstQuestionOptions = [
  {
    text: <FormattedMessage id='carer.feedback.options.yes' />,
    value: 'yes'
  },
  {
    text: <FormattedMessage id='carer.feedback.options.no' />,
    value: 'no'
  }
]

export const secondQuestionOptions = [
  {
    text: '1',
    value: '1'
  },
  {
    text: '2',
    value: '2'
  },
  {
    text: '3',
    value: '3'
  },
  {
    text: '4',
    value: '4'
  },
  {
    text: '5',
    value: '5'
  }
]

export const thirdQuestionsOptions = [
  {
    text: <FormattedMessage id='carer.feedback.options.yes' />,
    value: 'yes'
  },
  {
    text: <FormattedMessage id='carer.feedback.options.no' />,
    value: 'no'
  }
]

export const fourthQuestionOptions = [
  {
    text: <FormattedMessage id='carer.feedback.options.fourthquestion.firstoption' />,
    value: 'termination_of_the_employment_contract'
  },
  {
    text: <FormattedMessage id='carer.feedback.options.fourthquestion.secondoption' />,
    value: 'voluntary_resignation'
  },
  {
    text: <FormattedMessage id='carer.feedback.options.other' />,
    value: 'other'
  }
]

export const fifthQuestionOptions = [
  {
    label: <FormattedMessage id='carer.feedback.options.fifthquestion.firstoption' />,
    value: 'vocation'
  },
  {
    label: <FormattedMessage id='carer.feedback.options.fifthquestion.secondoption' />,
    value: 'empathy'
  },
  {
    label: <FormattedMessage id='carer.feedback.options.fifthquestion.thirdoption' />,
    value: 'patience'
  },
  {
    label: <FormattedMessage id='carer.feedback.options.fifthquestion.fourthoption' />,
    value: 'being_helpful'
  },
  {
    label: <FormattedMessage id='carer.feedback.options.other' />,
    value: 'other'
  }
]

export const sixthQuestionOptions = [
  {
    label: <FormattedMessage id='carer.feedback.options.sixthquestion.firstoption' />,
    value: 'amiability'
  },
  {
    label: <FormattedMessage id='carer.feedback.options.sixthquestion.secondoption' />,
    value: 'attitude'
  },
  {
    label: <FormattedMessage id='carer.feedback.options.sixthquestion.thirdoption' />,
    value: 'flexibility'
  },
  {
    label: <FormattedMessage id='carer.feedback.options.sixthquestion.fourthoption' />,
    value: 'puntuality'
  },
  {
    label: <FormattedMessage id='carer.feedback.options.sixthquestion.fifthoption' />,
    value: 'performing_tasks'
  },
  {
    label: <FormattedMessage id='carer.feedback.options.other' />,
    value: 'other'
  }
]
