import React from 'react'
import {connect} from 'react-redux'
import { path } from 'ramda'
import { updateProfile, uploadPhoto, updateUsernameInProfile } from 'store/profile/actions'
import { updateUser } from 'seniors-first-commons/shared/store/user/actions'
import AccountSettingsLayout from 'components/templates/AccountSettingsLayout'

const AccountSettings = ({
  helpers,
  user,
  profile,
  loadingUpdates,
  uploadingPhoto,
  uploadPhoto,
  updateUser,
  updateProfile,
  updateUsernameInProfile
}) => {
  const personalData = path(['data', 'profile'], profile)

  const onFormSubmit = ({gender, birthday, firstname, lastname, receiveNotifications, receiveEmailNotifications}, { setSubmitting }) => {
    const originalGender = personalData?.gender
    const originalBirthday = personalData?.birthday

    if(gender !== originalGender || !birthday.isSame(originalBirthday)) {
      updateProfile({
        ...profile.data.profile,
        birthday,
        gender
      })
    }
    if (firstname !== user.firstname || lastname !== user.lastname || receiveNotifications !== user.receiveNotifications || receiveEmailNotifications !== user.receiveEmailNotifications) {
      updateUser({
        firstname,
        lastname,
        receiveEmailNotifications,
        receiveNotifications,
        success: 'success.successfullChange'
      })
        .unwrap()
        .then((response) => updateUsernameInProfile({
          ...profile.data,
          firstname: response.firstname,
          lastname: response.lastname
        }))
    }

    setSubmitting(false)
  }


  return (
    <AccountSettingsLayout
      actions={{
        onFormSubmit,
        uploadPhoto
      }}
      helpers={helpers}
      loading={{ loadingUpdates, uploadingPhoto }}
      profile={profile}
      user={user}
    />
  )
}

const mapStateToProps = (state) => {
  return {
    helpers: state.helpers.helpers,
    loadingUpdates: state.user.user.updatingUserData || state.profile.profile.updatingProfileData,
    profile: state.profile.profile,
    uploadingPhoto: state.profile.profile.loadingPhotoUpdate,
    user: state.user.user.data
  }
}

const mapDispatchToProps = {
  updateProfile,
  updateUser,
  updateUsernameInProfile,
  uploadPhoto
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountSettings)
