import React from 'react'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import CustomModal from 'seniors-first-commons/components/atoms/CustomModal'
import SelectMultiSelect from 'seniors-first-commons/components/atoms/SelectMultiSelect'
import classes from './index.module.css'


export const ServiceModal = ({
  title,
  description,
  data = [],
  isOpen,
  onClose,
  selected,
  onRemove,
  onSelect,
  onSelectAll
}) => {
  const selectAllHandler = () => onSelectAll(data)
  return (
    <CustomModal
      additionalClasses={classes.modal}
      onClose={onClose}
      open={isOpen}
    >
      <div className='has-text-centered'>
        <h2 className='t2-bold primary has-margin-b-7'>
          <FormattedMessageWithClass id={title} />
        </h2>
        <p className='p1-regular grey-m has-margin-bt-7'>
          <FormattedMessageWithClass id={description} />
        </p>
        <SelectMultiSelect
          key={`select-${title}`}
          onRemove={onRemove}
          onSelect={onSelect}
          onSelectAll={selectAllHandler}
          options={data}
          selected={selected}
        />

        <p>
          <button
            className={`filled has-margin-t-6 ${classes.mainButton}`}
            onClick={onClose}
            type='button'
          >
            <FormattedMessageWithClass id='app.boarding.offeredservices.servicemodal.save' />
          </button>
        </p>
      </div>
    </CustomModal>
  )
}
