import * as Yup from 'yup'


export const PersonalDataSchema = Yup.object().shape({
  birthday: Yup.date().typeError('validations.required').required('validations.required'),
  firstname: Yup.string().required('validations.required'),
  gender: Yup.string().required('validations.required'),
  lastname: Yup.string().required('validations.required'),
  nationalities: Yup.array().min(1, 'validations.selectone').required('validations.required')
})

export const ContactDetailsSchema = Yup.object().shape({
  city: Yup.object().shape({
    address_lat: Yup.number().required('validations.required'),
    formatted_address: Yup.string().required('validations.required')
  }),
  country: Yup.object().shape({
    label: Yup.string().required('validations.required'),
    value: Yup.number().required('validations.required')
  }).required('validations.required'),
  phone: Yup.string()
    .min(4, 'validations.phonetooshort')
    .required('validations.required')
})
