import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import { path } from 'ramda'
import useMediaQuery from 'seniors-first-commons/services/useMediaQuery'
import { useFecthDataOnCondition } from 'seniors-first-commons/shared/hooks'
import { getJobPosts, getPendingOffersAmount } from 'store/offers/actions'
import {
  getCarerData,
  updateAvailability,
  updateOnlyCareServices,
  updateWorkingLocation,
  uploadPhoto
} from 'store/profile/actions'
import { getServices } from 'seniors-first-commons/shared/store/helpers/actions'
import {
  isFormCompleted,
  isVideoCompleted,
  getCompletionPercentage
} from 'store/profile/selector'
import AppContainer from 'components/organisms/AppContainer'
import TwoColumnsLayout from 'components/templates/TwoColumnsLayout'
import FirstColumn from './FirstColumn'
import SecondColumn from './SecondColumn'
import { BOARDING_ROUTES, HOME_FORM_MAP } from 'seniors-first-commons/utils/constants'
import { BREAKPOINTS } from 'seniors-first-commons/styles/constants'

const Home = ({
  user,
  services,
  jobPosts,
  pendingOffers,
  isPersonalDataCompleted,
  isOfferedServicesCompleted,
  isHiringCompleted,
  completionPercentage,
  profile,
  updatingPartialProfileData,
  loadingServices,
  uploadingPhoto,
  completedProfile,
  getJobPosts,
  getPendingOffersAmount,
  updateAvailability,
  updateWorkingLocation,
  updateOnlyCareServices,
  getCarerData,
  getServices,
  uploadPhoto,
  ...props
}) => {
  const allStepsEnabled = isPersonalDataCompleted && isOfferedServicesCompleted && isHiringCompleted
  const mediaQuery = useMediaQuery()
  const matchesMobile = mediaQuery[0] <= BREAKPOINTS.MOBILE_MAX
  const matchesTablet = mediaQuery[0] >= BREAKPOINTS.TABLET_MIN && mediaQuery[0] <= BREAKPOINTS.TABLET_MIN
  const matchesDesktop = mediaQuery[0] >= BREAKPOINTS.DESKTOP_MIN && mediaQuery[0] <= BREAKPOINTS.DESKTOP_MAX
  const pathname = props.location.pathname.split('/')
  const currentRoute = pathname[2]

  const itemsList = [
    {
      enabled: true,
      route: BOARDING_ROUTES.personalData,
      text: 'carer.home.profileheader.routes.personalInfo'
    },
    {
      enabled: isPersonalDataCompleted,
      route: BOARDING_ROUTES.offeredServices,
      text: 'carer.home.profileheader.routes.services'
    },
    {
      enabled: isOfferedServicesCompleted,
      route: BOARDING_ROUTES.hiring,
      text: 'carer.home.profileheader.routes.contracts'
    },
    {
      enabled: allStepsEnabled,
      route: BOARDING_ROUTES.education,
      text: 'carer.home.profileheader.routes.education'
    },
    {
      enabled: allStepsEnabled,
      route: BOARDING_ROUTES.illnessExperiences,
      text: 'carer.home.profileheader.routes.experiences'
    },
    {
      enabled: allStepsEnabled,
      route: BOARDING_ROUTES.video,
      text: 'carer.home.profileheader.routes.videoCV'
    }
  ].map(item => {
    item.route += '?from=home'
    return item
  })

  const offeredServices = path(['data', 'offered_service'], profile)

  const fetchJobPosts = useCallback(() => {
    if(completedProfile){
      getJobPosts({
        page: 1,
        payload: offeredServices,
        per_page: 8
      })
    }
    // eslint-disable-next-line
  }, [offeredServices, getJobPosts])

  useFecthDataOnCondition(() => {
    fetchJobPosts()
  }, completedProfile)

  useFecthDataOnCondition(() => {
    getPendingOffersAmount()
  }, completedProfile)

  const handlePageChange = (e) => {
    if (jobPosts.currentPage !== e) {
      getJobPosts({
        page: e,
        payload: offeredServices,
        per_page: 8
      })
    }
    else return
  }

  return (
    <AppContainer name='Home'>
      <TwoColumnsLayout
        firstColumn={(
          <FirstColumn
            actions={{
              getCarerData,
              getServices,
              updateAvailability,
              updateOnlyCareServices,
              updateWorkingLocation,
              uploadPhoto
            }}
            completionPercentage={completionPercentage}
            itemsList={itemsList}
            loading={{
              loadingServices,
              updatingPartialProfileData,
              uploadingPhoto
            }}
            profile={profile}
            services={services}
            user={user}
          />
        )}
        isHiddenFirstColumn={matchesMobile && currentRoute !== 'profile'}
        isHiddenSecondColumn={matchesMobile && currentRoute === 'profile'}
        matchesMobile={matchesMobile}
        secondColumn={(
          <SecondColumn
            handlePageChange={handlePageChange}
            jobPosts={jobPosts}
            matchesDesktop={matchesDesktop}
            matchesMobile={matchesMobile}
            matchesTablet={matchesTablet}
            pendingOffers={pendingOffers}
          />
        )}
      />
    </AppContainer>
  )
}

const mapStateToProps = (state) => {
  return {
    completedProfile: state.profile.completedProfile,
    completionPercentage: getCompletionPercentage(state),
    isHiringCompleted: isFormCompleted(state)(HOME_FORM_MAP['hiring']),
    isOfferedServicesCompleted: isFormCompleted(state)(HOME_FORM_MAP['services']),
    isPersonalDataCompleted: isFormCompleted(state)(HOME_FORM_MAP['profile']),
    isVideoCompleted: isVideoCompleted(state)(HOME_FORM_MAP['video']),
    jobPosts: state.offers.jobPosts,
    loadingServices: state.helpers.helpers.loadingServices,
    pendingOffers: state.offers.pendingDirectOffers,
    profile: state.profile.profile,
    services: state.helpers.helpers.services,
    updatingPartialProfileData: state.profile.updatingPartialProfileData,
    uploadingPhoto: state.profile.profile.loadingPhotoUpdate,
    user: state.user.user.data
  }
}

const mapDispatchToProps = {
  getCarerData,
  getJobPosts,
  getPendingOffersAmount,
  getServices,
  updateAvailability,
  updateOnlyCareServices,
  updateWorkingLocation,
  uploadPhoto
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
