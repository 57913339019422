import React from 'react'
import { useFormatMessage } from 'seniors-first-commons/shared/store/translations/effects'
import CustomModal from 'seniors-first-commons/components/atoms/CustomModal'
import classes from './index.module.css'


const ExperienceModal = ({
  isOpen,
  onConfirm,
  onSkip,
  onClose
}) => {

  const translations = {
    confrimButton: useFormatMessage('app.boarding.modal.experience.add'),
    details: useFormatMessage('app.boarding.modal.experience.text'),
    skipButton: useFormatMessage('app.boarding.modal.experience.skip'),
    title: useFormatMessage('app.boarding.modal.experience.title')
  }

  return (
    <CustomModal
      hasCloseButton={false}
      onClose={onClose}
      open={isOpen}
      styles={{ maxWidth: '450px', width: '95%' }}
    >
      <div className='has-text-centered'>
        <p className='t2-bold primary'>
          {translations.title}
        </p>

        <p className='p1-bold has-margin-b-6 has-margin-t-5'>
          {translations.details}
        </p>

        <div className={classes.buttonsContainer}>
          <button
            className={`button filled has-margin-t-7 ${classes.button}`}
            onClick={onConfirm}
          >
            {translations.confrimButton}
          </button>

          <button
            className='button button-text'
            onClick={onSkip}
          >
            <span className='p2-bold'>{translations.skipButton}</span>
          </button>
        </div>
      </div>
    </CustomModal>
  )
}

export default ExperienceModal
