import React, { useState } from 'react'
import { pathOr } from 'ramda'
import { useFormatMessage } from 'seniors-first-commons/shared/store/translations/effects'
import { formatToMatchEnv } from 'seniors-first-commons/utils/helpers'
import LanguageSwitch from 'seniors-first-commons/components/organisms/LanguageSwitch'
import NavbarCircleIcon from 'seniors-first-commons/components/atoms/NavbarCircleIcon'
import NavbarAvatar from 'seniors-first-commons/components/atoms/NavbarAvatar'
import NavbarDropdown from 'seniors-first-commons/components/atoms/NavbarDropdown'
import ClientsLogo from 'seniors-first-commons/components/atoms/images/logo2.svg'
import {
  BellIcon,
  BurgerIcon
} from 'seniors-first-commons/components/atoms/images/icons'
import dummyImage from 'seniors-first-commons/components/atoms/images/user.png'
import { ROUTE } from 'root/constants'
import classes from './index.module.css'


const AppNavbar = ({
  matchesMobile,
  user,
  notReadNotifications,
  onLogout,
  changeRoute,
  activeRoute
}) => {
  const [ openBurger, setOpenBurger ] = useState(false)
  const firstname = pathOr('', ['firstname'], user)
  const photo = pathOr(dummyImage, ['photo'], user)

  const onToggleBurgerMenu = () => {
    setOpenBurger(!openBurger)
  }

  const dropdownItems = [
    {
      hide: !matchesMobile,
      isActive: activeRoute && activeRoute(ROUTE.HOME),
      name: 'app.general.navbaritems.postedOffers',
      onClick: () => { changeRoute(ROUTE.HOME); onToggleBurgerMenu() }
    },
    {
      isActive: activeRoute && activeRoute(ROUTE.ACCOUNT_SETTINGS),
      name: 'app.general.navbaritems.accountSettings',
      onClick: () => { changeRoute(ROUTE.ACCOUNT_SETTINGS); onToggleBurgerMenu() }
    },
    {
      hide: !matchesMobile,
      isActive: activeRoute && activeRoute(ROUTE.PROFILE),
      name: 'app.general.navbaritems.profile',
      onClick: () => { changeRoute(ROUTE.PROFILE); onToggleBurgerMenu() }
    },
    {
      isActive: activeRoute && activeRoute(ROUTE.DIRECT_OFFERS),
      name: 'app.general.navbaritems.directoffers',
      onClick: () => { changeRoute(`${ROUTE.DIRECT_OFFERS}?tab=pending`); onToggleBurgerMenu() }
    },
    {
      external: useFormatMessage(formatToMatchEnv('app.general.faq.link')),
      isActive: false,
      name: 'app.general.navbaritems.faq',
      onClick: () => onToggleBurgerMenu()
    },
    {
      isActive: false,
      name: 'app.general.navbaritems.logout',
      onClick: () => onLogout()
    }
  ]

  return (
    <nav className={`navbar is-fixed-top ${classes.navbar}`} role='navigation'>
      <div className='level full-width is-mobile'>
        <div className='level-left'>
          {/* eslint-disable-next-line */}
          <a onClick={() => changeRoute(ROUTE.HOME)}>
            <img alt='' height='32px' src={ClientsLogo} width='143px' />
          </a>
        </div>

        <div className='level-right'>
          <div className='level-item'>
            <div className='has-margin-r-4 is-hidden-mobile'>
              <LanguageSwitch
                additionalClasses={classes.languageSwitch}
                openToTop={false}
              />
            </div>

            <div className='level-item'>
              <NavbarCircleIcon
                badge={notReadNotifications}
                Icon={BellIcon}
                isActive={activeRoute && activeRoute(ROUTE.NOTIFICATIONS)}
                onClick={() => changeRoute(ROUTE.NOTIFICATIONS)}
                tooltip='apps.general.navbar.notifications.tooltip'
              />
            </div>

            <div className='level-item is-hidden-desktop is-relative'>
              <NavbarCircleIcon
                badge={null}
                filled
                Icon={BurgerIcon}
                isActive={openBurger}
                onClick={onToggleBurgerMenu}
              />

              <div
                className={`is-hidden-desktop ${classes.burgerMenu}`}
                style={{ display: openBurger ? 'block' : 'none' }}
              >
                <NavbarDropdown dropdownItems={dropdownItems} />
              </div>
            </div>

            <div className='level-item is-hidden-touch'>
              <NavbarAvatar
                dropdownItems={dropdownItems}
                firstname={firstname}
                photo={photo}
              />
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default AppNavbar
