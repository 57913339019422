import React from 'react'
import moment from 'moment'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import { AppliedIcon } from 'seniors-first-commons/components/atoms/images/icons'


export const PostHeader = ({
  matchesMobile,
  matchesDesktop,
  posted_until,
  status
}) => {
  const today = moment(0, 'HH')
  const formattedPostedUntil = posted_until.format('L')
  const difference = posted_until.diff(today, 'day')

  return (
    <p className={`ellipsis t${matchesMobile ? '4' : matchesDesktop ? '3' : '2'}-bold ${(status === 'accepted' || status === 'pending') ? 'success' : difference < 0 ? 'grey-light' : 'black'}`}
    >
      <FormattedMessageWithClass id={`carer.home.post.${
        status === 'accepted'
          ? 'accepted' : status === 'pending'
            ? 'applied' : difference < 0
              ? 'expired' : difference === 0
                ? 'expiresToday'
                : 'applyBefore'
      }.title`}
      /> {' '}
      {status !== 'accepted' && status !== 'pending' && difference > 0
        ? <span>{formattedPostedUntil}</span>
        : null
      }
    </p>
  )
}

export const PostFooter = ({ id, posted_until, status, onApply }) => {
  const today = moment(0, 'HH')
  const difference = posted_until.diff(today, 'day')

  return (
    <button
      className='button filled'
      disabled={difference < 0 || status === 'accepted' || status === 'pending'}
      onClick={() => onApply(id)}
      style={{ height: '38px', width: '189px' }}
    >
      <FormattedMessageWithClass id={`carer.home.post.${status === 'accepted'
        ? 'accepted' : status === 'pending'
          ? 'applied' : difference < 0
            ? 'expired' : difference >= 0
              ? 'applyBefore'
              : 'applyBefore'}.button`}
      />
    </button>
  )
}

export const PostedOfferDetails = ({
  id,
  status,
  posted_until,
  responseLoading,
  onApplyToPost
}) => {
  const today = moment(0, 'HH')
  const difference = posted_until.diff(today, 'day')

  if (difference > 0 && (!status || status === 'has-not-applied')) {
    return (
      <div className='has-text-centered'>
        <button
          className={`button filled ${responseLoading ? 'is-loading' : ''}`}
          disabled={responseLoading}
          onClick={() => onApplyToPost(id)}
          style={{ height: '38px', width: '189px' }}
        >
          <FormattedMessageWithClass
            id='carer.home.post.applyBefore.button'
          />
        </button>
      </div>
    )
  }

  else if (status === 'accepted' || status === 'pending') {
    return (
      <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
        <AppliedIcon fill='#4CC700' height='45' stroke='#4CC700' width='45' />
        <FormattedMessageWithClass
          className='p1-bold success has-padding-t-7'
          id={`carer.home.post.${status === 'accepted' ? 'accepted' : 'applied'}.title`}
        />
      </div>
    )
  }
}
