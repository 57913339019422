import React from 'react'
import { ErrorMessage } from 'formik'
import Input from 'seniors-first-commons/components/atoms/Input'
import DatePicker from 'seniors-first-commons/components/molecules/DatePicker'
import PhoneInput from 'seniors-first-commons/components/atoms/PhoneInput'
import FieldLabel from 'seniors-first-commons/components/atoms/FieldLabel'
import ErrorHandler from 'seniors-first-commons/components/atoms/ErrorHandler'
import Checkbox from 'seniors-first-commons/components/atoms/Checkbox'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'


export const Period = (props) => {
  return (
    <DatePicker
      openDirection='up'
      shouldRenderMonthElement={true}
      {...props}
    />
  )
}

export const ClientName = (props) => {
  return (
    <>
      <FieldLabel
        id={props.field.name}
        labelName='app.boarding.experience.reference.name'
        labelType='p3-bold'
        topSpace={5}
      />
      <Input
        className='input'
        maxLength='20'
        placeholder='MAX: 20'
        type='text'
        wrapperClass='is-marginless'
        {...props}
      />

      <ErrorMessage
        component={ErrorHandler}
        name={props.field.name}
      />
    </>
  )
}

export const ClientEmail = (props) => {
  return (
    <>
      <FieldLabel
        id={props.field.name}
        labelName='app.boarding.experience.reference.email'
        labelType='p3-bold'
        topSpace={3}
      />
      <Input
        className='input'
        placeholder=''
        type='text'
        wrapperClass='is-marginless'
        {...props}
      />
      <ErrorMessage
        component={ErrorHandler}
        name={props.field.name}
      />
    </>
  )
}

export const ClientPhone = (props) => {
  return (
    <>
      <FieldLabel
        id={props.field.name}
        labelName='app.boarding.experience.reference.phone'
        labelType='p3-bold'
        topSpace={3}
      />
      <PhoneInput {...props} />
      <ErrorMessage
        component={ErrorHandler}
        name={props.field.name}
      />
    </>
  )
}

export const Confirmation = (props) => {
  return (
    <div className='has-margin-t-1'>
      <div style={{ alignItems: 'flex-start', display: 'flex', justifyContent: 'flex-start' }}>
        <Checkbox
          checked={props.field.value}
          id={props.field.name}
          style={{ verticalAlign: 'middle' }}
          {...props}
        />
        <label
          className='p3-bold grey has-text-left has-margin-l-7'
          htmlFor={props.field.name}
          style={{ maxWidth: 'fit-content' }}
        >
          <FormattedMessageWithClass id='app.boarding.experience.respnosability' />
        </label>
      </div>
      <ErrorMessage
        component={ErrorHandler}
        name={props.field.name}
      />
    </div>
  )
}
