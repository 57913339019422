
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { pathOr } from 'ramda'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import routingService from 'seniors-first-commons/services/routingService'
import ExperienceCard from 'components/molecules/ExperienceCard'
import ConfirmModal from 'seniors-first-commons/components/atoms/ConfirmModal'
import ProfileBoardingFooter from '../ProfileBoardingFooter'
import { PlusIcon, CloseCircle } from 'seniors-first-commons/components/atoms/images/icons'
import { ROUTE } from 'root/constants'
import classes from './index.module.css'


const Experiences = ({
  comingFrom,
  profile,
  isSubmitting,
  ...props
}) => {
  const [showDeleteExpModal, setShowDeleteExpModal] = useState(false)
  const [expToRemove, setExpToRemove] = useState(null)
  const history = useHistory()

  const experiences = pathOr([], ['experiences'], profile)

  const onContinue = () => {
    routingService.push(history, `${ROUTE.HOME}`)
  }

  const onDeleteExperience = (id) => {
    setExpToRemove(id)
    setShowDeleteExpModal(true)
  }

  const onConfirmExpDelete = () => {
    props.deleteExperience({ experience_id: expToRemove })
      .unwrap()
      .then(() => {
        setExpToRemove(null)
        setShowDeleteExpModal(false)
      })
  }

  const onFooterBackClick = () => {
    history.goBack()
  }

  const onFooterNextClick = () => {
    props.moveToNextStep()
  }

  const onCloseExpDeleteModal = () => {
    setExpToRemove(null)
    setShowDeleteExpModal(false)
  }

  return (
    <div className='has-text-centered'>
      <p className='t1-bold primary hast-text-centered has-padding-b-3'>
        <FormattedMessageWithClass id='app.boarding.routes.experience' />
      </p>

      <div className='has-margin-b-5'>
        <button
          className={`button outlined ${classes.button}`}
          onClick={props.onAddExperience}
        >
          <PlusIcon
            className='outlined-icon'
            fill='#0080F5'
            height='14'
            width='14'
          />
          <FormattedMessageWithClass
            id='app.boarding.experience.experience.addworkexperience'
          />
        </button>

        {comingFrom === 'home' &&
          <button
            className={`button outlined ${classes.button}`}
            onClick={onContinue}
          >
            <FormattedMessageWithClass id='app.boarding.experience.experience.continue' />
          </button>
        }
      </div>

      <div className='columns is-multiline is-centered is-8'>
        {experiences.map((exp, index) => {
          return (
            <div
              className={'column is-half-tablet is-one-third-fullhd'}
              key={index}
            >
              <ExperienceCard
                {...exp}
                onDeleteExperience={onDeleteExperience}
                onEditExperience={props.onEditExperience}
              />
            </div>
          )
        })}

        {comingFrom !== 'home' &&
          <ProfileBoardingFooter
            onBack={onFooterBackClick}
            onNext={onFooterNextClick}
            step={5}
          />
        }
      </div>

      <ConfirmModal
        Icon={CloseCircle}
        loading={isSubmitting}
        onCancel={onCloseExpDeleteModal}
        onClose={onCloseExpDeleteModal}
        onConfirm={onConfirmExpDelete}
        show={showDeleteExpModal}
        title='app.boarding.experience.experience.delete'
      />
    </div>
  )
}

export default Experiences
