import React from 'react'
import { Field, ErrorMessage } from 'formik'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import FieldLabel from 'seniors-first-commons/components/atoms/FieldLabel'
import ErrorHandler from 'seniors-first-commons/components/atoms/ErrorHandler'
import Radios from 'seniors-first-commons/components/atoms/Radios'
import AdvancedCheckbox from 'seniors-first-commons/components/atoms/AdvancedCheckbox'
import AddressAutoSuggest from 'seniors-first-commons/components/atoms/AddressAutoSuggest'
import { Availability } from 'seniors-first-commons/components/organisms/Availability/Availability'
import CaregiverServices from 'components/organisms/CaregiverServices'
import Slider from 'seniors-first-commons/components/atoms/Slider'
import classes from './index.module.css'
import DistanceSlider from '../../atoms/DistanceSlider'


export const OfferedServices = ({
  services,
  all_services
}) => {
  return (
    <>
      <Field
        additionalClasses={classes.serviceBox}
        all_services={all_services}
        component={CaregiverServices}
        id='services'
        name='services'
        services={services}
      />
      <ErrorMessage
        component={ErrorHandler}
        name='services'
        topSpace={0}
      />
    </>
  )
}

export const AvaialableDays = ({ availableDays }) => {
  const available_days = [
    {
      name: 'availableDays',
      text: 'app.boarding.availability.form.selectdays.all',
      value: 'all'
    },
    {
      name: 'availableDays',
      text: 'app.boarding.availability.form.selectdays.week',
      value: 'week'
    },
    {
      name: 'availableDays',
      text: 'app.boarding.availability.form.selectdays.weekend',
      value: 'weekend'
    }
  ]

  return (
    <>
      <FieldLabel
        id='availableDays'
        labelName='app.boarding.availability.form.selectdays'
        labelType='p2-bold'
      />
      <Field
        component={Radios}
        content={available_days}
        input={availableDays}
        name='availableDays'
      />
    </>
  )
}

export const AvaialableShifts = ({ availableShifts, ...formik }) => {

  const onAllTimesSelect = () => {
    if (availableShifts.length === 3) {
      formik.setFieldValue('availableShifts', [])
    }
    else {
      formik.setFieldValue('availableShifts', ['morning', 'afternoon', 'night'])
    }
  }

  const shifts = [
    {
      checked: availableShifts.length === 3,
      id: 'all_times',
      label: (
        <div className={classes.shiftWrapper}>
          <FormattedMessageWithClass id='app.boarding.availability.form.selectshift.all' />
        </div>
      ),
      name: 'allAvailableShifts',
      onChange: onAllTimesSelect
    },
    {
      checked: availableShifts.includes('morning'),
      id: 'morning',
      label: (
        <div className={classes.shiftWrapper}>
          <FormattedMessageWithClass id='app.boarding.availability.form.selectshift.morning' />
          <FormattedMessageWithClass
            className='p3-bold grey-light'
            id='app.boarding.availability.form.selectshift.morning.description'
          />
        </div>
      ),
      name: 'availableShifts',
      value: 'morning'
    },
    {
      checked: availableShifts.includes('afternoon'),
      id: 'afternoon',
      label: (
        <div className={classes.shiftWrapper}>
          <FormattedMessageWithClass id='app.boarding.availability.form.selectshift.afternoon' />
          <FormattedMessageWithClass
            className='p3-bold grey-light'
            id='app.boarding.availability.form.selectshift.afternoon.description'
          />
        </div>
      ),
      name:
      'availableShifts',
      value: 'afternoon'
    },
    {
      checked: availableShifts.includes('night'),
      id: 'night',
      label: (
        <div className={classes.shiftWrapper}>
          <FormattedMessageWithClass id='app.boarding.availability.form.selectshift.night' />
          <FormattedMessageWithClass
            className='p3-bold grey-light'
            id='app.boarding.availability.form.selectshift.night.description'
          />
        </div>
      ),
      name: 'availableShifts',
      value: 'night'
    }
  ]

  return (
    <>
      <FieldLabel
        id='availableShifts'
        labelName='app.boarding.availability.form.selectshift'
        labelType='p2-bold'
        topSpace={1}
      />

      <div className='columns'>
        {
          shifts.map((item, index) => (
            <div className='column' key={index}>
              <Field
                name={item.name}
                {...item}
                component={AdvancedCheckbox}
              />
            </div>
          ))
        }
      </div>
    </>
  )
}

export const AvailabilityDaysShifts = ({
  availableDays,
  availableShifts,
  day_times,
  matchesMobile
}) => {
  return (
    <>
      <FieldLabel
        id='day_times'
        labelName='app.boarding.availability.table.head'
        labelType='p2-bold'
        topSpace={1}
      />
      <Field
        component={Availability}
        days={day_times}
        matchesMobile={matchesMobile}
        name='day_times'
        preSetDay={availableDays}
        preSetTime={availableShifts}
      />
      <ErrorMessage
        component={ErrorHandler}
        name='day_times'
      />
    </>
  )
}

export const DailyHoursSlider = () => {
  return (
    <>
      <FieldLabel
        bottomSpace={0}
        id='hiring_min_hours'
        labelName='app.boarding.availability.form.contracts'
        labelType='p3-bold'
        topSpace={1}
      />
      <Field
        component={Slider}
        max={8}
        name='hiring_min_hours'
        sliderText='h'
        style={{ paddingBottom: '1rem' }}
      />
      <ErrorMessage
        component={ErrorHandler}
        name='hiring_min_hours'
      />
    </>
  )
}

export const WorkingLocation = ({ country_of_residence }) => {
  return (
    <>
      <FieldLabel
        id='working_location'
        labelName='app.boarding.availability.form.distance'
        labelType='p2-bold'
        topSpace={1}
      />
      <p className='p3-bold grey-light has-text-centered'>
        <FormattedMessageWithClass id='app.boarding.availability.form.placesnear' />
      </p>

      <Field
        component={AddressAutoSuggest}
        country={country_of_residence || ''}
        id='working_location'
        inputClassName='input'
        name='working_location'
        placeholder='Enter your address'
      />

      <ErrorMessage
        component={ErrorHandler}
        name='working_location'
      />
    </>
  )
}

export const RadioSlider = () => {


  return (
    <div className='has-margin-t-1'>
      <Field
        component={DistanceSlider}
        max={100}
        name='radio_km'
        sliderText='km'
        style={{ paddingBottom: '1rem' }}
      />
      <ErrorMessage
        component={ErrorHandler}
        name='radio_km'
      />
    </div>
  )
}
