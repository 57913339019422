import React from 'react'
import PublicNavbar from 'seniors-first-commons/components/organisms/PublicNavbar'

const ExperienceFeedbackLayout = ({ children }) => (
  <div className='has-navbar-fixed-top has-navbar-fixed-bottom'>
    <PublicNavbar />
    <div className='container'>
      <div style={{ margin: 'auto', maxWidth: '768px', minHeight: '80vh' }}>
        <div className='section'>
          {children}
        </div>
      </div>
    </div>
  </div>
)


export default ExperienceFeedbackLayout
