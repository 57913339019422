import React, { useEffect } from 'react'
import { Route, Switch, useRouteMatch, useParams } from 'react-router-dom'
import ProfileBoarding from 'screens/ProfileBoarding'
import Home from 'screens/Home'
import DeleteProfile from 'seniors-first-commons/screens/DeleteProfile'
import Login from 'seniors-first-commons/screens/Login'
import Register from 'seniors-first-commons/screens/Register'
import PasswordReset from 'seniors-first-commons/screens/PasswordReset'
import PasswordChange from 'seniors-first-commons/screens/PasswordChange'
import VerifyEmail from 'seniors-first-commons/screens/VerifyEmail'
import NotFoundPage from 'seniors-first-commons/components/templates/NotFound'
import ExperienceFeedback from '../screens/ExperienceFeedback'
import PrivateRoute from 'seniors-first-commons/utils/PrivateRoute'
import PublicRoute from 'seniors-first-commons/utils/PublicRoute'
import authService from 'seniors-first-commons/services/auth-service'
import service from 'seniors-first-commons/services/routingService'
import DirectOffers from 'screens/DirectOffers'
import Notifications from 'screens/Notifications'
import Inbox from 'screens/Inbox'
import OfferDetails from 'screens/OfferDetails'
import Welcome from 'screens/Welcome'
import WelcomeSteps from 'screens/WelcomeSteps'
import AccountSettings from 'screens/AccountSettings'
import { languages } from 'seniors-first-commons/shared/languages/index'
import { ROUTE } from './constants'


let language = null

const AppRoutes = () => {
  return (
    <Switch>
      <Route path={`/:lang(${languages.join('|')})?`}>
        <LanguageRoutes />
      </Route>
    </Switch>
  )
}

const LanguageRoutes = () => {
  const isAuth = authService.isAuthenticated()
  const { path } = useRouteMatch()
  const { lang } = useParams()
  language = lang
  useEffect(() => {
    if (!service.language && language) {
      service.setLanguage(language)
    }
    // eslint-disable-next-line
  }, [service.language, language, service.setLanguage])

  return (
    <Switch>
      <PrivateRoute
        component={Home}
        exact
        path={path}
        token={isAuth}
      />

      <PrivateRoute
        component={Home}
        path={`${path}${ROUTE.PROFILE}`}
        token={isAuth}
      />

      <PrivateRoute
        component={ProfileBoarding}
        path={`${path}${ROUTE.BOARDING}/:step`}
        token={isAuth}
      />

      <PrivateRoute
        component={AccountSettings}
        path={`${path}${ROUTE.ACCOUNT_SETTINGS}`}
        token={isAuth}
      />

      <PrivateRoute
        component={DeleteProfile}
        path={`${path}${ROUTE.DELETE_ACCOUNT}`}
        role='carer'
        token={isAuth}
      />

      <PrivateRoute
        component={DirectOffers}
        path={`${path}${ROUTE.DIRECT_OFFERS}`}
        token={isAuth}
      />

      <PrivateRoute
        component={Notifications}
        path={`${path}${ROUTE.NOTIFICATIONS}`}
        token={isAuth}
      />

      <PrivateRoute
        component={Inbox}
        path={`${path}${ROUTE.INBOX}`}
        token={isAuth}
      />

      <PrivateRoute
        component={OfferDetails}
        path={`${path}${ROUTE.OFFER_DETAILS}/:id`}
        token={isAuth}
      />

      <PrivateRoute
        component={Welcome}
        exact
        path={`${path}${ROUTE.WELCOME}`}
        token={isAuth}
      />

      <PrivateRoute
        component={WelcomeSteps}
        path={`${path}${ROUTE.WELCOME}/:step`}
        token={isAuth}
      />

      {/* PUBLIC ROUTES */}
      <PublicRoute
        component={ExperienceFeedback}
        isAuthed={isAuth}
        path={`${path}${ROUTE.EXPERIENCE_FEEDBACK}/:token`}
        restricted={false}
      />

      <PublicRoute
        component={Login}
        isAuthed={isAuth}
        path={`${path}${ROUTE.LOGIN}`}
        restricted={true}
        role='carer'
      />

      <PublicRoute
        component={Register}
        isAuthed={isAuth}
        path={`${path}${ROUTE.REGISTER}`}
        restricted={true}
        role='carer'
      />

      <PublicRoute
        component={PasswordReset}
        isAuthed={isAuth}
        path={`${path}${ROUTE.PASSWORD_RESET}`}
        restricted={true}
        role='carer'
      />

      <PublicRoute
        component={PasswordChange}
        isAuthed={isAuth}
        path={`${path}${ROUTE.PASSWORD_CHANGE}/:token`}
        restricted={true}
        role='carer'
      />

      <PublicRoute
        component={VerifyEmail}
        isAuthed={isAuth}
        path={`${path}${ROUTE.VERIFY_EMAIL}/:token`}
        restricted={true}
        role='carer'
      />

      <Route
        component={NotFoundPage}
        path='*'
      />
    </Switch>
  )
}


export default AppRoutes
