import React from 'react'
import Address from 'seniors-first-commons/components/molecules/OfferCardParts/Address'
import Contract from 'seniors-first-commons/components/molecules/OfferCardParts/Contract'
import Duration from 'seniors-first-commons/components/molecules/OfferCardParts/Duration'
import Modality from 'seniors-first-commons/components/molecules/OfferCardParts/Modality'
import Retribution from 'seniors-first-commons/components/molecules/OfferCardParts/Retribution'
import Services from 'seniors-first-commons/components/molecules/OfferCardParts/Services'
import classes from './index.module.css'


const DataLevel = ({ isMarginless=false, children, extraClasses='' }) => {
  return (
    <div className={`${extraClasses} ${!isMarginless ? 'has-margin-b-6' : ''}`}>
      {children}
    </div>
  )
}

const PostedOffer = ({
  data={},
  ...props
}) => {
  return (
    <div className={`card ${classes.card}`}>
      <div className='card-content'>
        <DataLevel>
          {props.header}
        </DataLevel>

        <div style={{ height: '65px' }}>
          <Services services={data ? data.service_categories : []} />
        </div>

        <DataLevel>
          <Address data={data} />
        </DataLevel>

        <DataLevel>
          <Modality
            data={data}
            onOpenDaysAndShiftsModal={props.actions.onOpenDaysAndShiftsModal}
          />
        </DataLevel>

        <DataLevel>
          <Contract data={data} />
        </DataLevel>

        <DataLevel>
          <Duration data={data}/>
        </DataLevel>

        <DataLevel>
          <Retribution currency={data?.currency} data={data} />
        </DataLevel>

        <div className='has-margin-t-5 has-text-centered'>
          {props.footer}
        </div>
      </div>
    </div>
  )
}

export default PostedOffer
