import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import ApiService from 'seniors-first-commons/services/api-service'

const { axiosGet } = ApiService

export const chatWithClient = createAsyncThunk(
  'layouts/chatWithClient',
  (payload, { rejectWithValue }) => {
    return axiosGet('/carer/chat')
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const toggleRightSidebar = createAction('layouts/toggleRightSidebar')

export const toggleLeftSidebar = createAction('layouts/toggleLeftSidebar')

export const toggleChatModal = createAction('layouts/toggleChatModal')

export const getNotReadMessages = createAction('layouts/getNotReadMessages')

export const setInbox = createAction('layouts/setInbox')

export const changeErrorMessageStatus = createAction('layouts/changeErrorMessageStatus')
