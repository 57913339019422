import React, { useRef, useEffect } from 'react'
import { connect } from 'react-redux'
import Talk from 'talkjs'
import useMediaQuery from 'seniors-first-commons/services/useMediaQuery'
import InboxLayout from 'components/templates/InboxLayout'
import AppContainer from '../../components/organisms/AppContainer'
import { BREAKPOINTS } from 'seniors-first-commons/styles/constants'

const Inbox = ({ caregiverChatId, loading }) => {
  const mediaQuery = useMediaQuery()
  const matchesMobile = mediaQuery[0] <= BREAKPOINTS.MOBILE_MAX
  const inboxRef = useRef(null)

  useEffect(() => {
    if(caregiverChatId) {
      Talk.ready
        .then(() => {
          if(!window.talkSession) {
            const user = new Talk.User(caregiverChatId)
            window.talkSession = new Talk.Session({
              appId: process.env.REACT_APP_TALK_ID,
              me: user
            })
          }

          window.talkSession.unreads.on('change', unreadConversations => {
            const amountOfUnreads = unreadConversations.length
            if(amountOfUnreads) document.title = `(${  amountOfUnreads  }) Seniors First`
            else document.title = 'Seniors First'
          })

          const inbox = window.talkSession.createInbox({
            selected: undefined
          })

          inbox.mount(inboxRef.current)
        })
        .catch(error => console.log(error))
    }
  // eslint-disable-next-line
  }, [caregiverChatId])

  return (
    <div className='full-content-caregiver'>
      <AppContainer name='Chat'>
        <div className={`container section ${matchesMobile ? 'has-padding-lr-7' : ''}`}>
          {!loading ?
            <InboxLayout
              chatId={caregiverChatId}
              inboxRef={inboxRef}
            />
            : <span className='p2-regular'>Please wait...</span>}
        </div>
      </AppContainer>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    caregiverChatId: state.layouts.chat.data && state.layouts.chat.data.chat_user_id,
    loading: state.layouts.chat.loading
  }
}

export default connect(mapStateToProps, null)(Inbox)
