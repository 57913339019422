
import React, { useState, useEffect } from 'react'
import { AvailabilityTable } from './AvailabilityTable'
import { dayTimeIds, timeIds, dayIds, timeSchedule } from './constants'


const mapAvailability = (availability, workHours = []) =>
  (dayTimeIds[availability] || []).reduce((acc, dayId) => {
    workHours.forEach(hour => {
      const dayTime = (timeIds[hour] || []).map(hour => ({ id: +`${dayId}${hour}`, name: `${dayIds[dayId]} ${timeSchedule[hour]} ` }))
      acc = [...acc, ...dayTime]
    })
    return acc
  }, [])

export const Availability = ({
  form,
  field,
  preSetDay,
  preSetTime,
  days = [],
  useShortName = false,
  matchesMobile
}) => {
  const [availability, setAvailability] = useState(days)

  useEffect(() => {
    let newAvailability
    if (preSetDay && preSetTime) {
      newAvailability = mapAvailability(preSetDay, preSetTime)
      setAvailability(newAvailability)
      form.setFieldValue(field.name, newAvailability)
    }
    // eslint-disable-next-line
  }, [preSetDay, preSetTime])

  useEffect(() => {
    if (days.length) setAvailability(days)
  }, [days])

  const handleChange = value => {
    const index = availability.findIndex(e => e.id === value.id)
    const newValue =
      index > -1
        ? [...availability.slice(0, index), ...availability.slice(index + 1)]
        : [...availability, value]

    setAvailability(newValue)
    form.setFieldValue(field.name, newValue)
  }

  return (
    <AvailabilityTable
      availability={availability}
      matchesMobile={matchesMobile}
      onClick={handleChange}
      preSetDay={preSetDay}
      preSetTime={preSetTime}
      useShortName={useShortName}
    />
  )
}
