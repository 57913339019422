import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useQuery } from 'seniors-first-commons/shared/hooks'
import useMediaQuery from 'seniors-first-commons/services/useMediaQuery'
import { getJobOfferDetails } from 'store/offers/actions'
import AppContainer from 'components/organisms/AppContainer'
import FullWidthLayout from 'components/templates/FullWidthLayout'
import OfferDetailsBody from 'components/organisms/OfferDetailsBody'
import { BREAKPOINTS } from 'seniors-first-commons/styles/constants'

const OfferDetails = ({
  offerDetails,
  getJobOfferDetails,
  ...props
}) => {
  const id = props.match.params.id
  const query = useQuery()
  const offerType = query.get('from')
  const mediaQuery = useMediaQuery()
  const matchesMobile = mediaQuery[0] <= BREAKPOINTS.MOBILE_MAX

  useEffect(() => {
    getJobOfferDetails({ job_offer_id: id })
    // eslint-disable-next-line
  }, [])

  return (
    <AppContainer name='Offer details'>
      <FullWidthLayout>
        <div className={matchesMobile ? 'has-padding-lr-7' : ''}>
          <OfferDetailsBody
            comingFrom='details'
            offerDetails={offerDetails}
            offerType={offerType}
          />
        </div>
      </FullWidthLayout>
    </AppContainer>
  )
}

const mapStateToProps = (state) => ({
  offerDetails: state.offers.oneOfferDetails
})

const mapDispatchToProps = {
  getJobOfferDetails
}

export default connect(mapStateToProps, mapDispatchToProps)(OfferDetails)
