import React from 'react'
import { useHistory } from 'react-router-dom'
import withVideo from 'hoc/withVideo'
import { useFormatMessage } from 'seniors-first-commons/shared/store/translations/effects'
import ExampleVideoButton from 'components/molecules/ExampleVideoButton'
import VideoCard from 'components/organisms/VideoCard'
import ProfileBoardingFooter from '../ProfileBoardingFooter'


const Footer = ({ onGoBack, onNext, onAddLater, ...props }) => {
  return (
    <ProfileBoardingFooter
      hasAddLaterButton={true}
      onAddLater={onAddLater}
      onBack={onGoBack}
      onNext={onNext}
      step={6}
      {...props}
    />
  )
}

const VideoCv = ({
  hasVideo,
  uploadedStatusText,
  uploadingProgress,
  isSubmitting,
  onDeleteVideo,
  onOpenVideoUploadZone,
  onShowPreviewVideo,
  ...props
}) => {
  const history = useHistory()

  const translations = {
    descriptionLink: useFormatMessage('app.boarding.videocv.form.description.link'),
    descriptionText: useFormatMessage('app.boarding.videocv.form.description.text'),
    descriptionTitle: useFormatMessage('app.boarding.videocv.form.description.title'),
    details: useFormatMessage('app.boarding.videocv.main.text2'),
    educationLink: useFormatMessage('app.boarding.videocv.form.education.link'),
    educationText: useFormatMessage('app.boarding.videocv.form.education.text'),
    educationTitle: useFormatMessage('app.boarding.videocv.form.education.title'),
    experienceLink: useFormatMessage('app.boarding.videocv.form.experience.link'),
    experienceText: useFormatMessage('app.boarding.videocv.form.experience.text'),
    experienceTitle: useFormatMessage('app.boarding.videocv.form.experience.title'),
    limit: useFormatMessage('app.boarding.videocv.main.limit'),
    title: useFormatMessage('app.boarding.videocv.main.text1')
  }

  const onNext = () => {
    props.goHome()
  }
  const onAddLater = () => {
    props.goHome()
  }
  const onBack = () => {
    history.goBack()
  }

  return (
    <>
      <p className='t1-bold primary has-text-centered has-padding-b-3'>
        Video CV
      </p>
      <p className='p2-bold grey-light has-margin-b-7'>
        {translations.title}
      </p>
      <p className='p2-bold grey-light'>
        {translations.details}
      </p>

      <div
        className='full-width has-margin-t-3'
        style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
      >
        <ExampleVideoButton onOpenExampleVideo={onShowPreviewVideo} />

        <VideoCard
          hasVideo={!!hasVideo}
          isUploading={isSubmitting}
          onDelete={onDeleteVideo}
          onPreview={onOpenVideoUploadZone}
          onVideUploadBarClick={onOpenVideoUploadZone}
          translations={translations}
          uploadedStatusText={uploadedStatusText}
          uploadingProgress={uploadingProgress}
        />
      </div>

      <Footer
        onAddLater={onAddLater}
        onGoBack={onBack}
        onNext={onNext}
      />
    </>
  )
}

export default withVideo(VideoCv)
