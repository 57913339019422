import React from 'react'
import { useHistory } from 'react-router-dom'
import routingService from 'seniors-first-commons/services/routingService'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import { ROUTE } from 'root/constants'


const WelcomeStep = ({
  index,
  name,
  route,
  isEnabled,
  isCompleted
}) => {
  const history = useHistory()

  const onChangeRoute = () => {
    routingService.push(history, `${ROUTE.WELCOME}/${route}`)
  }

  return (
    <div className='column is-one-third'>
      <div
        className={`box ${isCompleted ? '' : 'disabled'}`}
        onClick={isEnabled ? onChangeRoute : () => {}}
        style={{ cursor: !isEnabled ? 'not-allowed' : 'pointer' }}
      >
        {!isCompleted && isEnabled && (
          <button
            className='button is-rounded is-pulled-right'
            onClick={onChangeRoute}
          >
            <FormattedMessageWithClass
              className='p2-bold'
              id='app.home.profilestep.button'
            />
          </button>
        )}

        <p className={`t1-bold has-margin-b-7 ${isEnabled ? 'primary-dark' : ''}`}>
          {index}
        </p>

        <p className={`p1-bold ${isEnabled ? 'primary-light' : ''}`}>
          <FormattedMessageWithClass id={name} />
        </p>
      </div>
    </div>
  )
}


export default WelcomeStep
