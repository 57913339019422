import React from 'react'


const TextField = ({
  field,
  form,
  ...props
}) => {
  const onFieldChange = (e) => {
    form.setFieldValue(field.name, e.target.value)
  }

  const onFieldBlur = () => {
    form.setFieldTouched(field.name)
  }

  return (
    <div className='control is-large'>
      <textarea
        {...field}
        className='textarea input is-primary'
        cols='10'
        onBlur={onFieldBlur}
        onChange={onFieldChange}
        rows='5'
        {...props}
      ></textarea>
    </div>
  )
}

export default TextField

