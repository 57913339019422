import React from 'react'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import { AppliedIcon } from 'seniors-first-commons/components/atoms/images/icons'


const AcceptedOfferBody = ({ fromInterest }) => {
  return (
    <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
      <AppliedIcon
        fill='#4CC700'
        height='45'
        stroke='#4CC700'
        width='45'
      />
      <FormattedMessageWithClass
        className='p1-bold success has-padding-t-7'
        id={!fromInterest
          ? 'carer.directoffers.actions.accept.text'
          : 'carer.directoffers.actions.acceptedinterest.text'
        }
      />
    </div>
  )
}

export default AcceptedOfferBody
