import React, { useState } from 'react'
import { connect } from 'react-redux'
import { path } from 'ramda'
import { useHistory } from 'react-router-dom'
import AppRoutes from './routes'
import {
  getNotReadMessages,
  toggleChatModal,
  setInbox,
  chatWithClient
} from '../store/layouts/actions'

import { getCarerData } from 'store/profile/actions'
import { getUser } from 'seniors-first-commons/shared/store/user/actions'
import { getNotReadNotifications, getNotifications } from 'seniors-first-commons/shared/store/notifications/actions'
import { getCountries } from 'seniors-first-commons/shared/store/helpers/actions'
import { useFecthDataOnCondition, useInterval } from 'seniors-first-commons/shared/hooks'
import withLocale from 'seniors-first-commons/hoc/withLocale'
import authService from 'seniors-first-commons/services/auth-service'
import useMediaQuery from 'seniors-first-commons/services/useMediaQuery'
import { useQuery } from 'seniors-first-commons/shared/hooks'
import Loading from 'components/organisms/Loading'
import UserActiveStatus from 'seniors-first-commons/components/organisms/UserActiveStatus'
import ErrorSnackbar from 'components/organisms/ErrorSnackbar'
import SuccessSnackbar from 'components/organisms/SuccessSnackbar'
import InboxModal from 'components/organisms/ChatInboxModal'
import Privacy from 'seniors-first-commons/components/organisms/Privacy/Privacy'
import ScrollToTop from 'seniors-first-commons/services/scrollToTop'
import { ROUTE } from 'root/constants'
import { NEW_NOTIFICATIONS } from 'seniors-first-commons/utils/constants'
import '../index.css'
import { BREAKPOINTS } from 'seniors-first-commons/styles/constants'
import RouteLeavingGuard from 'seniors-first-commons/shared/contexts/RouteLeavingGuard'

function App({
  chatData,
  messagesInbox,
  chatModal,
  notifications,
  userIsActive,
  refresh_token,
  completedProfile,
  setInbox,
  toggleChatModal,
  getNotReadMessages,
  getUser,
  getNotifications,
  getNotReadNotifications,
  chatWithClient,
  getCountries,
  getCarerData,
  dirtyForms
}) {
  const [chatInterval, setChatInterval] = useState(true)
  const history = useHistory()
  const mediaQuery = useMediaQuery()
  const query = useQuery()
  const comingFrom = query.get('from')
  const matchesMobile = mediaQuery[0] <= BREAKPOINTS.MOBILE_MAX
  const notificationsRoute = history.location.pathname.includes(ROUTE.NOTIFICATIONS.substring(1))

  useFecthDataOnCondition(() => {
    getCountries()
  }, refresh_token)

  useFecthDataOnCondition(() => {
    chatWithClient()
  }, completedProfile && refresh_token)

  useFecthDataOnCondition(() => {
    getNotifications({ page: 1, per_page: NEW_NOTIFICATIONS })
  }, refresh_token && completedProfile && !notificationsRoute && !notifications.page)

  useFecthDataOnCondition(() => {
    getUser()
      .then(({ payload }) => getCarerData({ carer_id: payload.id }))
  }, !!refresh_token)

  useInterval(() => {
    getNotReadNotifications({ page: 1, per_page: NEW_NOTIFICATIONS })
  }, refresh_token && completedProfile && userIsActive ? process.env.REACT_APP_REFRESH_NOTIFICATIONS : null)

  useInterval(() => {
    chatWithClient()
      .unwrap()
      .then(payload => {
        if (payload.chat_user_id) {
          setChatInterval(false)
        }
      })
      .catch(() => setChatInterval(false))
  }, chatInterval && refresh_token && completedProfile && userIsActive ? process.env.REACT_APP_FIRST_CHAT_INTERVAL : null)

  const whenShouldBlockNavigation = dirtyForms.includes(history.location.pathname) && comingFrom === 'home'

  return (
    <main className='App hero'>
      <Privacy />
      <Loading />
      <UserActiveStatus />
      <ErrorSnackbar />
      <SuccessSnackbar />
      <ScrollToTop setShowChatModal={toggleChatModal}>
        <RouteLeavingGuard
          navigate={location => {
            history.push({pathname: location.pathname, search: location.search})
          }}
          shouldBlockNavigation={location => {
            const { pathname } = history.location
            // if the route where i'm navigating from has a dirty form
            // and the target route is different should display the modal for confirmation
            if (dirtyForms.includes(pathname) && pathname !== location.pathname) {
              return true
            }
            return false
          }}
          when={whenShouldBlockNavigation}
        />
        <AppRoutes />
      </ScrollToTop>
      {!matchesMobile && completedProfile &&
        <>
          <InboxModal
            authed={authService.isAuthenticated()}
            caregiverChatId={path(['chat_user_id'], chatData)}
            inbox={messagesInbox}
            open={chatModal}
            role='carer'
            setInbox={setInbox}
            setUnreads={getNotReadMessages}
            toggleChatModal={toggleChatModal}
          />
        </>
      }
    </main>
  )
}

const mapStateToProps = (state) => ({
  chatData: state.layouts.chat.data,
  chatModal: state.layouts.chat.showModal,
  completedProfile: state.profile.completedProfile,
  dirtyForms: state.ui.dirtyForms,
  messagesInbox: state.layouts.inbox,
  notReadMessages: state.layouts.chat.notReadMessages,
  notifications: state.notifications.data,
  profile: state.profile.profile,
  refresh_token: localStorage.getItem('refresh_token'),
  renderNewHome: state.profile.renderNewHome,
  userIsActive: state.user.active
})

const mapDispatchToProps = {
  chatWithClient,
  getCarerData,
  getCountries,
  getNotReadMessages,
  getNotReadNotifications,
  getNotifications,
  getUser,
  setInbox,
  toggleChatModal
}

export default connect(mapStateToProps, mapDispatchToProps)(withLocale(App))
