import React from 'react'
import JobOfferCard from 'components/organisms/JobOfferCard'
import InterestedOfferBody from 'components/molecules/InterestedOfferBody'


const Interested = ({
  matchesMobile,
  selectedOffer,
  loading,
  actions,
  ...props
}) => {
  return (
    <JobOfferCard
      data={props.item}
      matchesMobile={matchesMobile}
      onOpenDaysAndShiftsModal={actions.onOpenDaysAndShiftsModal}
    >
      <InterestedOfferBody
        loading={(selectedOffer === props.item.id) && loading}
        onViewReasons={actions.onViewInterestedReasons}
        {...props}
      />
    </JobOfferCard>
  )
}

export default Interested
