import { pathOr } from 'ramda'


export default class Formation {
  constructor(profile) {
    this.formation_type = pathOr('', ['formation', 'formation_type'], profile).toString()
    this.other_formation_type = pathOr('', ['formation', 'other_formation_type'], profile)
    this.other_specific_formation = pathOr('', ['formation', 'other_specific_formation'], profile)
    this.languages = pathOr([], ['formation', 'languages'], profile)
  }
}
