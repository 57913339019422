import React, { useEffect } from 'react'
import { path, pathOr, equals } from 'ramda'
import { useHistory, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import useMediaQuery from 'seniors-first-commons/services/useMediaQuery'
import routingService from 'seniors-first-commons/services/routingService'
import { blackList } from 'seniors-first-commons/shared/store/auth/actions'
import { clear } from 'store/profile/actions'
import AppNavbar from '../../molecules/AppNavbar'
import { ROUTE } from 'root/constants'


const AppContainer = ({
  name='',
  user,
  profile,
  renderNewHome,
  notReadNotifications,
  notReadMessages,
  blackList,
  clear,
  ...props
}) => {
  const history = useHistory()
  const currentRoute = `/${  history.location.pathname.split('/')[2]}`
  const firstname = pathOr('', ['firstname'], user)
  const photo = path(['bio', 'photo'], profile)
  const mediaQuery = useMediaQuery()
  const matchesMobile = mediaQuery[0] <= 768

  useEffect(() => {
    const title = notReadMessages ?
      `(${notReadMessages}) Seniors First | ${name}` :
      `Seniors First | ${name}`
    document.title = title
  }, [name, notReadMessages])

  const changeRoute = (route) => {
    routingService.push(history, route)
  }

  const activeRoute = (route) => {
    return equals(route, currentRoute)
  }

  const onLogout = () => {
    blackList()
      .unwrap()
      .then(() => clear())
      .finally(() => routingService.push(history, `${ROUTE.LOGIN}`))
  }

  if (!renderNewHome) {
    return (
      <Redirect to={routingService.getFinalRoute(ROUTE.WELCOME)} />
    )
  }

  return (
    <div className='has-navbar-fixed-top'>
      <AppNavbar
        activeRoute={activeRoute}
        changeRoute={changeRoute}
        matchesMobile={matchesMobile}
        notReadMessages={notReadMessages}
        notReadNotifications={notReadNotifications}
        onLogout={onLogout}
        user={{ firstname, photo }}
      />
      <section>
        {props.children}
      </section>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    chatData: state.layouts.chat.data,
    chatModal: state.layouts.chat.showModal,
    messagesInbox: state.layouts.inbox,
    notReadMessages: state.layouts.chat.notReadMessages,
    notReadNotifications: state.notifications.notReadNotifications,
    profile: state.profile.profile.data,
    refresh_token: localStorage.getItem('refresh_token'),
    renderNewHome: state.profile.renderNewHome,
    user: state.user.user.data
  }
}

const mapDispatchToProps = {
  blackList,
  clear
}

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer)
