import { path } from 'ramda'
import * as Yup from 'yup'


export const EconomicConditionsSchema = Yup.object().shape({
  accepts_work_with_autonomous: Yup.boolean()
    .when('accepts_work_with_company', {
      is: false,
      otherwise: Yup.boolean().notRequired(),
      then: Yup.boolean().oneOf([true], 'validations.required')
    }),
  accepts_work_with_company: Yup.boolean()
    .when('accepts_work_with_autonomous', {
      is: false,
      otherwise: Yup.boolean().notRequired(),
      then: Yup.boolean().oneOf([true], 'validations.required')
    }),
  countries_interested: Yup.array().when('working_abroad', {
    is: true,
    otherwise: Yup.array().notRequired(),
    then: Yup.array().min(1, 'validations.selectone')
      .required('validations.required')
  })
}, ['accepts_work_with_company', 'accepts_work_with_autonomous'])


export const SalarySchema = (profile) => Yup.object().shape({
  hourly_rate: Yup.number().when('economic_conditions', {
    is: 'custom',
    otherwise: Yup.number().nullable(),
    then: Yup.number()
      .typeError('validations.required')
      .test('testing hourly_rate', 'validations.required', (value) => {
        let valid = true

        if (path(['offered_service', 'external_mode'], profile) && !value) {
          valid = false
        }
        return valid
      })
  }),

  salary: Yup.number().when('economic_conditions', {
    is: 'custom',
    otherwise: Yup.number().nullable(),
    then: Yup.number()
      .typeError('validations.required')
      .test('testing salary', 'validations.required', (value) => {
        let valid = true
        if (path(['offered_service', 'internal_mode'], profile) && !value) {
          valid = false
        }
        return valid
      })
  })
})


export const LaborConditionsSchema = (profile) => Yup.object().shape({
  movilization_bodily: Yup.bool()
    .test('testing', 'validations.required', function(value) {
      let valid = true
      const offeredServices = path(['offered_service', 'formatted_services'], profile)
      const patient_movilisation = this.parent.patient_movilisation
      const movilization_crane = this.parent.movilization_crane
      const offersPersonalService = offeredServices['3'].length || offeredServices['4'].length ? true : false

      if (patient_movilisation && offersPersonalService) {
        if (!movilization_crane && !value) valid = false
      }
      return valid
    }),

  movilization_crane: Yup.bool()
    .test('testing', 'validations.required', function(value) {
      let valid = true
      const offeredServices = path(['offered_service', 'formatted_services'], profile)
      const patient_movilisation = this.parent.patient_movilisation
      const movilization_bodily = this.parent.movilization_bodily
      const offersPersonalService = offeredServices['3'].length || offeredServices['4'].length ? true : false

      if (patient_movilisation && offersPersonalService) {
        if (!movilization_bodily && !value) valid = false
      }
      return valid
    }),

  personal_hygiene: Yup.string()
    .test('testing personal_hygiene', 'validations.required', (value) => {
      let valid = true
      const offeredServices = path(['offered_service', 'formatted_services'], profile)

      if (offeredServices && (offeredServices['3'].length || offeredServices['4'].length) && !value) {
        valid = false
      }

      return valid
    })
})
