import * as Yup from 'yup'


const ExperienceFeedbackSchema = Yup.object().shape({
  comments: Yup.string(),
  rating: Yup.string().required('validations.required'),
  strengths: Yup.array(),
  strengths_other: Yup.string(),
  tasks_completed: Yup.string(),
  termination_reason: Yup.string(),
  termination_reason_other: Yup.string(),
  trustworthy: Yup.string().required('validations.required'),
  weaknesses: Yup.array(),
  weaknesses_other: Yup.string()
})

export default ExperienceFeedbackSchema
