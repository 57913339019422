import React from 'react'
import { isEmpty } from 'ramda'
import AddressAutoSuggest from 'seniors-first-commons/components/atoms/AddressAutoSuggest'
import DistanceSlider from '../../../atoms/DistanceSlider'


export const AddressInput = ({ country, ...props }) => {
  return (
    <AddressAutoSuggest
      autoFocus
      country={country ? country.code : ''}
      defaults={props.field.value}
      inputClassName='input'
      placeholder=''
      {...props}
    />
  )
}

export const RadioInput = (props) => {
  return (
    <DistanceSlider
      disabled={isEmpty(props.form.values.address)}
      max={100}
      style={{ paddingBottom: '1rem' }}
      {...props}
    />
  )
}
