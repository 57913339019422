import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { pathOr } from 'ramda'
import { getCaregiverToFeedback, submitFeedbackForm } from 'store/profile/actions'
import Error404 from 'seniors-first-commons/components/templates/NotFound'
import ExperienceFeedbackLayout from 'components/templates/ExperienceFeedbackLayout'
import SuccessScreen from 'components/molecules/SuccessScreen'
import ExperienceFeedbackForm from 'components/organisms/ExperienceFeedbackForm'

const ExperienceFeedback = ({
  data,
  loading,
  getCaregiverToFeedback,
  submitFeedbackForm
}) => {
  const [showForm, setShowForm] = useState(true)
  const [completed, setCompleted] = useState(false)
  const [success, setSuccess] = useState(false)
  const { token } = useParams()

  useEffect(() => {
    document.title = 'Seniors First | Experience feedback'
  }, [])

  useEffect(() => {
    getCaregiverToFeedback({ id: token })
      .unwrap()
      .catch(error => {
        const errorMessage = pathOr('', ['data', 'message'], error)
        if (error.status === 404) {
          if (errorMessage === 'carer-experience-feedback-completed') {
            setCompleted(true)
          }
          setShowForm(false)
        }
      })
  }, [getCaregiverToFeedback, token])

  const handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(false)
    submitFeedbackForm({ id: token, payload: values })
      .unwrap()
      .then(() => setSuccess(true))
  }

  if (!loading) {
    if (showForm){
      return (
        !success ?
          <ExperienceFeedbackLayout>
            <ExperienceFeedbackForm
              {...data}
              onFormSubmit={handleSubmit}
            />
          </ExperienceFeedbackLayout>:
          <SuccessScreen
            text='carer.feedback.successmessage.text'
            title='carer.feedback.successmessage.title'
          />
      )
    }
    else if (!showForm && completed){
      return (
        <SuccessScreen
          text='carer.feedback.completedmessage.text'
          title='carer.feedback.completedmessage.title'
        />
      )
    }
    else return <Error404 />
  }
  else {
    return (
      <div>Please wait...</div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.profile.experienceFeedback.data,
    loading: state.profile.experienceFeedback.loading
  }
}

const mapDispatchToProps = { getCaregiverToFeedback, submitFeedbackForm }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExperienceFeedback)
