import React from 'react'
import { useHistory } from 'react-router-dom'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import routingService from 'seniors-first-commons/services/routingService'
import { ROUTE } from 'root/constants'
import classes from './index.module.css'


const DirectOffersHomeHeader = ({
  matchesMobile,
  matchesTablet,
  pendingOffers=0
}) => {
  const history = useHistory()

  const onViewThemClick = () => {
    routingService.push(history, `${ROUTE.DIRECT_OFFERS}?tab=pending`)
  }

  return (
    <div className={`columns is-vcentered is-marginless-lrt has-padding-lr-5 has-padding-bt-4 ${matchesMobile ? classes.mobileWrapper : ''} ${classes.wrapper}`}>
      <div className='column is-narrow-desktop has-text-centered-mobile'>
        <p className={`white t${matchesTablet ? '3' : '2'}-regular`}>
          <FormattedMessageWithClass id='carer.home.pendingOfferCard.text1' />{' '}
          {pendingOffers}{' '}
          <FormattedMessageWithClass
            id={`carer.home.pendingOfferCard.text2.${pendingOffers === 1 ? 'singular' : 'plural'}`}
          />
        </p>
      </div>

      <div className='column has-text-centered-mobile has-text-right'>
        <button
          className={`button ${classes.button}`}
          onClick={onViewThemClick}
          type='button'
        >
          <FormattedMessageWithClass
            className='primary p1-bold'
            id='carer.home.pendingOfferCard.button'
          />
        </button>
      </div>
    </div>
  )
}

export default DirectOffersHomeHeader
