import React from 'react'

export const ServiceBox = ({
  id,
  title,
  isCompleted,
  image,
  onClick,
  style,
  additionalClasses=''
}) => {
  const clickHandler = () => onClick(id)
  return (
    <div
      className={`button-shadow services-box ${additionalClasses} ${isCompleted ? 'active' : ''}`}
      onClick={clickHandler}
      style={style}
    >
      <div className='radio'>
        <input name='' type='radio' />
        <span className='icon is-xl'>
          <img alt='' src={image} title={title} />
        </span>
      </div>
      <label className='label has-text-weight-semibold is-size-6'>{title}</label>
    </div>
  )
}
