import React from 'react'
import { Formik, Form, Field } from 'formik'
import {
  FormattedMessageWithClass,
  useFormatMessage
} from 'seniors-first-commons/shared/store/translations/effects'
import RegisterSchema from 'seniors-first-commons/shared/schemas/register'
import RegisterInitValues from 'seniors-first-commons/shared/models/register'
import { Firstname, Lastname, Email, Password, ConfirmPassword } from './inputs'
import SocialLogin from 'seniors-first-commons/components/molecules/SocialLogin'
import { InfoIcon } from 'seniors-first-commons/components/atoms/images/icons'
import classes from './index.module.css'


const RegisterForm = ({
  isSubmitting,
  onSubmit,
  onShowMoreInfo,
  onGoogleLogin,
  onFBLogin,
  onLogin
}) => {
  const currentEnv = process.env.REACT_APP_CURRENT_ENV
  const formatToMatchEnv = (url) => {
    return currentEnv !== 'prod' ? `${url}-stage` : url
  }
  const translations = {
    privacyPolicyUrl: useFormatMessage(formatToMatchEnv('app.footer.privacy.url'))
  }

  return (
    <>
      <Formik
        initialValues={new RegisterInitValues()}
        onSubmit={onSubmit}
        validationSchema={RegisterSchema}
      >
        {({ handleSubmit }) => {
          return (
            <Form>
              <Field
                component={Firstname}
                id='firstname'
                name='firstname'
              />
              <Field
                component={Lastname}
                id='lastname'
                name='lastname'
              />
              <Field
                component={Email}
                id='email'
                name='email'
              />
              <Field
                component={Password}
                id='password'
                name='password'
              />
              <Field
                component={ConfirmPassword}
                id='confirmPassword'
                name='confirmPassword'
              />

              <div className={classes.extraDetails}>
                <FormattedMessageWithClass
                  className='p2-bold primary is-hidden-mobile'
                  id='app.register.form.accept'
                />
                <a
                  className='button-text has-margin-r-7'
                  href={translations.privacyPolicyUrl}
                  rel='noopener noreferrer'
                  style={{ marginLeft: '5px' }}
                  target='_blank'
                >
                  <FormattedMessageWithClass
                    className='p2-bold'
                    id='app.register.form.policy'
                  />
                </a>

                <div
                  className='custom-tooltip pointer'
                  onClick={onShowMoreInfo}
                >
                  <InfoIcon
                    fill='none'
                    height='14'
                    stroke='#0040AA'
                    width='14'
                  />
                  <FormattedMessageWithClass
                    className='tooltip-content p3-bold white'
                    id='app.register.form.extraDetails'
                    placement='top'
                  />
                </div>
              </div>

              <button
                className={`
                  button filled has-margin-bt-5 nonAuthedButtons
                  ${isSubmitting ? 'is-loading' : ''} 
                `}
                disabled={isSubmitting}
                onClick={handleSubmit}
                type='submit'
              >
                <FormattedMessageWithClass
                  id='app.register.form.submit'
                />
              </button>

              <div className='columns'>
                <div className='column'>
                  <FormattedMessageWithClass
                    className='p2-bold primary'
                    id='app.register.form.alreadyHaveAccount'
                  />

                  <div className='has-margin-t-7'>
                    <button
                      className={`outlined ${classes.loginbutton}`}
                      onClick={onLogin}
                      type='button'
                    >
                      <FormattedMessageWithClass
                        id='app.login.message'
                      />
                    </button>
                  </div>
                </div>

                <div className='column'>
                  <FormattedMessageWithClass
                    className='p2-bold primary'
                    id='app.register.form.social'
                  />

                  <div className='has-margin-t-7'>
                    <SocialLogin
                      onFBLogin={onFBLogin}
                      onGoogleLogin={onGoogleLogin}
                    />
                  </div>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

export default RegisterForm
