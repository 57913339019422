import { ROUTE as COMMON_ROUTE } from 'seniors-first-commons/constants'

export const ROUTE = {
  ...COMMON_ROUTE,
  ACCOUNT_SETTINGS: '/account-settings',
  BOARDING: '/boarding',
  BOARDING_SERVICES_HOW: '/servicios-como',
  DIRECT_OFFERS: '/direct-offers',
  EXPERIENCE_FEEDBACK: '/ef',
  FAQS: '/faqs',
  HOME: '/',
  INBOX: '/inbox',
  NOTIFICATIONS: '/notifications',
  OFFER_DETAILS: '/job-offers',
  PROFILE: '/profile',
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
  WELCOME: '/welcome'
}
