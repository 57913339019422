import React from 'react'
import { Field } from 'formik'
import { useFormatMessage } from 'seniors-first-commons/shared/store/translations/effects'
import Input from 'seniors-first-commons/components/atoms/Input'
import Radio from 'seniors-first-commons/components/atoms/RadioButton'
import Checkbox from 'seniors-first-commons/components/atoms/Checkbox'
import {
  firstQuestionOptions,
  secondQuestionOptions,
  thirdQuestionsOptions,
  fourthQuestionOptions,
  fifthQuestionOptions,
  sixthQuestionOptions
} from './constants'
import classes from './index.module.css'


export const FirstQuestionInput = () => {
  return (
    <div className={classes.radios}>
      {
        firstQuestionOptions.map((option, index) => {
          return (
            <Field
              component={Radio}
              id={`trustworthy${  option.value}`}
              key={index}
              label={option.text}
              name='trustworthy'
              type='radio'
              value={option.value}
            />
          )
        })
      }
    </div>
  )
}

export const SecondQuestionInput = () => {
  return (
    <div className={classes.secondQuestionRadios}>
      {secondQuestionOptions.map((option, index) => {
        return (
          <Field
            component={Radio}
            id={`rating${  option.value}`}
            key={index}
            label={option.text}
            labelClass={classes.secondQuestionLabel}
            name='rating'
            type='radio'
            value={option.value}
          />
        )
      })}
    </div>
  )
}

export const ThirdQuestionInput = () => {
  return (
    <div className={classes.radios}>
      {thirdQuestionsOptions.map((option, index) => {
        return (
          <Field
            component={Radio}
            id={`tasks_completed${  option.value}`}
            key={index}
            label={option.text}
            name='tasks_completed'
            type='radio'
            value={option.value}
          />
        )
      })}
    </div>
  )
}

export const FourthQuestionInput = () => {

  return (
    <div className={classes.radios}>
      {fourthQuestionOptions.map((option, index) => (
        <div
          key={index}
          style={{ alignItems: 'center', display: option.value === 'other' ? 'flex' : '' }}
        >
          <Field
            component={Radio}
            id={`termination_reason${  option.value}`}
            label={option.text}
            labelClass={`is-flex ${classes.radioLabel}`}
            name='termination_reason'
            type='radio'
            value={option.value}
          />

          {option.value === 'other' ?
            <Field
              className='input has-margin-l-7'
              component={Input}
              maxLength='250'
              name='termination_reason_other'
              type='text'
            /> : null
          }
        </div>
      )
      )}
    </div>
  )
}

export const FifthQuestionInput = () => {
  return (
    <div className={classes.checkboxes}>
      {fifthQuestionOptions.map((option, index) => {
        return (
          <div
            key={index}
            style={{ alignItems: 'center', display: option.value === 'other' ? 'flex' : '' }}
          >
            <>
              <Field
                name='strengths'
                type='checkbox'
                value={option.value}
              >
                {({ field }) => {
                  return (
                    <Checkbox {...field} id={`strengths${  option.value}`} />
                  )
                }}
              </Field>
              <label
                className='has-margin-l-7 p1-regular grey'
                htmlFor={`strengths${  option.value}`}
              >
                {option.label}
              </label>

              {option.value === 'other' ?
                <Field
                  className='input has-margin-l-7'
                  component={Input}
                  maxLength='250'
                  name='strengths_other'
                  type='text'
                /> : null
              }
            </>
          </div>
        )
      })}
    </div>
  )
}

export const SixthQuestionInput = () => {
  return (
    <div className={classes.checkboxes}>
      {sixthQuestionOptions.map((option, index) => {
        return (
          <div key={index} style={{ alignItems: 'center', display: option.value === 'other' ? 'flex' : '' }}>
            <Field
              name='weaknesses'
              type='checkbox'
              value={option.value}
            >
              {({ field }) => {
                return (
                  <Checkbox {...field} id={`weaknesses${  option.value}`} />
                )
              }}
            </Field>
            <label
              className='has-margin-l-7 p1-regular grey'
              htmlFor={`weaknesses${  option.value}`}
            >
              {option.label}
            </label>

            {option.value === 'other' ?
              <Field
                className='input has-margin-l-7'
                component={Input}
                maxLength='250'
                name='weaknesses_other'
                type='text'
              /> : null
            }
          </div>
        )
      })}
    </div>
  )
}

export const SeventhQuestion = () => {
  return (
    <Field
      autoComplete='false'
      className='input'
      component={Input}
      maxLength='250'
      name='comments'
      placeholder={useFormatMessage('carer.feedback.options.lastinput.placeholder')}
      style={{width: '80%'}}
      type='text'
    />
  )
}
