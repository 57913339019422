import React from 'react'
import { ErrorMessage } from 'formik'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import FieldLabel from 'seniors-first-commons/components/atoms/FieldLabel'
import ErrorHandler from 'seniors-first-commons/components/atoms/ErrorHandler'
import Select from 'seniors-first-commons/components/molecules/Select'


export const Illnesses = (props) => {
  return (
    <>
      <FieldLabel
        bottomSpace={3}
        id='illnesses'
        labelName='app.boarding.routes.experience'
        labelType='t1-bold'
      />
      <p className='p2-bold primary has-text-centered has-margin-b-7'>
        <FormattedMessageWithClass id='app.boarding.illnessexperiences.text' />
      </p>

      <Select
        addStyles={{
          menu: (base) => ({
            ...base,
            zIndex: '38'
          })
        }}
        isMulti
        placeholder=''
        shouldTranslate={false}
        {...props}
      />

      <ErrorMessage
        component={ErrorHandler}
        name='illnesses'
      />
    </>
  )
}

