import { setCookie, getCookie, deleteCookie } from './cookies'

const COOKIES_CONFIGURED = 'pcy_configured'
const TRACKING_COOKIES_ENABLED = 'pcy_tracking'
const MARKETING_COOKIES_ENABLED = 'pcy_marketing'

const onReady = (callback) => {
  const stateCheck = setInterval(() => {
    if (document.readyState === 'complete') {
      callback()
      clearInterval(stateCheck)
    }
  }, 100)
}

export const configureCookies = ({tracking, marketing}) => {
  setCookie(COOKIES_CONFIGURED, 'true', 3650)
  setCookie(
    TRACKING_COOKIES_ENABLED,
    tracking
      ? 'true'
      : 'false',
    3650
  )
  setCookie(
    MARKETING_COOKIES_ENABLED,
    marketing
      ? 'true'
      : 'false',
    3650
  )
}

/**
 * TRACKING/PERFORMANCE COOKIES HANDLING
 */

const startGA = () => {
  window.dataLayer = window.dataLayer || []
  var gtag = function () { window.dataLayer.push(arguments) }
  gtag('js', new Date())
  gtag('config', 'UA-158067130-2')
  gtag('config', 'AW-676225505')
}

const enableGA = () => {
  startGA()
}

const disableGA = () => {
  window['ga-disable-UA-158067130-2'] = true
  window['ga-disable-AW-676225505'] = true

  // GA starts but will not set any cookies nor send data to Google servers.
  startGA()

  // Delete it's cookies
  deleteCookie('_ga', '.seniorsfirst.care')
  deleteCookie('_gid', '.seniorsfirst.care')
  deleteCookie('_gat_gtag_UA_158067130_2', '.seniorsfirst.care')
}

export const enableTrackingSafely = () => {
  onReady(() => {
    // Enable Gtag
    enableGA()
  })
}

export const disableTrackingSafely = () => {
  onReady(() => {
    // Disable Gtag
    disableGA()
  })
}

/**
 * MARKETING/TARGETING COOKIES HANDLING
 */

const enablePixel = () => {
  window.fbq('consent', 'grant')
}

const disablePixel = () => {
  // Pixel is disabled by default so not further action needed,
  // But will make sure to delete it's cookie just in case
  deleteCookie('_fbp', '.seniorsfirst.care')
  deleteCookie('fr', '.facebook.com')
}

export const enableMarketingSafely = () => {
  onReady(() => {
    // Enable Pixel
    enablePixel()
  })
}

export const disableMarketingSafely = () => {
  onReady(() => {
    // Disable Pixel
    disablePixel()

    // Delete other cookies that were reported
    // by CookieBot as marketing/targeting cookies
    deleteCookie('NID', '.google.com')
    deleteCookie('test_cookie', '.doubleclick.net')
  })
}

export const getPrivacyConfigured = () => getCookie(COOKIES_CONFIGURED) === 'true'

export const getTracking = () => getCookie(TRACKING_COOKIES_ENABLED) === 'true'

export const getMarketing = () => getCookie(MARKETING_COOKIES_ENABLED) === 'true'
