import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { path } from 'ramda'
import { useFormatMessage } from 'seniors-first-commons/shared/store/translations/effects'
import { useInterval, useFecthDataOnCondition } from 'seniors-first-commons/shared/hooks'
import { fetchVideos, fetchOneVideo, uploadVideo, deleteVideo } from 'store/profile/actions'
import UploadZoneModal from 'seniors-first-commons/components/atoms/UploadZoneModal'
import CustomModal from 'seniors-first-commons/components/atoms/CustomModal'
import ConfirmModal from 'seniors-first-commons/components/atoms/ConfirmModal'
import VideoModal from 'seniors-first-commons/components/molecules/VideoModal'
import { CloseCircle } from 'seniors-first-commons/components/atoms/images/icons'


const mapStateToProps = (state) => {
  return {
    completedProfile: state.profile.completedProfile,
    deletingVideo: state.profile.videos.deletingVideo,
    uploadingVideo: state.profile.videos.uploadingVideo,
    videos: state.profile.videos.data
  }
}

const mapDispatchToProps = {
  deleteVideo,
  fetchOneVideo,
  fetchVideos,
  uploadVideo
}


const withVideo = (WrappedComponent) => {

  const reader = new FileReader()

  const InnerComponent = ({
    videos,
    deletingVideo,
    uploadingVideo,
    completedProfile,
    uploadVideo,
    deleteVideo,
    fetchVideos,
    fetchOneVideo,
    ...props
  }) => {
    const [showUploadZone, setShowUploadZone] = useState(false)
    const [uploadedVideo, setUploadedVideoData] = useState(undefined)
    const [uploadedBuffer, setVideoBuffer] = useState(undefined)
    const [runInterval, setRunInterval] = useState(false)
    const [showNotReadyModal, setShowNotReadyModal] = useState(false)
    const [showConfirmDeleteVideoModal, setShowConfirmDeleteVideoModal] = useState(false)
    const [showPreview, setShowPreview] = useState('')

    const translations = {
      cancel: useFormatMessage('app.boarding.videocv.videonotready.close'),
      error: useFormatMessage('app.boarding.videocv.video.error'),
      inProgress: useFormatMessage('app.boarding.videocv.video.inprogress'),
      notReadyDetails: useFormatMessage('app.boarding.videocv.videonotready.text'),
      ready: useFormatMessage('app.boarding.videocv.video.ready'),
      uploadVideo: useFormatMessage('app.boarding.videocv.uploadbar.upload'),
      uploading: useFormatMessage('app.boarding.videocv.uploadbar.uploading')
    }

    const video = path([0], videos)
    const uploadingProgress = path(['progress'], video)
    const videoUrl = path(['video_url'], video)
    const hasVideo = uploadingProgress === 100 || !!videoUrl
    const upload_status = path(['upload_status'], video)
    const transCodeStatus = path(['transcode_status'], video)
    const isCompleted = upload_status === 'complete' && transCodeStatus === 'complete'
    const hasError = upload_status === 'error' || transCodeStatus === 'error'
    const uploadedStatusText = hasVideo
      ? isCompleted
        ? translations.ready
        : hasError
          ? translations.error
          : translations.inProgress
      : uploadingProgress < 100 || uploadingVideo
        ? translations.uploading
        : translations.uploadVideo

    useFecthDataOnCondition(() => {
      fetchVideos()
    }, completedProfile)

    const addBuffer = e => {
      var buffer = e.srcElement.result
      setVideoBuffer(buffer)
    }

    useEffect(() => {
      reader.addEventListener('loadend', addBuffer)

      return () => {
        reader.removeEventListener('loadend', addBuffer)
      }
      // eslint-disable-next-line
    }, [])

    useEffect(() => {
      if (uploadedBuffer && uploadedVideo) {
        uploadVideo({ file: uploadedBuffer, size: uploadedVideo.size, video_id: uploadedVideo.id })
          .then(() => {
            setRunInterval(true)
          })
          .catch(() => {
            deleteVideo(uploadedVideo.id)
            setRunInterval(false)
          })
          .finally(() => {
            setUploadedVideoData(undefined)
            setVideoBuffer(undefined)
          })
      }
      // eslint-disable-next-line
    }, [uploadedBuffer])

    useInterval(async () => {
      fetchVideos()
        .unwrap()
        .then((payload) => {
          if (payload.every(i => i.upload_status === 'complete' && i.transcode_status === 'complete')) {
            setRunInterval(false)
          }
        })
    }, runInterval ? process.env.REACT_APP_VIDEO_REFRESH_INTERVAL : null)

    const onVideoButtonInteraction = () => {
      const video_id = videoUrl ? videoUrl.substr(8) : ''
      if (!hasVideo && !isCompleted) {
        setShowUploadZone(true)
      }
      else {
        if (!isCompleted) {
          fetchOneVideo({ video_id: 1 })
            .unwrap()
            .then(({ upload_status, transcode_status, video_url }) => {
              if (upload_status === 'complete' && transcode_status === 'complete') {
                const formattedUrl = video_url.substr(8)
                setShowPreview(formattedUrl)
              } else {
                setShowNotReadyModal(true)
              }
            })
        } else {
          setShowPreview(video_id)
        }
      }
    }

    const onUpload = (e) => {
      const file = Array.isArray(e) ? e[0] : e.target.files[0]
      if(file){
        const { size } = file
        if (size > 83886080) return alert('File size is too big')
        setUploadedVideoData({ id: 1, size })
        reader.readAsArrayBuffer(file)
        onCloseUploadModal()
      }
    }

    const onCloseUploadModal = () => {
      setShowUploadZone(false)
    }

    const onCloseNotReadyModal = () => {
      setShowNotReadyModal(false)
    }

    const onCloseVideoModal = () => {
      setShowPreview('')
    }

    const onVideoRemove = () => {
      setShowConfirmDeleteVideoModal(true)
    }

    const onCloseDeleteConfirmModal = () => {
      setShowConfirmDeleteVideoModal(false)
    }

    const onConfirmVideoDelete = () => {
      deleteVideo({ video_id: 1 })
        .unwrap()
        .then(() => {
          setShowConfirmDeleteVideoModal(false)
          setShowPreview(false)
          setRunInterval(false)
        })
    }

    const onShowPreviewVideo = () => {
      const SF_VIDEO = '383256834'
      setShowPreview(SF_VIDEO)
    }

    return (
      <>
        <WrappedComponent
          hasVideo={hasVideo}
          onDeleteVideo={onVideoRemove}
          onOpenVideoUploadZone={onVideoButtonInteraction}
          onShowPreviewVideo={onShowPreviewVideo}
          uploadedStatusText={uploadedStatusText}
          uploadingProgress={uploadingProgress}
          uploadingVideo={uploadingVideo}
          {...props}
        />
        <UploadZoneModal
          accept={'video/*'}
          isOpen={showUploadZone}
          onCancel={onCloseUploadModal}
          onClose={onCloseUploadModal}
          onUploadClick={onUpload}
        />
        <CustomModal
          onClose={onCloseNotReadyModal}
          open={showNotReadyModal}
          shouldCloseOnOverlayClick
          styles={{ maxWidth: '450px', width: '95%' }}
        >
          <div className='has-text-centered' style={{ padding: '10px' }}>
            <p className='p1-bold has-margin-b-5'>
              {translations.notReadyDetails}
            </p>
            <button
              className='button filled'
              onClick={onCloseNotReadyModal}
              style={{ height: '48px', width: '120px' }}
            >
              {translations.cancel}
            </button>
          </div>
        </CustomModal>
        <VideoModal
          hasRemoveButton={true}
          isOpen={!!showPreview}
          onClose={onCloseVideoModal}
          videoId={showPreview}
        />
        <ConfirmModal
          Icon={CloseCircle}
          loading={deletingVideo}
          onCancel={onCloseDeleteConfirmModal}
          onClose={onCloseDeleteConfirmModal}
          onConfirm={onConfirmVideoDelete}
          show={showConfirmDeleteVideoModal}
          title='app.boarding.videocv.main.delete'
        />
      </>
    )
  }

  const EnhancedComponent = connect(mapStateToProps, mapDispatchToProps)(InnerComponent)

  return EnhancedComponent
}

export default withVideo
