import React from 'react'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import {
  PencilEdit,
  TrashIcon,
  PhoneIcon,
  MailIcon,
  PersonIcon
} from 'seniors-first-commons/components/atoms/images/icons'
import classes from './index.module.css'


const ExperienceCard = ({
  id,
  from_date,
  to_date,
  onEditExperience,
  onDeleteExperience,
  ...props
}) => {
  const period = `${from_date.format('MMMM YY')} - ${to_date.format('MMMM YY')}`

  return (
    <div className='card bordered-card'>
      <div className='card-header has-padding-7'>
        <div className='level is-mobile full-width'>
          <div className='level-left'>
            <p className='p2-bold primary ellipsis'>
              {period}
            </p>
          </div>
          <div className='level-right'>
            <button
              className='icon-button'
              disabled={props.state === 'positive' || props.state === 'negative'}
              onClick={() => onDeleteExperience(id)}
            >
              <TrashIcon
                fill='none'
                height='16'
                stroke='red'
                width='16'
              />
            </button>
            <button
              className='icon-button has-margin-l-7'
              disabled={props.state === 'positive' || props.state === 'negative'}
              onClick={() => onEditExperience(id)}
            >
              <PencilEdit
                fill='none'
                height='16'
                stroke='red'
                width='16'
              />
            </button>
          </div>
        </div>
      </div>
      <div className='card-content has-padding-7 has-text-left'>
        <p className='p2-bold primary'>
          <FormattedMessageWithClass id='app.boarding.experience.card.title' />
        </p>

        <div>
          {props.service_categories.map((service, index) => (
            <FormattedMessageWithClass
              className={`tag is-rounded p3-bold primary ${index !== props.service_categories.length - 1 ? 'has-margin-r-7' : ''}`}
              id={`app.boarding.offeredservices.form.${service}`}
              key={index}
            />
          ))}
        </div>

        <p className='p2-bold primary has-margin-t-6'>
          <FormattedMessageWithClass id='app.boarding.experience.card.contact' />
        </p>

        <div className={`has-margin-t-7 ${classes.level}`}>
          <div className={classes.iconWrapper}>
            <PersonIcon
              fill='none'
              height='16'
              stroke='#0040AA'
              width='16'
            />
          </div>
          <span className='p2-bold' style={{ marginLeft: '5px' }}>
            {props.reference_name}
          </span>
        </div>

        <div className={`has-margin-t-7 ${classes.level}`}>
          <div className={classes.iconWrapper}>
            <PhoneIcon
              fill='none'
              height='16'
              stroke='#0040AA'
              width='16'
            />
          </div>
          <span className='p2-bold' style={{ marginLeft: '5px' }}>
            {props.reference_phone
              ? props.reference_phone
              : <FormattedMessageWithClass id='app.boarding.experience.card.phone.notprovided' />
            }
          </span>
        </div>

        <div className={`has-margin-t-7 ${classes.level}`}>
          <div className={classes.iconWrapper}>
            <MailIcon
              fill='none'
              height='16'
              stroke='#0040AA'
              width='16'
            />
          </div>
          <span className='p2-bold' style={{ marginLeft: '5px' }}>
            {props.reference_email
              ? props.reference_email
              : <FormattedMessageWithClass id='app.boarding.experience.card.email.notprovided' />
            }
          </span>
        </div>
      </div>
    </div>
  )
}


export default ExperienceCard
