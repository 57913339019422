import React from 'react'
import WelcomeStep from 'components/atoms/WelcomeStep'


const WelcomeBox = ({ steps }) => {
  return (
    <div className='columns'>
      <div className='column is-10 is-offset-1'>
        <div className='columns is-multiline'>
          {Object.keys(steps).map((step, index) => {
            return (
              <WelcomeStep
                index={index + 1}
                key={index}
                route={step}
                {...steps[step]}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default WelcomeBox
