import React, { useEffect, useState } from 'react'
import { Prompt } from 'react-router-dom'
import ConfirmModal from 'seniors-first-commons/components/atoms/ConfirmModal'

export const RouteLeavingGuard = ({
  navigate,
  shouldBlockNavigation,
  when
}) => {
  const [modalVisible, updateModalVisible] = useState(false)
  const [lastLocation, updateLastLocation] = useState()
  const [confirmedNavigation, updateConfirmedNavigation] = useState(false)

  const showModal = location => {
    updateModalVisible(true)
    updateLastLocation(location)
  }

  const closeModal = cb => {
    updateModalVisible(false)
    if (cb) {
      cb()
    }
  }

  const handleBlockedNavigation = nextLocation => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      showModal(nextLocation)
      return false
    }
    return true
  }

  const handleConfirmNavigationClick = () => {
    closeModal(() => {
      if (lastLocation) {
        updateConfirmedNavigation(true)
      }
    })
  }

  const handleCancelNavigationClick = () => {
    closeModal(() => {
      updateConfirmedNavigation(false)
    })
  }

  useEffect(() => {
    if (confirmedNavigation) {
      navigate(lastLocation)
      updateConfirmedNavigation(false)
    }
  }, [confirmedNavigation])

  return (
    <>
      <Prompt
        message={handleBlockedNavigation}
        when={when}
      />
      <ConfirmModal
        cancel={'apps.general.goBack.back'}
        confirm={'app.boarding.modal.confirmation.continue'}
        details={'app.routeGuard.modal.description'}
        hascancelButton
        hasCloseButton
        onCancel={handleCancelNavigationClick}
        onClose={handleCancelNavigationClick}
        onConfirm={handleConfirmNavigationClick}
        show={modalVisible}
        title={'app.routeGuard.modal.title'}
      />
    </>
  )
}

export default RouteLeavingGuard
