import React from 'react'
import EmptyChats from 'seniors-first-commons/components/atoms/EmptyChats'

const InboxLayout = ({ chatId, inboxRef }) => {
  return (
    <>
      {chatId ? (
        <div
          className='full-width'
          ref={inboxRef}
          style={{ height: '450px', margin: 'auto', maxWidth: '768px' }}
        >
          <i>Loading chat...</i>
        </div>
      ) : <EmptyChats role='carer' />}
    </>

  )
}

export default InboxLayout
