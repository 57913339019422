import * as Yup from 'yup'


export const OfferedServicesSchema = Yup.object().shape({
  services: Yup.object().shape({
    1: Yup.array(),
    2: Yup.array(),
    3: Yup.array(),
    4: Yup.array()
  }).test('Testing offered services', 'validations.selectoneservice', (value) => {
    let valid = true
    if (Object.values(value).every(item => !item.length)) {
      valid = false
    }
    return valid
  })
})


export const ModalitySchema = Yup.object().shape({
  external_mode: Yup.boolean().when('internal_mode', {
    is: false,
    otherwise: Yup.boolean().notRequired(),
    then: Yup.boolean().oneOf([true], 'validations.required')
  }),
  internal_mode: Yup.boolean().when('external_mode', {
    is: false,
    otherwise: Yup.boolean().notRequired(),
    then: Yup.boolean().oneOf([true], 'validations.required')
  })
}, ['external_mode', 'internal_mode'])


export const AvailabilitySchema = Yup.object().shape({
  day_times: Yup.array()
    .min(1, 'validations.selectone')
    .required('validations.required'),
  hiring_min_hours: Yup.number()
    .min(1, 'validations.required')
    .required('validations.required'),
  radio_km: Yup.number()
    .min(1, 'validations.required')
    .required('validations.required'),
  working_location: Yup.object().shape({
    address_lat: Yup.number()
      .required('validations.required'),
    formatted_address: Yup.string().required('validations.required').test('validating address exists', 'validations.clients.createOffer.address', (value) => {
      let validAddress = true
      if (value && (value.match(/,/g) || []).length < 2) {
        validAddress = false
      }
      return validAddress
    })
  })
})
