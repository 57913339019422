import React, { useEffect, useState, useRef } from 'react'
import { path } from 'ramda'
import { Field, ErrorMessage } from 'formik'
import moment from 'moment'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import { useQuery } from 'seniors-first-commons/shared/hooks'
import FieldLabel from 'seniors-first-commons/components/atoms/FieldLabel'
import ExperiencesInitValues from 'models/experience'
import { ExperienceSchema } from 'schemas/experience'
import Wizard from 'components/organisms/ProfileWizard'
import ServicesCategories from 'seniors-first-commons/components/molecules/OfferedServices'
import ProfileBoardingFooter from '../ProfileBoardingFooter'
import ErrorHandler from 'seniors-first-commons/components/atoms/ErrorHandler'
import ConfirmModal from 'seniors-first-commons/components/atoms/ConfirmModal'
import {
  Period,
  ClientName,
  ClientEmail,
  ClientPhone,
  Confirmation
} from './inputs'


const WizardStep = ({ children, ...props }) => children(props)

const Footer = ({ onGoBack, ...props }) => {
  return (
    <ProfileBoardingFooter
      onBack={onGoBack}
      step={5}
      {...props}
    />
  )
}

const ExperiencesForm = ({
  profile,
  modalsState: { showAddAnotherExpModal, setShowAddAnotherExpModal },
  isSubmitting,
  ...props
}) => {
  const [experience, setExperience] = useState(null)
  const query = useQuery()
  const experience_id = query.get('experience_id')
  const formRef = useRef(null)

  useEffect(() => {
    if (profile && experience_id) {
      const experiences = path(['experiences'], profile)
      if (experiences && !!experiences.length) {
        const expToEdit = experiences.find(item => item.id.toString() === experience_id.toString())
        setExperience(expToEdit)
      }
    }
    // eslint-disable-next-line
  }, [profile])

  const onAddNewExperience = () => {
    formRef.current.resetForm()
    formRef.current.setSubmitting(false)
    setShowAddAnotherExpModal(false)
  }

  const onContinueFromAddNewExpModal = () => {
    setShowAddAnotherExpModal(false)
    props.continueToExperiences()
  }

  const onCloseAddNewExpModal = () => {
    setShowAddAnotherExpModal(false)
  }

  const onSubmit = (values) => {
    // eslint-disable-next-line no-unused-vars
    const { confirmation, ...restExperience } = values
    if (experience_id) {
      const experiences = path(['experiences'], profile)
      if (experiences && !!experiences.length) {
        const expToEdit = experiences.find(item => item.id.toString() === experience_id.toString())
        if (expToEdit) {
          props.updateExperience({ experience_id, ...restExperience })
            .unwrap()
            .then(() => props.moveToNextStep())
        }
        else {
          props.createExperience({ ...restExperience })
            .unwrap()
            .then(() => setShowAddAnotherExpModal(true))
        }
      }
      else {
        props.createExperience({ ...restExperience })
          .unwrap()
          .then(() => setShowAddAnotherExpModal(true))
      }
    }
    else {
      props.createExperience({ ...restExperience })
        .unwrap()
        .then(() => setShowAddAnotherExpModal(true))
    }
  }

  return (
    <>
      <Wizard
        enableReinitialize
        Footer={Footer}
        formRef={formRef}
        initialValues={new ExperiencesInitValues(experience, profile)}
        loading={isSubmitting}
        onSubmit={onSubmit}
        {...props}
      >
        <WizardStep validationSchema={ExperienceSchema}>
          {({ parentState: { values } }) => {
            return (
              <div className='has-text-centered'>
                <p className='t1-bold primary has-padding-b-5'>
                  <FormattedMessageWithClass id='app.boarding.routes.experience' />
                </p>
                <p className='p1-bold grey-light has-padding-b-5'>
                  <FormattedMessageWithClass id='app.boarding.experience.experience.addworkexperience' />
                </p>

                <>
                  <FieldLabel
                    bottomSpace={7}
                    labelName='app.boarding.experience.form.period'
                    labelType='p1-bold'
                  />
                  <p className='p3-bold grey-light'>
                    <FormattedMessageWithClass id='app.boarding.experience.form.period.helptext' />
                  </p>

                  <div className='columns is-mobile has-margin-t-5'>
                    <div className='column is-half'>
                      <Field
                        component={Period}
                        isOutsideRange={(day) => day.isSameOrAfter(moment())}
                        name='from_date'
                        placeholder='From'
                      />
                      <ErrorMessage
                        component={ErrorHandler}
                        name='from_date'
                      />
                    </div>
                    <div className='column is-half'>
                      <Field
                        component={Period}
                        isOutsideRange={(day) => day.isSameOrBefore(values.from_date)}
                        name='to_date'
                        placeholder='To'
                      />
                      <ErrorMessage
                        component={ErrorHandler}
                        name='to_date'
                      />
                    </div>
                  </div>
                </>

                <div className='full-width has-margin-b-1'>
                  <FieldLabel
                    labelName='app.boarding.experience.form.servicecategories'
                    labelType='p3-bold'
                    topSpace={3}
                  />
                  <Field
                    component={ServicesCategories}
                    name='service_categories'
                  />
                  <ErrorMessage
                    component={ErrorHandler}
                    name='service_categories'
                  />
                </div>

                <>
                  <p className='p1-bold grey-light is-marginless-b'>
                    <FormattedMessageWithClass id='app.boarding.experience.reference.title' />
                  </p>

                  <Field
                    component={ClientName}
                    name='reference_name'
                  />

                  <Field
                    component={ClientEmail}
                    name='reference_email'
                  />

                  <Field
                    component={ClientPhone}
                    name='reference_phone'
                  />

                  <Field
                    component={Confirmation}
                    name='confirmation'
                  />
                </>
              </div>
            )
          }}
        </WizardStep>
      </Wizard>

      <ConfirmModal
        cancel='app.boarding.experience.observations.submit'
        confirm='app.boarding.experience.observations.add'
        hasCloseButton={false}
        onCancel={onContinueFromAddNewExpModal}
        onClose={onCloseAddNewExpModal}
        onConfirm={onAddNewExperience}
        show={showAddAnotherExpModal}
        title=''
      />
    </>
  )
}

export default ExperiencesForm
