import React from 'react'
import { Formik, Form, Field } from 'formik'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import OfferedServicesInitValues from 'models/services'
import { OfferedServicesSchema } from 'schemas/services'
import CaregiverServices from 'components/organisms/CaregiverServices'
import CustomModal from 'seniors-first-commons/components/atoms/CustomModal'
import classes from './index.module.css'


const OfferedServicesModal = ({
  open,
  services,
  userId,
  selectedServices,
  loading,
  onClose,
  updateOnlyCareServices,
  getCarer
}) => {

  const handleSubmit = (values, { setSubmitting, setStatus }) => {
    const formatValues = Array.prototype.concat.apply([], Object.values(values.services))

    updateOnlyCareServices(formatValues)
      .unwrap()
      .then(() => {
        onClose()
        setStatus({ success: true })
        setSubmitting(false)
        getCarer({ carer_id: userId })
      })
  }

  const initValues = selectedServices ? { services: selectedServices } : new OfferedServicesInitValues()

  return (
    <CustomModal
      cardBodyStyles={{ overflow: 'auto' }}
      onClose={onClose}
      open={open}
      shouldCloseOnOverlayClick={true}
      styles={{ overflow: 'auto' }}
    >
      <div className='has-text-centered'>
        <h3 className='t2-bold primary'>
          <FormattedMessageWithClass id='carer.home.offeredServicesCard.title' />
        </h3>
        <p className='p2-regular grey has-margin-t-7 has-margin-b-3'>
          <FormattedMessageWithClass id='carer.home.offeredServicesCard.text' />
        </p>
        <Formik
          enableReinitialize
          initialValues={initValues}
          onSubmit={handleSubmit}
          validationSchema={OfferedServicesSchema}
        >
          {({ values, dirty }) => {
            return(
              <Form>
                <Field
                  additionalClasses={classes.item}
                  all_services={services}
                  component={CaregiverServices}
                  id='services'
                  name='services'
                  services={values.services}
                />

                <button
                  className={`button filled ${loading ? 'is-loading' : ''} ${classes.confirmButton}`}
                  disabled={loading || !dirty}
                  type='submit'
                >
                  <FormattedMessageWithClass id='carer.home.offeredServicesCard.button' />
                </button>
              </Form>
            )
          }}
        </Formik>
      </div>
    </CustomModal>
  )
}

export default OfferedServicesModal
