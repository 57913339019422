
import { pathOr } from 'ramda'


export default class WorkingLocationModal {
  constructor (profile) {
    this.address = {
      address_lat: pathOr(0, ['offered_service', 'lat'], profile),
      address_lng: pathOr(0, ['offered_service', 'lng'], profile),
      formatted_address: pathOr('', ['offered_service', 'working_location'], profile)
    }
    this.radio_km = pathOr(0, ['offered_service', 'radio_km'], profile)
  }
}
