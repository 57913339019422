import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import useMediaQuery from 'seniors-first-commons/services/useMediaQuery'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import { LeftArrowIcon, InfoIcon } from 'seniors-first-commons/components/atoms/images/icons'
import classes from './index.module.css'

import {
  addDirtyForm,
  removeDirtyForm
} from 'seniors-first-commons/shared/store/ui/actions'


const ProfileBoardingFooter = ({
  step=1,
  hasAddLaterButton=false,
  dirty,
  disabledNext=false,
  disabledBack=false,
  laststepText,
  loading,
  onNext,
  onBack,
  onAddLater=()=>{},
  addDirtyForm,
  removeDirtyForm,
  pathname,
  isLastStep
}) => {
  const mediaQuery = useMediaQuery()
  const matchesMobile = mediaQuery[0] <= 768
  const handleOnBack = (event) => {
    onBack(event)
  }

  useEffect(() => {
    if (dirty) {
      addDirtyForm(pathname)
    } else {
      removeDirtyForm(pathname)
    }
  }, [dirty])

  return (
    <div className={`navbar is-fixed-bottom hero-footer ${classes.container}`}>
      <div className={`level is-mobile ${matchesMobile ? 'full-width' : classes.wrapper}`}>
        <div className='level-left'>
          <button
            className={`button-text ${classes.backButton}`}
            disabled={disabledBack}
            onClick={handleOnBack}
            type='button'
          >
            <LeftArrowIcon
              className='button-text-icon-fill'
              height='18'
              width='20'
            />
            <FormattedMessageWithClass
              className='p2-bold has-padding-l-7'
              id='app.boarding.layout.footer.back'
            />
          </button>
        </div>

        <div className='level-item'>
          <button
            className={`button filled ${loading ? 'is-loading' : ''} ${classes.button}`}
            disabled={disabledNext || loading}
            onClick={onNext}
            type='submit'
          >
            <FormattedMessageWithClass id={isLastStep ? laststepText : 'app.boarding.layout.footer.next'} />
          </button>
          {hasAddLaterButton &&
            <button
              className='button-text'
              onClick={onAddLater}
              type='button'
            >
              <FormattedMessageWithClass
                className='p2-bold'
                id='app.boarding.layout.footer.later'
              />
            </button>
          }
          <p className={`custom-tooltip has-margin-l-7 ${classes.stepsContainer}`}>
            <InfoIcon
              fill='none'
              height='18'
              stroke='#0080F5'
              width='18'
            />

            <span className='tooltip-content p3-bold white' placement='top'>
              <FormattedMessageWithClass id='app.boarding.layout.footer.step' />: {step}
            </span>
          </p>
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = {
  addDirtyForm,
  removeDirtyForm
}


export default connect(null, mapDispatchToProps)(ProfileBoardingFooter)

