import { pathOr } from 'ramda'
import { createSelector } from 'reselect'
import { isNil, isEmpty, values } from 'ramda'
import { HOME_FORM_MAP } from 'seniors-first-commons/utils/constants'

export const shouldAskWorkPermit = (state) => {
  const nationalities = pathOr([], ['profile', 'profile', 'data', 'profile', 'nationalities'], state)
  const residenceCountry = pathOr({}, ['profile', 'profile', 'data', 'profile', 'country'], state)
  const ueCountries = pathOr([], ['helpers', 'helpers', 'countries'], state).filter(item => item.has_work_permit)

  const hasNationalityOfResidence = nationalities
    .some(nationalityCountry => nationalityCountry.value === residenceCountry.value)

  const residenceInUECountry = ueCountries
    .some(ueCountry => ueCountry.value === residenceCountry.value)

  let someEUNationality = false
  nationalities.forEach(nationality => {
    const nationalityInUE = ueCountries.some(ueCountry => ueCountry.value === nationality.value)
    if (nationalityInUE) {
      someEUNationality = true
    }
  })

  const resideInUEHavingSomeUENationality = residenceInUECountry && someEUNationality

  return !(hasNationalityOfResidence || resideInUEHavingSomeUENationality)
}

const getUser = state => state.data
export const getFullName = createSelector(
  getUser,
  user => {
    if (!user) return ''
    else return `${user.firstname} ${user.lastname}`
  }
)

const getBoardingForm = state => form => {
  if (state.profile.profile.data) {
    if (form) return state.profile.profile.data[form]
    else return state.profile.profile.data
  }
}

const getVideos = state => item => {
  return state.profile[item]
}

export const isFormCompleted = (state) => createSelector(
  getBoardingForm(state),
  data => {
    return data && !isEmpty(data) && !isNil(data)
  }
)

export const isVideoCompleted = (state) => createSelector(
  getVideos(state),
  ({ data }) => {
    return data.length && data[0].video_id
  }
)

const all_forms = new Set(values(HOME_FORM_MAP))
const getstate = state => state

export const getCompletionPercentage = createSelector(
  getBoardingForm,
  getstate,
  (forms, state) => {
    let count = 0
    all_forms.forEach(item => {
      const form = forms(item)

      if (item === 'videos') {
        return isVideoCompleted(state)(item) && count++
      }
      if (form && form.id) count++
      if (form && form.length) count++
    })
    const total = (count * 10) / 6

    return Math.round(total) * 10
  }
)
