import { createAsyncThunk, createAction } from '@reduxjs/toolkit'
import ApiService from 'seniors-first-commons/services/api-service'

const { axiosGet, axiosPost } = ApiService


const baseDataToApi = (payload) => {
  const data = { ...payload }

  const services = [...new Set(
    Array.prototype.concat.apply([], Object.values(data.formatted_services))
      .map(service => service.service_category_id)
  )]

  return {
    lat: data.lat,
    lng: data.lng,
    radio_km: data.radio_km,
    service_categories: services.join(',')
  }
}

export const getDirectProposals = createAsyncThunk(
  'offers/getDirectProposals',
  ({ page, per_page, status }, { rejectWithValue }) => {
    return axiosGet('/service-sheets/job-propositions-for', {
      page,
      per_page,
      status
    })
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const respondToOffer = createAsyncThunk(
  'offers/respondToOffer',
  ({ status, service_sheet_id, reasons }, { rejectWithValue }) => {
    return axiosPost(`/service-sheets/${service_sheet_id}/update-job-proposition-status/${status}`, {
      reasons
    })
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const getJobOfferDetails = createAsyncThunk(
  'offers/getJobOfferDetails',
  ({ job_offer_id }, { rejectWithValue }) => {
    return axiosGet(`/service-sheets/${job_offer_id}`)
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const applyToJobPostFromDetails = createAsyncThunk(
  'offers/applyToJobPostFromDetails',
  ({ sheet_id }, { rejectWithValue }) => {
    return axiosPost(`/service-sheets/${sheet_id}/apply-post`)
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const respondToOfferFromDetails = createAsyncThunk(
  'offers/respondToOfferFromDetails',
  ({ status, service_sheet_id, reasons }, { rejectWithValue }) => {
    return axiosPost(`/service-sheets/${service_sheet_id}/update-job-proposition-status/${status}`, {
      reasons
    })
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const getPendingOffersAmount = createAsyncThunk(
  'offers/getPendingOffersAmount',
  (payload, { rejectWithValue }) => {
    return axiosGet('/service-sheets/job-propositions-for/pending-amount')
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const getJobPosts = createAsyncThunk(
  'offers/getJobPosts',
  ({ payload, page, per_page }, { rejectWithValue }) => {
    const requestPayload = baseDataToApi(payload)
    return axiosGet('/service-sheets/search-posted', { ...requestPayload, page, per_page })
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const applyToJobPost = createAsyncThunk(
  'offers/applyToJobPost',
  ({ sheet_id }, { rejectWithValue }) => {
    return axiosPost(`/service-sheets/${sheet_id}/apply-post`)
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const toggleSuccessStatus = createAction('offers/toggleSuccessStatus')
