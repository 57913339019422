import React from 'react'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import { InfoIcon } from 'seniors-first-commons/components/atoms/images/icons'


const InterestedOfferBody = ({
  item={},
  loading,
  onViewReasons
}) => {
  return (
    <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
      <InfoIcon
        fill='none'
        height='45'
        stroke='#0080F5'
        width='45'
      />
      <button
        className='button-text has-padding-t-7'
        disabled={loading}
        onClick={() => onViewReasons(item?.id)}
      >
        <FormattedMessageWithClass
          className='p1-bold'
          id='carer.directoffers.actions.interested.text'
        />
      </button>
    </div>
  )
}

export default InterestedOfferBody
