import React from 'react'
import { ProgressCircle, Dot } from 'seniors-first-commons/components/atoms/images/icons'
import classes from './index.module.css'


const ProgressBar = (props) => {
  const { bgcolor, completed } = props

  const fillerStyles = {
    backgroundColor: bgcolor,
    borderRadius: 'inherit',
    height: '100%',
    textAlign: 'right',
    transition: 'width 1s ease-in-out',
    width: `${completed}%`
  }

  const point = {
    position: 'absolute',
    top: '50%',
    transform: 'translate(-100%, -50%)'
  }

  return (
    <div className={classes.container}>
      {[1,1,1].map((item, index) => {
        return (
          <Dot
            height='5px'
            key={index}
            style={{...point, left: `${(index + 1) * 25}%`}}
            width='5px'
          />
        )
      })}
      <div style={fillerStyles}>
        <ProgressCircle />
      </div>
    </div>
  )
}

export default ProgressBar
