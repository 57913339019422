import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

export const UploadProgress = ({ progress=0 }) => {

  return (
    <Fragment>
      <progress
        className='progress is-info is-small'
        max='100'
        value={progress}
      ></progress>
      <div className='column is-size-7 is-hidden-mobile'>
        <FormattedMessage id='app.boarding.videocv.uploadprogress.uploading' />
      </div>
      <div className='column has-text-right'>
        <span className='icon is-small'>...</span>
      </div>
    </Fragment>
  )
}
