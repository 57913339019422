import React, { Fragment } from 'react'
import { Field } from 'formik'
import { FormattedMessage } from 'react-intl'


const LANGUAGE_OPTIONS = {
  1: { level: 1, name: 'app.boarding.constants.language.basic' },
  2: { level: 2,  name: 'app.boarding.constants.language.intermediate' },
  3: { level: 3,  name: 'app.boarding.constants.language.advanced' }
}

const selectedStyle = { background: '#0086FF', color: 'white' }

export const SelectableTagGroup = ({
  languages,
  onDeleteLanguage
}) => {
  return (
    <div>
      {languages.map(({ label, ...rest }, index) => {

        const handleDelete = (key) => {
          onDeleteLanguage(key)
        }

        return (
          <div
            className='button-shadow has-margin-b-6 has-padding-lr-7 has-padding-bt-4'
            key={index}
            style={{ borderRadius: '10px' }}
          >
            <div className='tags padding-default'>
              <span
                className='p1-bold primary'
                style={{ flexGrow: 1, minWidth: '6rem' }}
              >
                {label}
              </span>
              <div className='is-flex'>
                <Field
                  component={SelectableTag}
                  data={{ label, ...rest }}
                  key={`languages[${index}]`}
                  name={`languages[${index}]`}
                />
              </div>
              <span
                className='delete has-margin-l-7'
                onClick={() => handleDelete(index)}
                style={{ marginBottom: '3px' }}
              >
              </span>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export const SelectableTag = ({
  form,
  field,
  data = {}
}) => {
  return (
    <Fragment>
      {Object.keys(LANGUAGE_OPTIONS).map(key => {
        const checked = data.level.toString() === key
        const language = LANGUAGE_OPTIONS[key]

        const handleSelect = () => {
          form.setFieldValue(field.name, { ...field.value, level: key })
        }

        return (
          <span
            className='tag selectable-tag is-medium p3-bold is-marginless-b'
            key={`${language.name}-${key}`}
            style={checked ? selectedStyle : null}
          >
            <label>
              <input
                {...field}
                checked={checked}
                className={checked ? '' : ''}
                onChange={handleSelect}
                style={{ display: 'none' }}
                type='radio'
              />
              <FormattedMessage id={language.name} />
            </label>
          </span>
        )
      })}
    </Fragment>
  )
}
