import React from 'react'
import { useHistory } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { stringifyQueries, useQuery, parseQueries } from 'seniors-first-commons/shared/hooks'


const ProfileWizard = ({
  initialValues,
  enableReinitialize=false,
  formRef,
  totalForms,
  Footer,
  children,
  loading,
  onSubmit,
  comingFrom,
  ...props
}) => {

  const history = useHistory()
  const query = useQuery()
  const existingQueryParams = parseQueries()
  const tab = query.get('tab') ? Number(query.get('tab')) : 0
  const steps = React.Children.toArray(children)

  const step = steps[tab]
  const totalSteps = steps.length
  const isLastStep = tab === totalSteps - 1
  const lastStepText = comingFrom === 'home'
    ? 'app.boarding.layout.footer.submit'
    : 'app.boarding.layout.footer.next'

  const next = () => {
    window.scrollTo(0, 0)
    const nextTab = stringifyQueries({ ...existingQueryParams, tab: step.props.tab + 1 })
    history.push({ search: nextTab })
  }

  const onGoBack = () => {
    history.goBack()
  }

  const handleSubmit = async (values, bag) => {
    if (step.props.onSubmit) {
      await step.props.onSubmit(values, bag)
    }
    if (isLastStep) {
      if (props.clearDirtyForms) props.clearDirtyForms()
      return onSubmit(values, bag)
    } else {
      bag.setTouched({})
      next(values)
    }
  }

  return (
    <>
      <Formik
        enableReinitialize={enableReinitialize}
        initialValues={initialValues}
        innerRef={ref => formRef ? formRef.current = ref : ref}
        onSubmit={handleSubmit}
        validationSchema={step.props.validationSchema}
      >
        {formik => {
          return (
            <Form className={props.styles}>

              {React.cloneElement(step, { parentState: { ...formik } })}

              <Footer
                {...formik}
                isLastStep={isLastStep}
                laststepText={lastStepText}
                loading={loading}
                onGoBack={onGoBack}
                pathname={history.location.pathname}
                tab={tab}
                total={totalForms}
              />
            </Form>
          )}}
      </Formik>
    </>
  )
}

export default ProfileWizard
