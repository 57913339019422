import { createReducer } from '@reduxjs/toolkit'
import moment from 'moment'
import HandleDaysShifts from 'seniors-first-commons/services/handleDayShifts'
import {
  getDirectProposals,
  respondToOffer,
  getJobOfferDetails,
  applyToJobPostFromDetails,
  respondToOfferFromDetails,
  toggleSuccessStatus,
  getPendingOffersAmount,
  getJobPosts,
  applyToJobPost
} from './actions'

const initValues = {
  directOffers: {
    currentPage: 0,
    items: [],
    pages: 0,
    total: 0
  },
  jobPosts: {
    currentPage: 0,
    items: [],
    total: 0
  },
  loading: false,
  offerApplyLoading: false,
  oneOfferDetails: {
    index: null,
    loading: false
  },
  pendingDirectOffers: 0,
  responseLoading: false,
  success: ''
}

const baseApiToJobOffers = (offer) => {
  offer['created_at'] = moment(offer['created_at']).format('L')
  if(offer.posted_until){
    offer.posted_until = moment(offer.posted_until)
  }
  offer['duration_from'] = moment(offer['duration_from']).format('L')
  offer['duration_to'] = offer['duration_to']
    ? moment(offer['duration_to']).format('L')
    : null
  offer['currency'] = offer?.country_id === 64 ? '€' : offer?.country_id === 185 ? '£' : '$'

  if(offer['hiring_modality'] === 'live_in_care'){
    HandleDaysShifts.handleLiveInCareTimes(offer)
  }
  else{
    HandleDaysShifts.handleVisitingCareTimes(offer)
  }
  return offer
}

const baseApiToJobPosts = (items) => {
  items.map(sheet => {
    sheet['created_at'] = moment(sheet['created_at']).format('L')
    sheet['duration_from'] = moment(sheet['duration_from']).format('L')
    sheet['duration_to'] = sheet['duration_to']
      ? moment(sheet['duration_to']).format('L')
      : null
    sheet['currency'] = sheet?.country_id === 64 ? '€' : sheet?.country_id === 185 ? '£' : '$'

    if (sheet.posted_until){
      sheet.posted_until = moment(sheet.posted_until)
    }

    if (sheet['hiring_modality'] === 'live_in_care'){
      HandleDaysShifts.handleLiveInCareTimes(sheet)
    }
    else HandleDaysShifts.handleVisitingCareTimes(sheet)

    return sheet
  })
  return items
}

const reducer = createReducer(initValues, (builder) => {

  builder.addCase(applyToJobPost.fulfilled, (state, action) => {
    state.jobPosts.items = state.jobPosts.items.map(item => {
      if (item.id === action.payload.service_sheet_id) {
        item.post_applicant_status = 'pending'
      }
      return item
    })
    state.success = 'success.appliedToPost'
  })

  builder.addCase(getJobPosts.fulfilled, (state, action) => {
    state.jobPosts.items = baseApiToJobPosts(action.payload.items)
    state.jobPosts.currentPage = action.payload.page
    state.jobPosts.total = action.payload.total
  })

  builder.addCase(getPendingOffersAmount.fulfilled, (state, action) => {
    state.pendingDirectOffers = action.payload.pending_propositions
  })

  builder.addCase(getDirectProposals.fulfilled, (state, action) => {
    state.directOffers.total = action.payload.total
    state.directOffers.pages = action.payload.pages
    state.directOffers.currentPage = action.payload.page
    state.directOffers.items = action.payload.items.map(item => baseApiToJobOffers(item))
  })

  builder.addCase(respondToOffer.fulfilled, (state, action) => {
    state.directOffers.items  = state.directOffers.items.filter(item => item.id !== action.payload.service_sheet_id)
    state.directOffers.total = state.directOffers.total - 1
    state.success = 'success.directOfferResponse'
  })

  builder.addCase(respondToOfferFromDetails.fulfilled, (state, action) => {
    state.oneOfferDetails.index.job_proposition.status = action.payload.status

    if (action.payload.status === 'interested') {
      state.oneOfferDetails.index.interested_reasons = action.payload.interested_reasons
        .map(({ reason }) => reason)
    }
  })

  builder.addCase(respondToOffer.pending, (state) => {
    state.responseLoading = true
  })

  builder.addCase(toggleSuccessStatus, (state, action) => {
    state.success = action.payload
  })

  builder.addCase(getJobOfferDetails.pending, (state) => {
    state.oneOfferDetails.loading = true
  })

  builder.addCase(getJobOfferDetails.fulfilled, (state, action) => {
    if (
      action.payload.job_proposition &&
      action.payload.job_proposition.status === 'interested'
    ) {
      state.oneOfferDetails.index = {
        ...baseApiToJobOffers(action.payload),
        interested_reasons: action.payload.job_proposition.interested_reasons
          .map(({ reason }) => reason)
      }
    }
    else state.oneOfferDetails.index = baseApiToJobOffers(action.payload)
  })

  builder.addCase(applyToJobPostFromDetails.fulfilled, (state) => {
    state.oneOfferDetails.index = {
      ...state.oneOfferDetails.index,
      post_application: {
        ...state.oneOfferDetails.index.post_application,
        status: 'pending'
      }
    }
  })

  builder.addCase(respondToOfferFromDetails.pending, (state) => {
    state.responseLoading = true
  })

  builder.addMatcher((action) => [
    applyToJobPostFromDetails.pending.toString(),
    applyToJobPost.pending.toString()
  ].includes(action.type), (state) => {
    state.offerApplyLoading = true
  })

  builder.addMatcher((action) => [
    applyToJobPostFromDetails.fulfilled.toString(),
    applyToJobPostFromDetails.rejected.toString(),
    applyToJobPost.fulfilled.toString(),
    applyToJobPost.rejected.toString()
  ].includes(action.type), (state) => {
    state.offerApplyLoading = false
  })

  builder.addMatcher((action) => [
    getJobOfferDetails.fulfilled.toString(),
    getJobOfferDetails.rejected.toString()
  ].includes(action.type), (state) => {
    state.oneOfferDetails.loading = false
  })

  builder.addMatcher((action) => [
    getDirectProposals.pending.toString(),
    getPendingOffersAmount.pending.toString(),
    getJobPosts.pending.toString()
  ].includes(action.type), (state) => {
    state.loading = true
  })

  builder.addMatcher((action) => [
    respondToOffer.fulfilled.toString(),
    respondToOffer.rejected.toString(),

    respondToOfferFromDetails.fulfilled.toString(),
    respondToOfferFromDetails.rejected.toString()
  ].includes(action.type), (state) => {
    state.responseLoading = false
  })

  builder.addMatcher((action) => [
    getJobPosts.fulfilled.toString(),
    getJobPosts.rejected.toString(),
    getPendingOffersAmount.fulfilled.toString(),
    getPendingOffersAmount.rejected.toString(),
    getDirectProposals.fulfilled.toString(),
    getDirectProposals.rejected.toString()
  ].includes(action.type), (state) => {
    state.loading = false
  })
})

export default reducer
