import React from 'react'
import { ServiceModal } from 'seniors-first-commons/components/organisms/Services/ServiceModal'


const CaregiverServicesModals = ({
  activeModal='',
  all_services=[],
  services=[],
  onSelect,
  onSelectAll,
  onRemove,
  onClose
}) => {
  return (
    <>
      <ServiceModal
        data={all_services[1]}
        description='app.boarding.offeredservices.form.homecare.description'
        isOpen={activeModal === 'home'}
        onClose={onClose}
        onRemove={(e) => onRemove(e, 1)}
        onSelect={(e) => onSelect(e)}
        onSelectAll={(e) => onSelectAll(e, 1)}
        selected={services['1']}
        title='app.boarding.offeredservices.form.1'
      />

      <ServiceModal
        data={all_services[2]}
        description='app.boarding.offeredservices.form.companionship.description'
        isOpen={activeModal === 'company'}
        onClose={onClose}
        onRemove={(e) => onRemove(e, 2)}
        onSelect={(e) => onSelect(e)}
        onSelectAll={(e) => onSelectAll(e, 2)}
        selected={services['2']}
        title='app.boarding.offeredservices.form.2'
      />

      <ServiceModal
        data={all_services[3]}
        description='app.boarding.offeredservices.form.personalcare.description'
        isOpen={activeModal === 'personal'}
        onClose={onClose}
        onRemove={(e) => onRemove(e, 3)}
        onSelect={(e) => onSelect(e)}
        onSelectAll={(e) => onSelectAll(e, 3)}
        selected={services['3']}
        title='app.boarding.offeredservices.form.3'
      />

      <ServiceModal
        data={all_services[4]}
        description='app.boarding.offeredservices.form.nursing.description'
        isOpen={activeModal === 'enfermeria'}
        onClose={onClose}
        onRemove={(e) => onRemove(e, 4)}
        onSelect={(e) => onSelect(e)}
        onSelectAll={(e) => onSelectAll(e, 4)}
        selected={services['4']}
        title='app.boarding.offeredservices.form.4'
      />
    </>
  )
}

export default CaregiverServicesModals
