import * as Yup from 'yup'

const AvailabilityModalSchema = Yup.object().shape({
  day_times: Yup.array().min(1, 'validations.selectone').required('validations.required'),
  external_mode: Yup.boolean().when('internal_mode', {
    is: false,
    otherwise: Yup.boolean().notRequired(),
    then: Yup.boolean().oneOf([true], 'validations.required')
  }),
  hiring_min_hours: Yup.number().min(1, 'validations.required')
    .required('validations.required'),
  internal_mode: Yup.boolean().when('external_mode', {
    is: false,
    otherwise: Yup.boolean().notRequired(),
    then: Yup.boolean().oneOf([true], 'validations.required')
  })
}, ['external_mode', 'internal_mode'])

export default AvailabilityModalSchema
