import React from 'react'
import { Field, ErrorMessage } from 'formik'
import { path } from 'ramda'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import PersonalDataInitValues from 'models/personalData'
import { PersonalDataSchema, ContactDetailsSchema } from 'schemas/personalData'
import FieldLabel from 'seniors-first-commons/components/atoms/FieldLabel'
import ErrorHandler from 'seniors-first-commons/components/atoms/ErrorHandler'
import {
  Firstname,
  Username,
  Email,
  Lastname,
  Birthday,
  Gender,
  Nationality,
  DrivingLicense,
  Country,
  Phone,
  Location
} from './inputs'
import Wizard from 'components/organisms/ProfileWizard'
import ProfileBoardingFooter from '../ProfileBoardingFooter'
import classes from './index.module.css'


const InforamationAlreadyCompleted = () => {
  return (
    <div className={`card ${classes.information_already_completed_card}`}>
      <div className='card-content has-text-centered'>
        <p className='primary t4-bold has-margin-b-7'>
          <FormattedMessageWithClass id='app.boarding.personaldata.form.title' />
        </p>
        <div className='columns'>
          <div className='column'>
            <>
              <FieldLabel
                bottomSpace={0}
                color='grey-light'
                id='username'
                labelName='app.boarding.personaldata.form.username'
                labelType='p2-bold'
              />
              <Field
                component={Username}
                id='username'
                name='username'
              />
            </>
            <>
              <FieldLabel
                bottomSpace={0}
                id='firstname'
                labelName='app.boarding.personaldata.form.firstname'
                labelType='p2-bold'
              />
              <Field
                component={Firstname}
                id='firstname'
                name='firstname'
              />
              <ErrorMessage
                component={ErrorHandler}
                name='firstname'
                spaceTop={0}
              />
            </>
          </div>

          <div className='column'>
            <>
              <FieldLabel
                bottomSpace={0}
                color='grey-light'
                id='email'
                labelName='app.boarding.personaldata.form.email'
                labelType='p2-bold'
              />
              <Field
                component={Email}
                id='email'
                name='email'
              />
            </>
            <>
              <FieldLabel
                bottomSpace={0}
                id='lastname'
                labelName='app.boarding.personaldata.form.surname'
                labelType='p2-bold'
              />
              <Field
                component={Lastname}
                id='lastname'
                name='lastname'
              />
              <ErrorMessage
                component={ErrorHandler}
                name='lastname'
                spaceTop={0}
              />
            </>
          </div>
        </div>
      </div>
    </div>
  )
}

const Footer = ({ onGoBack, ...props }) => {
  return (
    <ProfileBoardingFooter
      onBack={onGoBack}
      step={1}
      {...props}
    />
  )
}

const WizardStep = ({ children, ...props }) => children(props)

const PersonalDataForm = ({
  profile,
  user,
  countries,
  isSubmitting,
  ...props
}) => {
  const onSubmit = (values) => {
    const currentFirstname = path(['firstname'], user)
    const currentLastname = path(['lastname'], user)
    // eslint-disable-next-line no-unused-vars
    const { firstname, lastname, email, username, ...restProfile } = values
    if (firstname !== currentFirstname || lastname !== currentLastname) {
      props.updateUser({ ...user, firstname, lastname, success: '' })
        .unwrap()
        .then((response) => props.updateUsernameInProfile({ ...response }))
    }
    props.updateProfile({ ...restProfile })
      .unwrap()
      .then(() => props.moveToNextStep())
  }

  return (
    <Wizard
      enableReinitialize={true}
      Footer={Footer}
      initialValues={new PersonalDataInitValues(profile)}
      loading={isSubmitting}
      onSubmit={onSubmit}
      {...props}
    >
      <WizardStep tab={0} validationSchema={PersonalDataSchema}>
        {({parentState: { values }}) => {
          return (
            <>
              <p className='t1-bold has-text-centered primary has-padding-b-3'>
                <FormattedMessageWithClass id='app.boarding.routes.personalinformation' />
              </p>

              <InforamationAlreadyCompleted />

              <Gender gender={values.gender} />

              <Field component={Birthday} name='birthday' />

              <Field
                component={Nationality}
                countries={countries}
                name='nationalities'
                nationalities={values.nationality}
              />

              <Field
                component={DrivingLicense}
                name='has_driving_license'
              />
            </>
          )
        }}
      </WizardStep>

      <WizardStep tab={1} validationSchema={ContactDetailsSchema}>
        {({parentState: { values }}) => {
          return (
            <>
              <p className='t1-bold primary has-text-centered has-padding-b-3'>
                <FormattedMessageWithClass id='app.boarding.routes.contactdetails' />
              </p>

              <Field
                component={Country}
                countries={countries}
                name='country'
                values={values}
              />

              <Field component={Phone} name='phone' />

              <Field
                component={Location}
                country={values.country}
                name='city'
              />
            </>
          )
        }}
      </WizardStep>
    </Wizard>
  )
}

export default PersonalDataForm
