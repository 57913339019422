import React from 'react'
import useMediaQuery from 'seniors-first-commons/services/useMediaQuery'
import { BREAKPOINTS } from 'seniors-first-commons/styles/constants'


const FullWidthLayout = ({
  additionalClasses='full-content-caregiver' ,
  children
}) => {
  const mediaQuery = useMediaQuery()
  const matchesMobile = mediaQuery[0] <= BREAKPOINTS.MOBILE_MAX

  return (
    <div className={`${additionalClasses}`}>
      <div className={`container section part ${matchesMobile ? 'has-padding-lr-7' : ''}`}>
        {children}
      </div>
    </div>
  )
}

export default FullWidthLayout
