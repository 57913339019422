import React from 'react'
import moment from 'moment'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'

export const getPostParts = (status, posted_until) => {
  let descriptionText = ''
  let header = ''

  switch(status) {
  case undefined:
    descriptionText = 'carer.offerDetails.post.notApplied'
    header = (
      <>
        <FormattedMessageWithClass id='carer.home.post.applyBefore.title' />
        {' '}{moment(posted_until).format('L')}
      </>
    )
    break

  case 'pending':
    descriptionText = 'carer.offerDetails.post.pending'
    header = (
      <FormattedMessageWithClass id='carer.home.post.applied.title' />
    )
    break

  default:
    descriptionText = 'carer.offerDetails.post.accepted'
    header = <FormattedMessageWithClass id='carer.home.post.accepted.title' />
    break
  }

  return {
    descriptionText,
    header
  }
}

export const getDirectOfferDescriptionText = (status) => {
  let descriptionText = ''

  switch(status) {
  case 'pending':
    descriptionText = 'carer.offerDetails.pending.description'
    break

  case 'accepted':
    descriptionText = 'carer.offerDetails.accepted.description'
    break

  case 'rejected':
    descriptionText = 'carer.offerDetails.rejected.description'
    break

  case 'interested':
    descriptionText = 'carer.offerDetails.interested.description'
    break

  default:
    descriptionText = 'carer.offerDetails.applied.description'
    break
  }

  return descriptionText
}

