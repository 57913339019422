import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import moment from 'moment'
import ApiService from 'seniors-first-commons/services/api-service'
import store from 'store/index'

const {
  axiosGet,
  axiosPut,
  axiosPost,
  axiosDelete,
  axiosPatch,
  axiosUpload
} = ApiService


/* eslint-disable no-unused-vars */
const baseProfileDataToApi = ({
  created_at,
  updated_at,
  birthday,
  nationalities,
  country,
  city,
  currency,
  ...rest
}) => {
/* eslint-enable no-unused-vars */
  const data = { ...rest }
  const { label, value, ...restCountry } = country

  if (birthday) data['birthday'] = moment(birthday).format('YYYY-MM-DD')
  if (nationalities) {
    data['nationalities'] = nationalities
      .map(({ label, value, ...rest }) => ({ id: value, name: label, ...rest }))
  }

  data['country'] = { id: value, name: label, ...restCountry }
  data['city'] = city.formatted_address
  data['city_lat'] = city.address_lat
  data['city_lng'] = city.address_lng

  return data
}

const baseServicesDataToApi = ({
  services,
  working_location,
  ...rest
}) => {
  const data = {...rest}

  if (services) {
    data['services'] = Array.prototype.concat.apply([], Object.values(services))
  }

  data['working_location'] = working_location.formatted_address
  data['lat'] = working_location.address_lat
  data['lng'] = working_location.address_lng

  return data
}

const baseHiringConditionsDataToApi = ({
  countries_interested,
  economic_conditions,
  hourly_rate,
  salary,
  ...rest
}) => {
  const data = {...rest}

  if (countries_interested && countries_interested.length) {
    data['countries_interested'] = countries_interested
      .map(({ label, value, ...rest }) => ({ id: value, name: label, ...rest }))
  }
  else data['countries_interested'] = []

  if (economic_conditions === 'min_wage') {
    data['salary'] = null
    data['hourly_rate'] = null
  }
  else {
    if (!hourly_rate) data['hourly_rate'] = null
    else data['hourly_rate'] = hourly_rate

    if (!salary) data['salary'] = null
    else data['salary'] = salary
  }

  return data
}

const baseFormationDataToApi = ({
  languages,
  formation_type,
  other_formation_type,
  ...rest
}) => {
  const data = { ...rest }

  if (languages && languages.length) {
    data['languages'] = languages.map(({ label, value, level }) =>
      ({ language: { id: value, name: label }, level }))
  }
  else data['languages'] = []

  if (Number(formation_type) !== 5) {
    data['other_formation_type'] = null
  }
  else data['other_formation_type'] = other_formation_type

  data['formation_type'] = formation_type

  return data
}

const baseIllnessesExperienceToApi = (illnesses) => {

  if (illnesses && !!illnesses.length) {
    return illnesses.map(({ label, value }) => ({ id: value, name: label }))
  }
  else return illnesses
}

const baseExperienceDataToApi = ({
  from_date,
  to_date,
  service_categories,
  reference_email,
  ...rest
}) => {
  const data = {...rest}
  if (from_date) data['from_date'] = from_date.format('YYYY-MM-DD')
  if (to_date) data['to_date'] = to_date.format('YYYY-MM-DD')

  const services = []
  service_categories.map(service => {
    if(service === '1') services.push({ id: Number(service), name: 'Home care' })
    if (service === '2') services.push({ id: Number(service), name: 'Companionship' })
    if (service === '3') services.push({ id: Number(service), name: 'Personal care' })
    if (service === '4') services.push({ id: Number(service), name: 'Nursing' })

    return service
  })
  data['service_categories'] = services

  if (!reference_email) data['reference_email'] = null
  else data['reference_email'] = reference_email

  return data
}

const baseExperienceFeedbackDataToApi = (payload) => {
  const data = {}

  // first question
  if(payload['trustworthy'] === 'yes'){
    data['trustworthy'] = true
  }
  else{
    data['trustworthy'] = false
  }

  // second question
  if(payload['rating']){
    data['rating'] = parseInt(payload['rating'])
  }

  // third question
  if(payload['tasks_completed']){
    if(payload['tasks_completed'] === 'yes'){
      data['tasks_completed'] = true
    }
    else{
      data['tasks_completed'] = false
    }
  }

  // fourth question
  if(payload['termination_reason']){
    if(payload['termination_reason'] !== 'other'){
      data['termination_reason'] = payload['termination_reason']
    }
    else{
      data['termination_reason'] = payload['termination_reason_other']
    }
  }

  // fifth question
  if(payload['strengths'] && payload['strengths'].length > 0){
    let answers = payload['strengths']

    if(answers.includes('other') && payload['strengths_other']){
      answers = answers.filter(item => item !== 'other')
      answers.push(payload['strengths_other'])
    }
    else if (!answers.includes('other') && answers.includes(payload['strengths_other'])){
      answers.splice(answers['strengths_other'], 1)
    }
    data['strengths'] = answers.join(',')
  }

  // sixth question
  if(payload['weaknesses'] && payload['weaknesses'].length > 0){
    let answers = payload['weaknesses']

    if(answers.includes('other') && payload['weaknesses_other']){
      answers = answers.filter(item => item !== 'other')
      answers.push(payload['weaknesses_other'])
    }
    else if (!answers.includes('other') && answers.includes(payload['weaknesses_other'])){
      answers.splice(answers['weaknesses_other'], 1)
    }
    data['weaknesses'] = answers.join(',')
  }

  if(payload['comments']){
    data['comments'] = payload['comments']
  }

  return data
}


export const getCarerData = createAsyncThunk(
  'profile/getCarerData',
  ({ carer_id }, { rejectWithValue }) => {
    return axiosGet(`/carers/${carer_id}`)
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const updateProfile = createAsyncThunk(
  'profile/updateProfile',
  ({ ...payload }, { rejectWithValue }) => {
    const requestPayload = baseProfileDataToApi(payload)
    return axiosPut('/carer/profile', { ...requestPayload })
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const updateServices = createAsyncThunk(
  'profile/updateServices',
  (payload, { rejectWithValue }) => {
    const requestPayload = baseServicesDataToApi(payload)
    return axiosPut('/carer/offered-services', { ...requestPayload })
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const updateHiringConditions = createAsyncThunk(
  'profile/updateHiringConditions',
  (payload, { rejectWithValue }) => {
    const requestPayload = baseHiringConditionsDataToApi(payload)
    return axiosPut('/carer/hiring-conditions', { ...requestPayload })
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const updateFormation = createAsyncThunk(
  'profile/updateFormation',
  (payload, { rejectWithValue }) => {
    const requestPayload = baseFormationDataToApi(payload)
    return axiosPut('/carer/formation', { ...requestPayload })
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const updateIllnessesExperience = createAsyncThunk(
  'profile/updateIllnessesExperience',
  (illnesses, { rejectWithValue }) => {
    const requestPayload = baseIllnessesExperienceToApi(illnesses)
    return axiosPut('/carer/illness-experiences', requestPayload)
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const getExperience = createAsyncThunk(
  'profile/getExperience',
  ({ experience_id }, { rejectWithValue }) => {
    return axiosGet(`/carer/experiences/${experience_id}`)
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const createExperience = createAsyncThunk(
  'profile/createExperience',
  (payload, { rejectWithValue }) => {
    const requestPayload = baseExperienceDataToApi(payload)
    return axiosPost('/carer/experiences', { ...requestPayload })
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const updateExperience = createAsyncThunk(
  'profile/updateExperience',
  ({ experience_id, ...rest }, { rejectWithValue }) => {
    const requestPayload = baseExperienceDataToApi(rest)
    return axiosPut(`/carer/experiences/${experience_id}`, { ...requestPayload })
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const deleteExperience = createAsyncThunk(
  'profile/deleteExperience',
  ({ experience_id }, { rejectWithValue }) => {
    return axiosDelete(`/carer/experiences/${experience_id}`)
      .then(response => ({ ...response, experience_id }))
      .catch(error => rejectWithValue(error))
  }
)

const onProgress = ({ loaded, total }) => {
  const progress = Math.ceil((loaded / total) * 100)
  store.dispatch(getProgress({ progress }))
  return progress
}

export const fetchVideos = createAsyncThunk(
  'profile/fetchVideos',
  (payload, { rejectWithValue }) => {
    return axiosGet('/carer/videos')
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const fetchOneVideo = createAsyncThunk(
  'profile/fetchOneVideo',
  ({ video_id }, { rejectWithValue }) => {
    return axiosGet(`/carer/videos/${video_id}`)
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const uploadVideo = createAsyncThunk(
  'profile/uploadVideo',
  ({ video_id, size, file }, { rejectWithValue }) => {
    const handleProgress = onProgress

    return axiosPost(`/carer/videos/${video_id}`, { size })
      .then(response => {
        return axiosPatch(response.upload_link, file, 0, handleProgress)
      })
      .catch(error => rejectWithValue(error))
  }
)

export const deleteVideo = createAsyncThunk(
  'profile/deleteVideo',
  ({ video_id }, { rejectWithValue }) => {
    return axiosDelete(`/carer/videos/${video_id}`)
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const uploadPhoto = createAsyncThunk(
  'profile/uploadPhoto',
  ({ photo }, { rejectWithValue }) => {
    const photoBlob = new FormData()
    photoBlob.append('photo', photo)

    return axiosUpload('/carer/photo', photoBlob)
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

//
export const updateOnlyCareServices = createAsyncThunk(
  'profile/updateOnlyCareServices',
  (payload, { rejectWithValue }) => {
    return axiosPut('/carer/offered-services/services', {services: payload})
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const updateWorkingLocation = createAsyncThunk(
  'profile/updateWorkingLocation',
  (payload, { rejectWithValue }) => {
    return axiosPut('/carer/offered-services/working-location', payload)
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const updateAvailability = createAsyncThunk(
  'profile/updateAvailability',
  (payload, { rejectWithValue }) => {
    return axiosPut('/carer/offered-services/day-times', payload)
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const updateBio = createAsyncThunk(
  'profile/updateBio',
  ({ bio }, { rejectWithValue }) => {
    return axiosPut('/carer/bio', { bio })
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const getCaregiverToFeedback = createAsyncThunk(
  'profile/getCaregiverToFeedback',
  ({id}, {rejectWithValue}) => {
    return axiosGet(`/experiences/feedback/${id}`)
      .then((response) => response)
      .catch(error => rejectWithValue(error))
  })

export const submitFeedbackForm = createAsyncThunk(
  'profile/submitFeedbackForm',
  ({ payload, id }, {rejectWithValue}) => {
    const requestPayload = baseExperienceFeedbackDataToApi(payload)
    return axiosPost(`/experiences/feedback/${id}`, requestPayload)
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const updateUsernameInProfile = createAction('profile/updateUsernameInProfile')

export const showNewHome = createAction('profile/showNewHome')

export const getProgress = createAction('profile/getProgress')

export const toggleSuccessStatus = createAction('profile/toggleSuccessStatus')

export const clear = createAction('user/clear')
