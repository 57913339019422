import React from 'react'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import InitialLayout from 'seniors-first-commons/components/templates/InitialLayout'
import RegisterSuccess from 'seniors-first-commons/components/molecules/RegisterSuccess'
import RegisterForm from 'seniors-first-commons/components/organisms/RegisterForm'
import MoreInfoModal from 'seniors-first-commons/components/atoms/MoreInfoModal'


const RegisterLayout = ({
  role,
  locale,
  showMoreInfo,
  registerData,
  onResendEmail,
  onFormSubmit,
  onFBLogin,
  onGoogleLogin,
  onRedirectToLogin,
  onReturnToLogin,
  toggleShowMoreInfo,
  onLogoClick
}) => {
  return (
    <InitialLayout locale={locale} onLogoClick={onLogoClick} role={role}>
      {registerData.data && registerData.data.id ? (
        <RegisterSuccess
          onResend={onResendEmail}
          onReturnToLogin={onReturnToLogin}
          resendingEmail={registerData.loading}
        />
      ) :
        (<>
          <p className='t1-bold primary'>
            <FormattedMessageWithClass
              id={`app.${role}.register.form.title`}
            />
          </p>
          <RegisterForm
            isSubmitting={registerData.loading}
            onFBLogin={onFBLogin}
            onGoogleLogin={onGoogleLogin}
            onLogin={onRedirectToLogin}
            onShowMoreInfo={toggleShowMoreInfo}
            onSubmit={onFormSubmit}
          />
          <MoreInfoModal
            onClose={toggleShowMoreInfo}
            show={showMoreInfo}
          />
        </>
        )}
    </InitialLayout>
  )
}

export default RegisterLayout
