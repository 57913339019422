import { createReducer } from '@reduxjs/toolkit'
import moment from 'moment'
import { path } from 'ramda'
import {
  getCarerData,
  updateProfile,
  updateServices,
  updateHiringConditions,
  updateFormation,
  updateIllnessesExperience,
  createExperience,
  updateExperience,
  deleteExperience,
  deleteVideo,
  uploadVideo,
  getProgress,
  fetchVideos,
  clear,
  toggleSuccessStatus,
  fetchOneVideo,
  uploadPhoto,
  updateBio,
  updateAvailability,
  updateWorkingLocation,
  updateOnlyCareServices,
  updateUsernameInProfile,
  getCaregiverToFeedback,
  submitFeedbackForm,
  showNewHome
} from './actions'
import { AWS_IMAGE_STORAGE, LANG_LOCALSTORAGE_KEY } from 'seniors-first-commons/utils/constants'


const baseApiDataToProfile = (profile) => {
  const birthday = path(['birthday'], profile)
  const nationalities = path(['nationalities'], profile)
  const country = path(['country'], profile)

  if (profile) {
    profile = {
      ...profile,
      birthday: birthday ? moment(birthday) : birthday,
      country: country
        ? {
          code: country.code,
          enabled: country.enabled,
          has_work_permit: country.has_work_permit,
          label: country.name,
          value: country.id
        }
        : null,
      currency: country?.id === 64 ? '€' : country?.id === 185 ? '£' : '$',
      nationalities: nationalities
        ? nationalities.map(({ id, name, ...rest }) => ({ label: name, value: id, ...rest }))
        : []
    }
  }
  return profile
}

const baseApiDataToServices = (offered_service) => {
  const formatted_services = {
    1: [],
    2: [],
    3: [],
    4: []
  }
  const services = path(['services'], offered_service)

  if (services && services.length) {
    services.map(({ service_category_id, name, id }) => {
      switch (service_category_id) {
      case 1:
        formatted_services['1'].push({ id, name, service_category_id, value: name })
        break

      case 2:
        formatted_services['2'].push({ id, name, service_category_id, value: name })
        break

      case 3:
        formatted_services['3'].push({ id, name, service_category_id, value: name })
        break

      case 4:
        formatted_services['4'].push({ id, name, service_category_id, value: name })
        break

      default:
        return formatted_services
      }

      return { id, name, service_category_id }
    })

    offered_service = {
      ...offered_service,
      formatted_services
    }
  }

  return offered_service
}

const baseApiDataToHiringConditions = (hiring_condition) => {
  const countriesInterested = path(['countries_interested'], hiring_condition)

  if (countriesInterested && !!countriesInterested.length) {
    hiring_condition = {
      ...hiring_condition,
      countries_interested: countriesInterested
        .map(({ name, id, ...rest }) => ({ label: name, value: id, ...rest }))
    }
  }

  return hiring_condition
}

const baseApitDataToFormation = (formation) => {
  const languages = path(['languages'], formation)

  if (languages && !!languages.length) {
    formation = {
      ...formation,
      languages: languages.map(({ language, level }) =>
        ({ label: language.name, level, value: language.id }))
    }
  }

  return formation
}

const baseApiDataToIllnessesExperience = (illnessesExperiences) => {
  if (illnessesExperiences && !!illnessesExperiences.length) {
    return illnessesExperiences.map(({ name, id }) => ({ label: name, value: id }))
  }
  else return illnessesExperiences
}

const baseApiDataToExperiences = ({ from_date, to_date, service_categories, ...rest }) => {
  const data = {...rest}

  data['from_date'] = moment(from_date)
  data['to_date'] = moment(to_date)

  data['service_categories'] = service_categories.map(({ id }) => id.toString())

  return data
}

const initState = {
  completedProfile: false,
  experienceFeedback: {
    data: null,
    loading: false,
    submittingFeedback: false
  },
  profile: {
    data: null,
    loadingData: false,
    loadingPhotoUpdate: false,
    loadingVideoUpdate: false,
    updatingProfileData: false
  },

  renderNewHome: true,
  success: '',
  updatingPartialProfileData: false,
  videos: {
    data: [],
    deletingVideo: false,
    uploadingVideo: false
  }
}

const reducer = createReducer(initState, (builder) => {
  builder.addCase(updateUsernameInProfile, (state, action) => {
    state.profile.data = {
      ...state.profile.data,
      firstname: action.payload.firstname,
      lastname: action.payload.lastname
    }
  })

  builder.addCase(updateBio.fulfilled, (state, action) => {
    const currentBio = path(['profile', 'data', 'bio'], state)
    const bio = path(['payload', 'bio'], action)

    state.profile.data = {
      ...state.profile.data,
      bio: currentBio ? {
        ...currentBio,
        bio
      } : action.payload
    }
  })

  builder.addCase(uploadPhoto.fulfilled, (state, action) => {
    const currentBio = path(['profile', 'data', 'bio'], state)
    const photo = path(['payload', 'photo'], action)

    state.profile.data = {
      ...state.profile.data,
      bio: currentBio ? {
        ...currentBio,
        photo
      } : action.payload
    }

    state.success = 'success.uploadPhoto'
  })

  builder.addCase(uploadPhoto.pending, (state) => {
    state.profile.loadingPhotoUpdate = true
  })

  builder.addCase(fetchOneVideo.fulfilled, (state, action) => {
    state.videos.data = [action.payload]
  })

  builder.addCase(fetchVideos.fulfilled, (state, action) => {
    state.videos.data = action.payload
  })

  builder.addCase(getProgress, (state, action) => {
    const { progress } = action.payload

    state.videos.data = [
      { progress }
    ]
  })

  builder.addCase(deleteVideo.fulfilled, (state) => {
    state.videos.data = []
  })

  builder.addCase(deleteVideo.pending, (state) => {
    state.videos.deletingVideo = true
  })

  builder.addCase(uploadVideo.pending, (state) => {
    state.videos.uploadingVideo = true
  })

  builder.addCase(deleteExperience.fulfilled, (state, action) => {
    const experiences = path(['profile', 'data', 'experiences'], state)
    state.profile.data = {
      ...state.profile.data,
      experiences: experiences.filter(exp => exp.id !== action.payload.experience_id)
    }
    state.success = 'success.experienceDeleted'
  })

  builder.addCase(createExperience.fulfilled, (state, action) => {
    state.profile.data = {
      ...state.profile.data,
      experiences: [...state.profile.data.experiences, baseApiDataToExperiences(action.payload)]
    }
    state.success = 'success.createNewExp'
  })

  builder.addCase(updateExperience.fulfilled, (state, action) => {
    state.profile.data = {
      ...state.profile.data,
      experiences: state.profile.data.experiences.map(exp => {
        if (exp.id === action.payload.id) {
          exp = baseApiDataToExperiences(action.payload)
        }
        return exp
      })
    }
  })

  builder.addCase(updateIllnessesExperience.fulfilled, (state, action) => {
    state.profile.data = {
      ...state.profile.data,
      illnesses: baseApiDataToIllnessesExperience(action.payload)
    }
  })

  builder.addCase(updateFormation.fulfilled, (state, action) => {
    state.profile.data = {
      ...state.profile.data,
      formation: baseApitDataToFormation(action.payload)
    }
  })

  builder.addCase(updateHiringConditions.fulfilled, (state, action) => {
    state.profile.data = {
      ...state.profile.data,
      hiring_condition: baseApiDataToHiringConditions(action.payload)
    }
  })

  builder.addCase(updateServices.fulfilled, (state, action) => {
    state.profile.data = {
      ...state.profile.data,
      offered_service: baseApiDataToServices(action.payload)
    }
  })

  builder.addCase(updateProfile.fulfilled, (state, action) => {
    state.profile.data = {
      ...state.profile.data,
      profile: baseApiDataToProfile(action.payload)
    }
  })

  builder.addCase(getCarerData.fulfilled, (state, action) => {
    const profile = path(['payload', 'profile'], action)
    const offeredService = path(['payload', 'offered_service'], action)
    const hiringConditions = path(['payload', 'hiring_condition'], action)

    state.profile.data = {
      ...action.payload,
      experiences: path(['payload', 'experiences', 'length'], action) ? action.payload.experiences
        .map(exp => baseApiDataToExperiences(exp)) : [],
      formation: baseApitDataToFormation(action.payload.formation),
      hiring_condition: baseApiDataToHiringConditions(action.payload.hiring_condition),
      illnesses: baseApiDataToIllnessesExperience(action.payload.illnesses),
      offered_service: baseApiDataToServices(action.payload.offered_service),
      profile: baseApiDataToProfile(action.payload.profile),
      videos: action.payload.videos.map(v => ({ ...v, transcode_status: 'complete', upload_status: 'complete' }))
    }

    state.renderNewHome = !!profile && !!offeredService && !!hiringConditions
    state.completedProfile = !!profile && !!offeredService && !!hiringConditions
  })

  builder.addCase(getCarerData.pending, (state) => {
    state.profile.loadingData = true
  })

  builder.addCase(showNewHome, (state) => {
    state.renderNewHome = true
    state.completedProfile = true
  })

  builder.addCase(clear, () => {
    const lang = localStorage.getItem(LANG_LOCALSTORAGE_KEY)
    localStorage.clear()
    localStorage.setItem(LANG_LOCALSTORAGE_KEY, lang)
    clearTimeout(window.notificationsTimeout)
    if(window.talkSession) {
      // destory the chat session
      console.log('shoud destory all the sessions and UIs')
      window.talkSession.destroy()
      window.talkSession = null
      window.talkInbox = null
    }
  })

  builder.addCase(toggleSuccessStatus, (state, action) => {
    state.success = action.payload
  })

  builder.addCase(updateWorkingLocation.fulfilled, (state) => {
    state.success = 'success.successfullChange'
  })

  builder.addCase(updateOnlyCareServices.fulfilled, (state) => {
    state.success = 'success.successfullChange'
  })

  builder.addCase(updateAvailability.fulfilled, (state) => {
    state.success = 'success.successfullChange'
  })

  builder.addCase(getCaregiverToFeedback.pending, (state) => {
    state.experienceFeedback.loading = true
  })

  builder.addCase(getCaregiverToFeedback.fulfilled, (state, action) => {
    const caregiver = {...action.payload}
    caregiver['from_date'] = moment(caregiver.from_date, 'YYYY-MM-DD').format('L')
    caregiver['to_date'] = moment(caregiver.to_date, 'YYYY-MM-DD').format('L')
    caregiver.caregiver['bio.photo'] = caregiver.caregiver['bio.photo'] ?
      AWS_IMAGE_STORAGE + caregiver.caregiver['bio.photo'] : null
    state.experienceFeedback.data = caregiver
  })

  builder.addCase(submitFeedbackForm.pending, (state) => {
    state.experienceFeedback.submittingFeedback = true
  })

  builder.addCase(submitFeedbackForm.fulfilled, (state) => {
    state.success = 'success.feedbackSent'
  })

  builder.addMatcher((action) => [
    updateProfile.pending.toString(),
    updateServices.pending.toString(),
    updateHiringConditions.pending.toString(),
    updateFormation.pending.toString(),
    updateIllnessesExperience.pending.toString(),
    deleteExperience.pending.toString(),
    createExperience.pending.toString(),
    updateExperience.pending.toString()
  ].includes(action.type), (state) => {
    state.profile.updatingProfileData = true
  })

  builder.addMatcher((action) => [
    deleteVideo.fulfilled.toString(),
    deleteVideo.rejected.toString()
  ].includes(action.type), (state) => {
    state.videos.deletingVideo = false
  })

  builder.addMatcher((action) => [
    uploadVideo.fulfilled.toString(),
    uploadVideo.rejected.toString()
  ].includes(action.type), (state) => {
    state.videos.uploadingVideo = false
  })

  builder.addMatcher((action) => [
    updateProfile.fulfilled.toString(),
    updateProfile.rejected.toString(),
    updateServices.fulfilled.toString(),
    updateServices.rejected.toString(),
    updateHiringConditions.fulfilled.toString(),
    updateHiringConditions.rejected.toString(),
    updateFormation.fulfilled.toString(),
    updateFormation.rejected.toString(),
    updateIllnessesExperience.fulfilled.toString(),
    updateIllnessesExperience.rejected.toString(),
    deleteExperience.fulfilled.toString(),
    deleteExperience.rejected.toString(),
    createExperience.fulfilled.toString(),
    createExperience.rejected.toString(),
    updateExperience.fulfilled.toString(),
    updateExperience.rejected.toString()
  ].includes(action.type), (state) => {
    state.profile.updatingProfileData = false
  })

  builder.addMatcher((action) => [
    getCarerData.fulfilled.toString(),
    getCarerData.rejected.toString()
  ].includes(action.type), (state) => {
    state.profile.loadingData = false
  })

  builder.addMatcher((action) => [
    uploadPhoto.fulfilled.toString(),
    uploadPhoto.rejected.toString()
  ].includes(action.type), (state) => {
    state.profile.loadingPhotoUpdate = false
  })

  builder.addMatcher((action) => [
    updateOnlyCareServices.pending.toString(),
    updateAvailability.pending.toString(),
    updateWorkingLocation.pending.toString()
  ].includes(action.type), (state) => {
    state.updatingPartialProfileData = true
  })

  builder.addMatcher((action) => [
    updateOnlyCareServices.fulfilled.toString(),
    updateOnlyCareServices.rejected.toString(),
    updateAvailability.fulfilled.toString(),
    updateAvailability.rejected.toString(),
    updateWorkingLocation.fulfilled.toString(),
    updateWorkingLocation.rejected.toString()
  ].includes(action.type), (state) => {
    state.updatingPartialProfileData = false
  })

  builder.addMatcher((action) => [
    submitFeedbackForm.fulfilled.toString(),
    submitFeedbackForm.rejected.toString()
  ].includes(action.type), (state) => {
    state.experienceFeedback.submittingFeedback = false
  })

  builder.addMatcher((action) => [
    getCaregiverToFeedback.fulfilled.toString(),
    getCaregiverToFeedback.rejected.toString()
  ].includes(action.type), (state) => {
    state.experienceFeedback.loading = false
  })
})

export default reducer
