import React from 'react'
import { Formik, Form, Field } from 'formik'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import { accountSettingsSchema } from '../../../schemas/accountSettings'
import FieldLabel from 'seniors-first-commons/components/atoms/FieldLabel'
import LanguageSwitch from 'seniors-first-commons/components/organisms/LanguageSwitch'
import {
  Username,
  Firstname,
  Lastname,
  Email,
  Gender,
  Birthday,
  NotificationsToggle,
  EmailNotificationsToggle
} from './inputs'
import classes from './index.module.css'


const AccountSettingsForm = ({
  initialValues,
  isSubmitting,
  onSubmit
}) => {
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={accountSettingsSchema}
    >
      {({ dirty, handleSubmit, values}) => {
        return (
          <>
            <Form>
              <Field
                component={Username}
                id='username'
                name='username'
                placeholder=''
              />

              <Field
                component={Email}
                id='email'
                name='email'
                placeholder=''
              />

              <Field
                component={Firstname}
                id='firstname'
                name='firstname'
                placeholder=''
              />

              <Field
                component={Lastname}
                id='lastname'
                name='lastname'
                placeholder=''
              />

              <Gender gender={values.gender} />

              <Field component={Birthday} name='birthday' />
            </Form>

            <FieldLabel
              bottomSpace={7}
              centered={false}
              id='language'
              labelName='carer.accountSettings.languagePreferences'
              labelType='p1-bold'
              topSpace={3}
            />
            <LanguageSwitch
              additionalClasses={classes.languageSwitch}
              openToUp={true}
            />
            <Field
              component={NotificationsToggle}
              name='receiveNotifications'
            />
            <Field
              component={EmailNotificationsToggle}
              name='receiveEmailNotifications'
            />

            <button
              className={`button filled main-button ${isSubmitting ? 'is-loading' : ''} ${classes.button}`}
              disabled={isSubmitting || !dirty}
              onClick={handleSubmit}
              type='submit'
            >
              <FormattedMessageWithClass id='carer.accountSettings.submitButton' />
            </button>
          </>
        )
      }}
    </Formik>
  )
}

export default AccountSettingsForm
