import React from 'react'
import { connect } from 'react-redux'
import { Redirect, useHistory } from 'react-router-dom'
import { path, pathOr } from 'ramda'
import {
  isFormCompleted,
  isVideoCompleted,
  getCompletionPercentage
} from 'store/profile/selector'
import routingService from 'seniors-first-commons/services/routingService'
import useMediaQuery from 'seniors-first-commons/services/useMediaQuery'
import { uploadPhoto, updateBio } from 'store/profile/actions'
import { blackList } from 'seniors-first-commons/shared/store/auth/actions'
import { clear } from 'store/profile/actions'
import WelcomeFirstScreen from 'components/organisms/WelcomeFirstScreen'
import AppNavbar from 'components/molecules/AppNavbar'
import { ROUTE } from 'root/constants'
import { HOME_FORM_MAP } from 'seniors-first-commons/utils/constants'
import { BREAKPOINTS } from 'seniors-first-commons/styles/constants'

const Welcome = ({
  profile,
  renderNewHome,
  isPersonalDataCompleted,
  isOfferedServicesCompleted,
  isHiringCompleted,
  isFormationCompleted,
  hasExperiences,
  isVideoCompleted,
  completionPercentage,
  uploadingPhoto,
  uploadPhoto,
  updateBio,
  ...props
}) => {
  const history = useHistory()
  const firstname = pathOr('', ['firstname'], props.user)
  const photo = path(['data', 'bio', 'photo'], profile)
  const mediaQuery = useMediaQuery()
  const matchesMobile = mediaQuery[0] <= BREAKPOINTS.MOBILE_MAX
  const allStepsEnabled = isPersonalDataCompleted && isOfferedServicesCompleted && isHiringCompleted

  /* eslint-disable */
  const BOARDING_STEPS = {
    'personal-data': {
      isCompleted: true,
      isEnabled: true,
      name: 'app.home.constants.personalinformation',
      next: 'services'
    },
    'services': {
      isCompleted: isOfferedServicesCompleted,
      isEnabled: isPersonalDataCompleted,
      name: 'app.home.constants.services',
      next: 'hiring-conditions'
    },
    'hiring-conditions': {
      isCompleted: isHiringCompleted,
      isEnabled: isOfferedServicesCompleted && isPersonalDataCompleted,
      name: 'app.home.constants.contracts',
      next: 'formation'
    },
    'formation': {
      isCompleted: isFormationCompleted,
      isEnabled: allStepsEnabled,
      name: 'app.home.constants.education',
      next: 'illnesses-experience'
    },
    'experiences': {
      isCompleted: hasExperiences,
      isEnabled: allStepsEnabled,
      name: 'app.home.constants.experience',
      next: 'experiences'
    },
    'video-cv': {
      isCompleted: isVideoCompleted,
      isEnabled: allStepsEnabled,
      name: 'app.home.constants.videocv',
      next: ''
    }
  }
  /* eslint-disable */

  const onLogout = () => {
    props.blackList()
      .unwrap()
      .then(() => props.clear())
      .finally(() => routingService.push(history, `${ROUTE.LOGIN}`))
  }

  const onSubmitBio = ({ bio }, { setSubmitting }) => {
    updateBio({ bio })
      .then(() => setSubmitting(false))
      .catch(() => setSubmitting(false))
  }


  if (renderNewHome) {
    return <Redirect to={routingService.getFinalRoute(ROUTE.HOME)} />
  }

  return (
    <div className='has-navbar-fixed-top'>
      <AppNavbar
        changeRoute={() => {}}
        matchesMobile={matchesMobile}
        onLogout={onLogout}
        user={{ firstname, photo }}
      />
      <WelcomeFirstScreen
        actions={{ onSubmitBio, uploadPhoto }}
        completionPercentage={completionPercentage}
        firstSteps={BOARDING_STEPS}
        loading={{ uploadingPhoto }}
        matchesMobile={matchesMobile}
        profile={profile}
      />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    completionPercentage: getCompletionPercentage(state),
    hasExperiences: isFormCompleted(state)(HOME_FORM_MAP['experience']),
    isFormationCompleted: isFormCompleted(state)(HOME_FORM_MAP['education']),
    isHiringCompleted: isFormCompleted(state)(HOME_FORM_MAP['hiring']),
    isOfferedServicesCompleted: isFormCompleted(state)(HOME_FORM_MAP['services']),
    isPersonalDataCompleted: isFormCompleted(state)(HOME_FORM_MAP['profile']),
    isVideoCompleted: !!isVideoCompleted(state)(HOME_FORM_MAP['video']),
    profile: state.profile.profile,
    renderNewHome: state.profile.renderNewHome,
    uploadingPhoto: state.profile.profile.loadingPhotoUpdate,
    user: state.user.user.data
  }
}

const mapDispatchToProps = {
  blackList,
  clear,
  updateBio,
  uploadPhoto
}

export default connect(mapStateToProps, mapDispatchToProps)(Welcome)
