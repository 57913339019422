import React from 'react'
import { Formik, Form, Field } from 'formik'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import AvailabilityModalInitValues from 'models/availabilityModal'
import AvailabilityModalSchema from 'schemas/availabilityModal'
import AdvancedCheckbox from 'seniors-first-commons/components/atoms/AdvancedCheckbox'
import { MinHoursInput } from './inputs'
import { Availability } from 'seniors-first-commons/components/organisms/Availability/Availability'
import CustomModal from 'seniors-first-commons/components/atoms/CustomModal'
import classes from './index.module.css'


const Modality = ({
  open,
  matchesMobile,
  profile,
  userId,
  loading,
  onClose,
  updateAvailability,
  getCarer
}) => {

  const handleSubmit = (payload, { setSubmitting, setStatus }) => {
    updateAvailability(payload)
      .unwrap()
      .then(() => {
        onClose()
        setStatus({ success: true })
        setSubmitting(false)
        getCarer({ carer_id: userId })
      })
  }

  return (
    <CustomModal
      cardBodyStyles={{ overflow: 'auto' }}
      onClose={onClose}
      open={open}
      shouldCloseOnOverlayClick
      styles={{ overflow: 'auto' }}
    >
      <div className='has-text-centered'>
        <h3 className='t2-bold primary'>
          <FormattedMessageWithClass id='carer.home.availabilityCard.yourModality' />
        </h3>

        <Formik
          enableReinitialize
          initialErrors={{ force: 'error' }}
          initialValues={new AvailabilityModalInitValues(profile)}
          onSubmit={handleSubmit}
          validationSchema={AvailabilityModalSchema}
        >
          {({ values, dirty }) => {
            return (
              <Form>
                <div className='columns is-mobile has-margin-t-7'>
                  <div className='column'>
                    <Field
                      checked={values.external_mode}
                      component={AdvancedCheckbox}
                      label={(
                        <FormattedMessageWithClass
                          className='p2-bold'
                          id='carer.home.availabilityCard.visiting-care'
                        />
                      )}
                      name='external_mode'
                    />
                  </div>

                  <div className='column'>
                    <Field
                      checked={values.internal_mode}
                      component={AdvancedCheckbox}
                      label={(
                        <FormattedMessageWithClass
                          className='p2-bold'
                          id='carer.home.availabilityCard.live-in-care'
                        />
                      )}
                      name='internal_mode'
                    />
                  </div>
                </div>
                <h3 className='t2-bold primary has-margin-t-6'>
                  <FormattedMessageWithClass id='carer.home.availabilityCard.yourAvailability' />
                </h3>

                <Field
                  component={Availability}
                  days={values.day_times}
                  matchesMobile={matchesMobile}
                  name='day_times'
                  preSetDay={''}
                  preSetTime={[]}
                />

                <Field component={MinHoursInput} name='hiring_min_hours' />

                <button
                  className={`button filled has-margin-t-2 ${loading ? 'is-loading' : ''} ${classes.confirmButton}`}
                  disabled={loading || !dirty}
                  type='submit'
                >
                  <FormattedMessageWithClass id='carer.home.availabilityCard.confirm' />
                </button>
              </Form>
            )
          }}
        </Formik>
      </div>
    </CustomModal>
  )
}

export default Modality
