import React from 'react'
import { connect } from 'react-redux'
import { toast } from 'bulma-toast'
import { injectIntl } from 'react-intl'
import { changeErrorMessageStatus } from 'store/layouts/actions'
import { changeAuthErrorStatus } from 'seniors-first-commons/shared/store/auth/actions'
import { setErrorMessage } from 'seniors-first-commons/shared/store/translations/actions'

const Toast = ({
  error,
  changeErrorMessageStatus,
  changeAuthErrorStatus,
  setErrorMessage,
  ...props
}) => {

  if(error){
    setTimeout(() => {
      changeErrorMessageStatus('')
      changeAuthErrorStatus('')
      setErrorMessage('')
    }, 3000)

    toast({
      closeOnClick: false,
      dismissible: true,
      message: `<span class='p1-bold white'>${props.intl.formatMessage({ id: error })}</span>`,
      pauseOnHover: true,
      position: 'bottom-center',
      type: 'is-danger'
    })

    const closeButton = document.querySelector('.delete')
    closeButton.addEventListener('click', () => {
      changeErrorMessageStatus('')
      changeAuthErrorStatus('')
      setErrorMessage('')
    })
  }
  return <></>
}

const mapStateToProps = (state) => {
  return {
    error: state.layouts.error ||
    state.auth.error ||
    state.auth.login.error ||
    state.intl.error
  }
}

const mapDispatchToProps = {
  changeAuthErrorStatus,
  changeErrorMessageStatus,
  setErrorMessage
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Toast))
