import { path, pathOr } from 'ramda'


export default class HiringConditions {
  constructor(profile) {
    // economic conditions
    this.has_work_permit = pathOr(true, ['hiring_condition', 'has_work_permit'], profile)
    this.accepts_work_with_autonomous = pathOr(false, ['hiring_condition', 'accepts_work_with_autonomous'], profile)
    this.accepts_work_with_company = pathOr(false, ['hiring_condition', 'accepts_work_with_company'], profile)
    this.has_criminal_record_certificate = pathOr(true, ['hiring_condition', 'has_criminal_record_certificate'], profile)
    this.working_abroad = path(['hiring_condition', 'countries_interested'], profile)
    && path(['hiring_condition', 'countries_interested'], profile).length ? true : false
    this.countries_interested = pathOr([], ['hiring_condition', 'countries_interested'], profile)

    // salary
    this.economic_conditions = path(['hiring_condition', 'salary'], profile) ||
    path(['hiring_condition', 'hourly_rate'], profile) ? 'custom' : 'min_wage'
    this.salary = pathOr('', ['hiring_condition', 'salary'], profile)
    this.hourly_rate = pathOr('', ['hiring_condition', 'hourly_rate'], profile)

    // labor conditions
    this.smoke_free = pathOr(false, ['hiring_condition', 'smoke_free'], profile)
    this.pet_friendly = pathOr(true, ['hiring_condition', 'pet_friendly'], profile)
    this.accept_opposite_sex = pathOr(true, ['hiring_condition', 'accept_opposite_sex'], profile)
    this.accept_more_people = pathOr(true, ['hiring_condition', 'accept_more_people'], profile)
    this.accept_driving = pathOr(true, ['hiring_condition', 'accept_driving'], profile)
    this.personal_hygiene = pathOr('no', ['hiring_condition', 'personal_hygiene'], profile)
    this.patient_movilisation = pathOr(false, ['hiring_condition', 'movilization_crane'], profile) ||
    pathOr(false, ['hiring_condition', 'movilization_bodily'], profile) ? true : false
    this.movilization_crane = pathOr(false, ['hiring_condition', 'movilization_crane'], profile)
    this.movilization_bodily = pathOr(false, ['hiring_condition', 'movilization_bodily'], profile)
    this.movilization_max = pathOr(5, ['hiring_condition', 'movilization_max'], profile)
  }
}
