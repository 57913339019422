import React from 'react'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import VideoUploadBar from 'components/molecules/VideoUploadbar'
import { PlayButtonIcon, TrashIcon } from 'seniors-first-commons/components/atoms/images/icons'


const ActionButtons = ({
  onPreview,
  onDelete
}) => (
  <div className='buttons has-addons is-centered'>
    <span className='button is-large direction-vertical' onClick={onPreview}>
      <span className='icon has-text-info'>
        <PlayButtonIcon
          fill='#26DCF8'
          height='30'
          stroke='#26DCF8'
          width='30'
        />
      </span>

      <FormattedMessageWithClass
        className='p3-bold button-text'
        id='app.boarding.videocv.video.preview'
      />
    </span>
    <span className='button is-large direction-vertical' onClick={onDelete}>
      <span className='icon has-text-danger'>
        <TrashIcon
          fill='none'
          height='30'
          stroke='#ED0202'
          width='30'
        />
      </span>

      <FormattedMessageWithClass
        className='p3-bold has-text-danger button-text'
        id='app.boarding.videocv.video.delete'
      />
    </span>
  </div>
)


const VideoCard = ({
  hasVideo,
  video_id,
  translations,
  uploadedStatusText='',
  uploadingProgress=0,
  isUploading,
  onVideUploadBarClick,
  onPreview,
  onDelete
}) => {
  return (
    <div
      className={`button-shadow has-text-centered video is-paddingless-b ${hasVideo ? 'active' : ''} 
      ${(uploadingProgress > 0 && uploadingProgress < 100) ? 'disabled' : ''}`}
      style={{ minWidth: '250px' }}
    >
      <p className={`t2-bold ${hasVideo ? 'white' : 'primary'}`}>
        Video
      </p>
      {!hasVideo && (
        <VideoUploadBar
          isUploading={video_id || uploadingProgress}
          onClick={onVideUploadBarClick}
          progress={uploadingProgress}
          startedUploading={isUploading}
          text={translations.descriptionText}
        />
      )}
      {hasVideo && (
        <>
          <div className='has-text-centered box-content'>
            <p className='white p1-bold has-padding-bt-7'>
              {uploadedStatusText}
            </p>
            <p className='white p2-bold'>
              {translations.descriptionTitle}
            </p>
          </div>
          <ActionButtons
            onDelete={onDelete}
            onPreview={onPreview}
          />
        </>
      )}
    </div>
  )
}

export default VideoCard
