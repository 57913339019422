import React, { useState } from 'react'
import { path, pathOr } from 'ramda'
import { useQuery } from 'seniors-first-commons/shared/hooks'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import withVideo from 'hoc/withVideo'
import withPhoto from 'seniors-first-commons/hoc/withPhoto'
import withBio from 'hoc/withBio'
import ProfileDetails from 'components/organisms/CaregiverProfileDetails'
import HomeCard from 'components/molecules/HomeCard'
import MapContainer from 'seniors-first-commons/components/organisms/Map'
import { handleServiceType } from 'seniors-first-commons/utils/constants'
import OfferedSerivcesModal from 'components/organisms/HomeModals/OfferedServicesModal'
import WorkingLocationModal from 'components/organisms/HomeModals/WorkingLocationModal'
import AvailabilityModal from 'components/organisms/HomeModals/AvailabilityModal'
import ExperiencesModal from 'components/organisms/HomeModals/ExperiencesModal'
import { SuitCase, CalendarIcon } from 'seniors-first-commons/components/atoms/images/icons'
import classes from './index.module.css'

const FirstColumn = ({
  matchesMobile,
  profile,
  user,
  services,
  itemsList,
  completionPercentage,
  uploadedStatusText,
  uploadingVideo,
  uploadingProgress,
  loading,
  actions,
  onOpenVideoUploadZone,
  onPhotoClick,
  onBioBlur,
  onBioChange
}) => {
  const query = useQuery()
  const updateLocationQuery = query.get('updatelocation')
  const userMustCorrectAddress = updateLocationQuery && !!updateLocationQuery && updateLocationQuery === 'true'
  const [showOfferedServicesModal, setShowOfferedServicesModal] = useState(false)
  const [showAvailablityModal, setShowAvailablityModal] = useState(false)
  const [showWorkingLocationModal, setShowWorkingLocationModal] = useState(userMustCorrectAddress ? true : false )
  const [showExperiencesModal, setShowExperiencesModal] = useState(false)

  const offeredServices = path(['data', 'offered_service'], profile)
  const lat = offeredServices?.lat
  const lng = offeredServices?.lng
  const radioKm = offeredServices ? offeredServices.radio_km : 0
  const workingLocation = offeredServices?.working_location
  const allServices = offeredServices && Object.entries(offeredServices.formatted_services)
    .filter(([_, value]) => value.length) // eslint-disable-line no-unused-vars
  const username = `${pathOr('', ['firstname'], user)} ${pathOr('', ['lastname'], user)}`

  const onOpenOfferedServicesModal = () => {
    if (!services) {
      actions.getServices()
        .unwrap()
        .then(() => setShowOfferedServicesModal(true))
    }
    else {
      setShowOfferedServicesModal(true)
    }
  }

  return (
    <>
      <ProfileDetails
        completionPercentage={completionPercentage}
        itemsList={itemsList}
        onBioBlur={onBioBlur}
        onBioChange={onBioChange}
        onOpenVideoUploadZone={onOpenVideoUploadZone}
        onPhotoClick={onPhotoClick}
        profile={profile.data || {}}
        uploadedStatusText={uploadedStatusText}
        uploadingProgress={uploadingProgress}
        uploadingVideo={uploadingVideo}
        username={username}
      />

      <HomeCard
        loading={loading.loadingServices}
        onOpen={onOpenOfferedServicesModal}
        title='carer.home.offeredServicesCard.title'
      >
        <div className={`has-padding-blr-5 ${classes.servicescontainer}`}>
          {allServices && allServices.map((item, index) => {
            return (
              <span
                className={`has-margin-rt-7 custom-tooltip ${classes.serviceiconwrapper}`}
                key={index}
              >
                {handleServiceType(parseInt(item[0]))['image']}

                <FormattedMessageWithClass
                  className='p3-bold white tooltip-content'
                  id={`app.boarding.offeredservices.form.${item[0]}`}
                  placement='top'
                />
              </span>
            )
          })}
        </div>
      </HomeCard>

      <HomeCard
        onOpen={() => setShowWorkingLocationModal(true)}
        title='carer.home.workingLocationCard.title'
      >
        <MapContainer
          location={{
            lat: lat || 48.5000,
            lng: lng || 23.3833
          }}
          markerText={pathOr('Your location', ['data', 'firstname'], profile)}
          options={{ initialCenter:{lat: 48.5000, lng: 23.3833} }}
          radius={radioKm * 1000}
          zoom={8}
        />
        <p className='p2-regular grey has-padding-lr-5 has-padding-bt-7'>
          {workingLocation}
        </p>
      </HomeCard>

      <HomeCard
        onOpen={() => setShowAvailablityModal(true)}
        title='carer.home.availabilityCard.title'
      >
        <div className='has-padding-blr-5' style={{alignItems: 'center', display: 'flex'}}>
          <CalendarIcon
            fill='none'
            height='16px'
            stroke='#525860'
            width='16px'
          />

          <FormattedMessageWithClass
            className='p2-regular grey has-margin-l-7'
            id='carer.home.availabilityCard.text'
          />
        </div>
      </HomeCard>

      <HomeCard
        onOpen={() => setShowExperiencesModal(true)}
        title='carer.home.experiencesCard.title'
      >
        <div className='has-padding-blr-5' style={{alignItems: 'center', display: 'flex'}}>
          <SuitCase
            fill='none'
            height='16px'
            stroke='#525860'
            width='16px'
          />
          <FormattedMessageWithClass
            className='p2-regular grey has-margin-l-7'
            id='carer.home.experiencesCard.text'
          />
        </div>
      </HomeCard>

      <OfferedSerivcesModal
        getCarer={actions.getCarerData}
        loading={loading.updatingPartialProfileData}
        onClose={() => setShowOfferedServicesModal(false)}
        open={showOfferedServicesModal}
        selectedServices={path(['data', 'offered_service', 'formatted_services'], profile)}
        services={services || {1: [], 2: [], 3: [], 4: []}}
        updateOnlyCareServices={actions.updateOnlyCareServices}
        userId={user?.id}
      />

      <WorkingLocationModal
        getCarer={actions.getCarerData}
        loading={loading.updatingPartialProfileData}
        matchesMobile={matchesMobile}
        onClose={() => setShowWorkingLocationModal(false)}
        open={showWorkingLocationModal}
        profile={profile.data}
        updateWorkingLocation={actions.updateWorkingLocation}
        userId={user?.id}
        userMustCorrectAddress={userMustCorrectAddress}
      />

      <AvailabilityModal
        getCarer={actions.getCarerData}
        loading={loading.updatingPartialProfileData}
        matchesMobile={matchesMobile}
        onClose={() => setShowAvailablityModal(false)}
        open={showAvailablityModal}
        profile={profile.data}
        updateAvailability={actions.updateAvailability}
        userId={user?.id}
      />

      <ExperiencesModal
        onClose={() => setShowExperiencesModal(false)}
        open={showExperiencesModal}
      />
    </>
  )
}

export default withVideo(withPhoto(withBio(FirstColumn)))
