import React from 'react'
import { useHistory } from 'react-router-dom'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import routingService from 'seniors-first-commons/services/routingService'
import { BOARDING_ROUTES } from 'seniors-first-commons/utils/constants'
import CustomModal from 'seniors-first-commons/components/atoms/CustomModal'
import classes from './index.module.css'


const WorkingLocationModal = ({
  open,
  onClose
}) => {
  const history = useHistory()

  const onEditExperience = () => {
    onClose()
    routingService.push(history, `/boarding/${BOARDING_ROUTES.experiences}?from=home`)
  }

  const onAddExperience = () => {
    onClose()
    routingService.push(history, `/boarding/${BOARDING_ROUTES.experienceForm}?from=home`)
  }

  return (
    <CustomModal
      onClose={onClose}
      open={open}
      shouldCloseOnOverlayClick={true}
      styles={{ maxWidth: '400px', width: '95%' }}
    >
      <div className='has-text-centered'>
        <h3 className='t2-bold primary has-margin-b-2'>
          <FormattedMessageWithClass id='carer.home.experiencesCard.title' />
        </h3>

        <div className={'columns is-vcentered'}>
          <div className='column'>
            <button
              className={`button outlined ${classes.button}`}
              onClick={onAddExperience}
              style={{background: 'transparent'}}
            >
              + <FormattedMessageWithClass id='carer.home.experiencesCard.button1' />
            </button>
          </div>

          <div className='column'>
            <button
              className={`filled ${classes.button}`}
              onClick={onEditExperience}
            >
              <FormattedMessageWithClass id='carer.home.experiencesCard.button2' />
            </button>
          </div>
        </div>
      </div>
    </CustomModal>
  )
}

export default WorkingLocationModal
