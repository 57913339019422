import React from 'react'
import {connect} from 'react-redux'
import 'bulma-pageloader'

const Loader = ({
  loading
}) => {
  return (
    loading &&
      <div className='pageloader is-active has-background-dark' style={{opacity: '0.85'}}>
        <span className='title'>
          Loading
        </span>
      </div>
  )
}

const mapStateToProps = (state) => {
  return {
    loading:
    state.user.user.loading ||
    state.profile.profile.loadingData ||
    state.profile.experienceFeedback.loading ||
    state.helpers.helpers.loading ||
    state.offers.loading ||
    state.offers.oneOfferDetails.loading
  }
}

export default connect(mapStateToProps, null)(Loader)
