import React from 'react'
import { path } from 'ramda'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import EconomicConditionsInitValues from 'models/hiringConditions'
import { EconomicConditionsSchema, SalarySchema, LaborConditionsSchema } from 'schemas/hiringConditions'
import Wizard from 'components/organisms/ProfileWizard'
import ConfirmModal from 'seniors-first-commons/components/atoms/ConfirmModal'
import {
  WorkType,
  WorkingAbroad,
  CriminalRecord,
  CountriesInterested,
  EconomicConditions,
  HourlyRate,
  MonthlyRate,
  CustomSwitch,
  CarryAbility,
  MobilizationsType,
  PersonalCare
} from './inputs'
import ProfileBoardingFooter from '../ProfileBoardingFooter'
import { CloseCircle } from 'seniors-first-commons/components/atoms/images/icons'
import classes from './index.module.css'


const WizardStep = ({ children, ...props }) => children(props)

const Footer = ({ onGoBack, ...props }) => {
  return (
    <ProfileBoardingFooter
      onBack={onGoBack}
      step={3}
      {...props}
    />
  )
}

const HiringConditionsForm = ({
  profile,
  shouldAskWorkPermit,
  countries,
  modalsState: {
    showNotPermittedModal,
    setShowNotPermittedModal
  },
  isSubmitting,
  ...props
}) => {
  const isLivinCare = path(['offered_service', 'internal_mode'], profile)
  const isVisigingCare = path(['offered_service', 'external_mode'], profile)
  const offeredServices = path(['offered_service', 'formatted_services'], profile)
  const offersPersonalService = offeredServices && (offeredServices['3'].length || offeredServices['4'].length)
  const hasDrivingLicense = path(['profile', 'has_driving_license'], profile)

  const onCloseContinueLaterModal = () => {
    setShowNotPermittedModal(false)
  }

  const onPatientMovilisationChange = ({ ...formik }) => {
    formik.setFieldValue('patient_movilisation', !formik.values.patient_movilisation)
    formik.setFieldValue('movilization_crane', false)
    formik.setFieldValue('movilization_bodily', false)
  }

  /* eslint-disable no-unused-vars */
  const onSubmit = (values) => {
    const {
      working_abroad,
      patient_movilisation,
      has_work_permit,
      accept_driving,
      personal_hygiene,
      ...hiringConditionsRest
    } = values
    /* eslint-enable no-unused-vars */
    props.updateHiringConditions({
      ...hiringConditionsRest,
      accept_driving: accept_driving && hasDrivingLicense,
      has_work_permit: has_work_permit || !shouldAskWorkPermit,
      personal_hygiene: offersPersonalService ? personal_hygiene : 'no'
    })
      .unwrap()
      .then((response) => {
        if (response.has_work_permit) props.moveToNextStep()
        else setShowNotPermittedModal(true)
      })
  }

  return (
    <>
      <Wizard
        enableReinitialize={true}
        Footer={Footer}
        initialValues={new EconomicConditionsInitValues(profile)}
        loading={isSubmitting}
        onSubmit={onSubmit}
        {...props}
      >
        <WizardStep tab={0} validationSchema={EconomicConditionsSchema}>
          {({ parentState: { values, ...formik } }) => {
            return (
              <>
                <p className='has-text-centered primary t1-bold has-padding-b-3'>
                  <FormattedMessageWithClass id='carer.home.profileheader.routes.contracts' />
                </p>

                {shouldAskWorkPermit ?
                  <CustomSwitch
                    labelName='app.boarding.hiring.hiring.hasworkpermit'
                    name='has_work_permit'
                  />
                  : null
                }

                <WorkType
                  accepts_work_with_autonomous={values.accepts_work_with_autonomous}
                  accepts_work_with_company={values.accepts_work_with_company}
                  shouldAskWorkPermit={shouldAskWorkPermit}
                  {...formik}
                />

                <CriminalRecord
                  has_criminal_record_certificate={values.has_criminal_record_certificate}
                />

                <WorkingAbroad working_abroad={values.working_abroad} />

                {values.working_abroad
                  ? <CountriesInterested
                    countries={countries}
                    countries_interested={values.countries_interested}
                  />
                  : null}
              </>
            )
          }}
        </WizardStep>

        <WizardStep tab={1} validationSchema={SalarySchema(profile)}>
          {({ parentState: { values } }) => {
            return (
              <>
                <div className='has-text-centered'>
                  <p className='primary t1-bold has-padding-b-3'>
                    <FormattedMessageWithClass id='carer.home.profileheader.routes.contracts' />
                  </p>
                  <div className={classes.subtitleContainer}>
                    <div className={`${classes.subtitleWrapper}`}>
                      <p className='p1-bold primary'>
                        <FormattedMessageWithClass id='app.boarding.hiring.salary.title' />
                      </p>
                    </div>
                  </div>
                  <p className='p2-bold grey-light has-margin-bt-5'>
                    <FormattedMessageWithClass id='app.boarding.hiring.salary.text' />
                  </p>
                </div>
                <EconomicConditions
                  economic_conditions={values.economic_conditions}
                  profileCountryId={path(['profile', 'country', 'value'], profile)}
                />
                {
                  isLivinCare && values.economic_conditions === 'custom' ?
                    <MonthlyRate currency={path(['profile', 'currency'], profile)} />
                    : null
                }
                {isVisigingCare && values.economic_conditions === 'custom' ?
                  <HourlyRate currency={path(['profile', 'currency'], profile)} />
                  : null
                }
              </>
            )
          }}
        </WizardStep>

        <WizardStep tab={2} validationSchema={LaborConditionsSchema(profile)}>
          {({ parentState: { values, errors, ...formik } }) => {
            return (
              <>
                <p className='primary t1-bold has-padding-b-3 has-text-centered'>
                  <FormattedMessageWithClass id='carer.home.profileheader.routes.contracts' />
                </p>

                <p className='p1-bold grey-light has-text-centered'>
                  <FormattedMessageWithClass id='app.boarding.hiring.conditions.title' />
                </p>

                <CustomSwitch
                  labelName='app.boarding.hiring.conditions.smokefree'
                  name='smoke_free'
                />

                <CustomSwitch
                  labelName='app.boarding.hiring.conditions.petfriendly'
                  name='pet_friendly'
                />

                <CustomSwitch
                  labelName='app.boarding.hiring.conditions.acceptoppositesex'
                  name='accept_opposite_sex'
                />

                <CustomSwitch
                  labelName='app.boarding.hiring.conditions.acceptmorepeople'
                  name='accept_more_people'
                />

                {hasDrivingLicense &&
                  <CustomSwitch
                    labelName='app.boarding.hiring.conditions.acceptdriving'
                    name='accept_driving'
                  />
                }

                {offersPersonalService ?
                  (
                    <>
                      <PersonalCare personal_hygiene={values.personal_hygiene} />

                      <CustomSwitch
                        labelName='app.boarding.hiring.conditions.mobilisation'
                        name='patient_movilisation'
                        onChange={() => onPatientMovilisationChange({ values, ...formik })}
                      />

                      {values.patient_movilisation &&
                        <>
                          <MobilizationsType
                            errors={errors}
                            movilization_bodily={values.movilization_bodily}
                            movilization_crane={values.movilization_crane}
                          />

                          {values.movilization_bodily &&
                            <CarryAbility movilization_max={values.movilization_max} />
                          }
                        </>
                      }
                    </>
                  )
                  : null
                }
              </>
            )
          }}
        </WizardStep>
      </Wizard>

      <ConfirmModal
        cancel='app.boarding.hiring.workpermit.later'
        confirm='app.boarding.hiring.workpermit.continue'
        details='app.boarding.hiring.workpermit.subtitle'
        Icon={CloseCircle}
        onCancel={props.onContinueLaterFromWorkPermitModal}
        onClose={onCloseContinueLaterModal}
        onConfirm={props.onContinueFromWorkPermitModal}
        show={showNotPermittedModal}
        title='app.boarding.hiring.workpermit.title'
      />
    </>
  )
}

export default HiringConditionsForm

