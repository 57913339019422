import React, { useState } from 'react'
import { useHistory, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { path, pathOr } from 'ramda'
import useMediaQuery from 'seniors-first-commons/services/useMediaQuery'
import routingService from 'seniors-first-commons/services/routingService'
import { shouldAskWorkPermit } from 'store/profile/selector'
import {
  updateProfile,
  updateServices,
  updateHiringConditions,
  updateFormation,
  updateIllnessesExperience,
  getExperience,
  createExperience,
  deleteExperience,
  updateExperience,
  updateUsernameInProfile,
  showNewHome,
  clear
} from 'store/profile/actions'
import { blackList } from 'seniors-first-commons/shared/store/auth/actions'
import {
  getServices,
  getIllnesses,
  getLanguages
} from 'seniors-first-commons/shared/store/helpers/actions'
import { updateUser } from 'seniors-first-commons/shared/store/user/actions'
import WelcomeLayout from 'components/templates/WelcomeLayout'
import ProfileBoardingLayout from 'components/templates/ProfileBoardingLayout'
import PersonalDataForm from 'components/organisms/PersonalDataForm'
import ServicesForm from 'components/organisms/ServicesForm'
import HiringConditionsForm from 'components/organisms/HiringConditionsForm'
import FormationForm from 'components/organisms/FormationForm'
import IllnessesExperience from 'components/organisms/IllnessesExperience'
import Experiences from 'components/organisms/Experiences'
import ExperienceForm from 'components/organisms/ExperiencesForm'
import VideoCv from 'components/organisms/VideoCv'
import { ROUTE } from 'root/constants'

const BOARDING_STEPS = {
  'experience': {
    form: ExperienceForm,
    next: 'experiences'
  },
  'experiences': {
    form: Experiences,
    next: 'video-cv'
  },
  'formation': {
    form: FormationForm,
    next: 'illnesses-experience'
  },
  'hiring-conditions': {
    form: HiringConditionsForm,
    next: 'formation'
  },
  'illnesses-experience': {
    form: IllnessesExperience,
    next: 'experiences'
  },
  'personal-data': {
    form: PersonalDataForm,
    next: 'services'
  },
  'services': {
    form: ServicesForm,
    next: 'hiring-conditions'
  },
  'video-cv': {
    form: VideoCv
  }
}

const WelcomeSteps = ({
  countries,
  services,
  profile,
  renderNewHome,
  isSubmitting,
  shouldAskWorkPermit,
  ...props
}) => {
  const [showAddExperiencesModal, setShowAddExperiencesModal] = useState(false)
  const [showNotPermittedModal, setShowNotPermittedModal] = useState(false)
  const [showAddAnotherExpModal, setShowAddAnotherExpModal] = useState(false)
  const step = props.match.params.step
  const history = useHistory()
  const mediaQuery = useMediaQuery()
  const matchesMobile = mediaQuery[0] <= 768
  const firstname = pathOr('', ['firstname'], props.user)
  const photo = path(['data', 'bio', 'photo'], profile)

  const moveToNextStep = () => routingService.push(history, `${ROUTE.WELCOME}/${BOARDING_STEPS[step].next}`)
  const goHome = () => {
    props.showNewHome()
    routingService.push(history, `${ROUTE.HOME}`)
  }
  const onAddExperience = () => {
    routingService.push(history, `${ROUTE.WELCOME}/experience`)
  }
  const onEditExperience = (id) => {
    routingService.push(history, `${ROUTE.WELCOME}/experience?experience_id=${id}`)
  }
  const continueToExperiences = () => {
    routingService.push(history, `${ROUTE.WELCOME}/experiences`)
  }
  const onContinueFromWorkPermitModal = () => {
    setShowNotPermittedModal(false)
    routingService.push(history, `${ROUTE.WELCOME}/formation`)
  }
  const onContinueLaterFromWorkPermitModal = () => {
    setShowNotPermittedModal(false)
    props.showNewHome()
    routingService.push(history, `${ROUTE.HOME}`)
  }
  const onConfirmFromAddNewExpModal = () => {
    setShowAddExperiencesModal(false)
    routingService.push(history, `${ROUTE.WELCOME}/illnesses-experience`)
  }
  const onSkipAddNewExpModal = () => {
    setShowAddExperiencesModal(false)
    routingService.push(history, `${ROUTE.WELCOME}/video-cv`)
  }
  const onLogout = () => {
    props.blackList()
      .unwrap()
      .then(() => props.clear())
      .finally(() => routingService.push(history, `${ROUTE.LOGIN}`))
  }
  if (props.renderNewHome) {
    return <Redirect to={routingService.getFinalRoute(ROUTE.HOME)} />
  }
  return (
    <WelcomeLayout
      matchesMobile={matchesMobile}
      onLogout={onLogout}
      renderNewHome={renderNewHome}
      user={{ firstname, photo }}
    >
      <ProfileBoardingLayout
        actions={{
          continueToExperiences,
          goHome,
          moveToNextStep,
          onAddExperience,
          onConfirmFromAddNewExpModal,
          onContinueFromWorkPermitModal,
          onContinueLaterFromWorkPermitModal,
          onEditExperience,
          onSkipAddNewExpModal,
          ...props
        }}
        countries={countries}
        isSubmitting={isSubmitting}
        matchesMobile={matchesMobile}
        modalsState={{
          setShowAddAnotherExpModal,
          setShowAddExperiencesModal,
          setShowNotPermittedModal,
          showAddAnotherExpModal,
          showAddExperiencesModal,
          showNotPermittedModal
        }}
        profile={profile}
        services={services}
        shouldAskWorkPermit={shouldAskWorkPermit}
        step={step}
        steps={BOARDING_STEPS}
      />
    </WelcomeLayout>
  )
}

const mapStateToProps = (state) => ({
  countries: state.helpers.helpers.countries,
  illnesses: state.helpers.helpers.illnesses,
  isSubmitting: state.profile.profile.updatingProfileData ||
  state.profile.videos.uploadingVideo ||
  state.profile.videos.deletingVideo,
  languages: state.helpers.helpers.languages,
  profile: state.profile.profile.data,
  renderNewHome: state.profile.renderNewHome,
  services: state.helpers.helpers.services,
  shouldAskWorkPermit: shouldAskWorkPermit(state),
  user: state.user.user.data,
  videos: state.profile.videos.data
})

const mapDispatchToProps = {
  blackList,
  clear,
  createExperience,
  deleteExperience,
  getExperience,
  getIllnesses,
  getLanguages,
  getServices,
  showNewHome,
  updateExperience,
  updateFormation,
  updateHiringConditions,
  updateIllnessesExperience,
  updateProfile,
  updateServices,
  updateUser,
  updateUsernameInProfile
}

export default connect(mapStateToProps, mapDispatchToProps)(WelcomeSteps)
