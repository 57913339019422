import React from 'react'
import { connect } from 'react-redux'
import { updateBio } from 'store/profile/actions'
import { extractEmail, extractNumbers } from 'seniors-first-commons/utils/regularExpressions'

const mapDispatchToProps = {
  updateBio
}

const withBio = (WrappedComponent) => {
  const InnerComponent = ({
    updateBio,
    ...props
  }) => {
    const saveBio = (bio) => updateBio({ bio })

    const onBioBlur = (e) => {
      let textContent = e.target.textContent
      textContent = extractNumbers(extractEmail(textContent))
      saveBio(textContent)
    }

    const onBioChange = (e) => {
      const MAX_NUMBER = 501
      const textContent = e.target.textContent
      if(e.which !== 8 && textContent.length > MAX_NUMBER){
        e.preventDefault()
      }
    }

    return (
      <>
        <WrappedComponent
          onBioBlur={onBioBlur}
          onBioChange={onBioChange}
          {...props}
        />
      </>
    )
  }

  const EnhancedComponent = connect(null, mapDispatchToProps)(InnerComponent)
  return EnhancedComponent
}

export default withBio
