import React from 'react'
import { path } from 'ramda'
import withPhoto from 'seniors-first-commons/hoc/withPhoto'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import ProfileSettingsInitValues from 'models/ProfileSettingsInitValue'
import Container from 'components/organisms/AppContainer'
import AccountSettingsForm from 'components/organisms/AccountSettingsForm'
import UploadImage from 'seniors-first-commons/components/atoms/UploadImage'
import FullWidthLayout from '../FullWidthLayout'

const AccountSettingsLayout = ({
  profile,
  loading: { loadingUpdates },
  actions: { onFormSubmit },
  onPhotoClick,
  user
}) => {
  const photo = path(['data', 'bio', 'photo'], profile)
  const userReceivesWhatsappNotifications = path(['receive_notifications'], user)
  const userReceivesEmailNotifications = path(['receive_email_notifications'], user)

  return (
    <Container additionalClass='part' name='Account settings'>
      <FullWidthLayout additionalClasses=''>
        <div className='columns is-centered'>
          <div className='column is-one-quarter'>
            <div>
              <UploadImage
                image={photo}
                onClick={onPhotoClick}
              />
            </div>
          </div>

          <div className='column is-three-quarters'>
            <div style={{ maxWidth: '600px' }}>
              <FormattedMessageWithClass
                className='t1-bold primary has-padding-b-5'
                id='carer.accountSettings.title'
              />

              <AccountSettingsForm
                initialValues={new ProfileSettingsInitValues({
                  ...profile.data,
                  receive_email_notifications: userReceivesEmailNotifications,
                  receive_notifications: userReceivesWhatsappNotifications
                })}
                isSubmitting={loadingUpdates}
                onSubmit={onFormSubmit}
              />
            </div>
          </div>
        </div>
      </FullWidthLayout>
    </Container>
  )
}

export default withPhoto(AccountSettingsLayout)

