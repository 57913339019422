import React, {useState} from 'react'
import CustomSlider from '../../../seniors-first-commons/components/atoms/Slider'
import {useIntl} from 'react-intl'

const DistanceSlider = (props) => {
  const [slideValue,setSlideValue] = useState(50)

  const {locale} = useIntl()
  const mustUseMiles = ['en-US','en-GB'].includes(locale)

  const customSliderText = mustUseMiles ? ' mi' : ' km'


  const formProxy = {}
  const fieldProxy = {}

  fieldProxy.value = slideValue
  formProxy.setFieldValue = (name,e)=>{
    setSlideValue(e)
    const distance = mustUseMiles ? e * 1.60934 : e
    props.form.setFieldValue('radio_km', distance)
  }

  const customDistanceProps = {...props,field: fieldProxy,form: formProxy,sliderText: customSliderText}


  return (
    <>
      <CustomSlider {...customDistanceProps}/>
    </>
  )

}

export default DistanceSlider
