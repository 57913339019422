import React, { useRef } from 'react'
import { Formik, Form, Field } from 'formik'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import CustomModal from 'seniors-first-commons/components/atoms/CustomModal'
import Checkbox from 'seniors-first-commons/components/atoms/Checkbox'
import { interestedModalOptions } from 'seniors-first-commons/utils/constants'
import classes from './index.module.css'


const InterestedReasonsForm = ({
  open,
  loading,
  onSubmit,
  onClose
}) => {
  const formRef = useRef(null)

  const onModalClose = () => {
    formRef.current.resetForm()
    onClose()
  }

  return (
    <CustomModal
      cardBodyStyles={{ padding: '35px' }}
      onClose={onModalClose}
      open={open}
      styles={{ maxWidth: '450px', width: '95%' }}
    >
      <div className='has-text-centered'>
        <Formik
          enableReinitialize
          initialValues={{reasons: []}}
          innerRef={(ref) => formRef.current = ref}
          onSubmit={onSubmit}
        >
          {({ dirty }) => (
            <Form>
              <FormattedMessageWithClass
                className='t2-bold grey'
                id='carer.directoffers.interestedForm.title'
              />
              <br />
              <FormattedMessageWithClass
                className='p1-regular has-margin-t-7 grey'
                id='carer.directoffers.interestedForm.text'
              />

              <div className={`has-padding-bt-5 ${classes.optionsContainer}`}>
                {interestedModalOptions.map((option, index) => (
                  <div className='has-margin-bt-7' key={index}>
                    <Field
                      name='reasons'
                      type='checkbox'
                      value={option.value}
                    >
                      {({ field }) => {
                        return (
                          <Checkbox
                            {...field}
                            id={option.value}
                          />
                        )
                      }}
                    </Field>
                    <label
                      className='has-margin-l-7 p1-regular grey is-capitalized'
                      htmlFor={option.value}
                    >
                      <FormattedMessageWithClass
                        id={`carer.directoffers.interestedForm.${option.value}`}
                      />
                    </label>
                  </div>
                ))}
              </div>
              <button
                className={`button filled ${loading ? 'is-loading' : '' } ${classes.submitButton}`}
                disabled={loading || !dirty}
                type='submit'
              >
                <FormattedMessageWithClass id='carer.directoffers.interestedForm.button' />
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </CustomModal>
  )
}

export default InterestedReasonsForm
