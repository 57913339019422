import React, { useEffect, useRef } from 'react'
import Talk from 'talkjs'
import Chat from 'seniors-first-commons/services/chat'
import EmptyChats from 'seniors-first-commons/components/atoms/EmptyChats'
import classes from './index.module.css'


const ChatInboxModal = ({
  role,
  open=false,
  inbox,
  caregiverChatId='',
  setUnreads,
  setInbox,
  toggleChatModal,
  authed
}) => {
  const inboxRef = useRef(null)
  const chatHeaderRef = useRef(null)
  const feedHeader = useRef(null)
  const backButtonRef = useRef(null)
  const titleRef = useRef(null)

  useEffect(() => {
    if (caregiverChatId && !inbox) {
      Talk.ready
        .then(() => {
          const chat = new Chat(
            role,
            null,
            chatHeaderRef,
            feedHeader,
            titleRef,
            backButtonRef,
            toggleChatModal,
            setUnreads
          )

          chat.startSession(caregiverChatId)
          chat.createInbox()
          setInbox(true)
          if (!inboxRef.current.firstChild) chat.mountInbox(inboxRef.current)
        })
        .catch(error => console.log(error))
    }
    // eslint-disable-next-line
  }, [setUnreads, toggleChatModal, setInbox, caregiverChatId, inbox])


  if (authed) {
    return (
      <div className={`modal is-clipped ${open ? 'is-active' : ''} ${classes.modalContainer}`} >
        <div className={`modal-content ${classes.modal}`}>
          {caregiverChatId ?
            <div id='chat-container-sm'>
              <div id='header-row-sm'>
                <div id='feed-header-sm' ref={feedHeader}>
                  <span className='p1-bold black'></span>
                </div>
                <div id='chatbox-header-sm' ref={chatHeaderRef}>
                  {/* eslint-disable-next-line */}
                  <a id="back-btn-sm" ref={backButtonRef}></a>
                  <span className='p1-bold black' id='chat-title-sm' ref={titleRef}></span>
                </div>
              </div>
              <div className={classes.inboxContainer} id='talkjs-container-sm' ref={inboxRef}></div>
            </div>
            : <EmptyChats role='carer' />
          }
        </div>
      </div>
    )
  }
  else return null
}

export default ChatInboxModal
