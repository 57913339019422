import React from 'react'
import FieldLabel from 'seniors-first-commons/components/atoms/FieldLabel'
import Slider from 'seniors-first-commons/components/atoms/Slider'

export const MinHoursInput = (props) => {
  return (
    <>
      <FieldLabel
        bottomSpace={0}
        id='hiring_min_hours'
        labelName='carer.home.availabilityCard.minHours'
        labelType='p3-bold'
        topSpace={5}
      />

      <Slider
        max={8}
        name='hiring_min_hours'
        sliderText='h'
        style={{ paddingBottom: '1rem' }}
        {...props}
      />
    </>
  )
}
