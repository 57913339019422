import React, { useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { getDirectProposals } from 'store/offers/actions'
import routingService from 'seniors-first-commons/services/routingService'
import { useQuery } from 'seniors-first-commons/shared/hooks'
import AppContainer from 'components/organisms/AppContainer'
import FullWidthLayout from 'components/templates/FullWidthLayout'
import DirectOffersBody from 'components/organisms/DirectOffersBody'
import { ROUTE } from 'root/constants'
import { ITEMS_PER_PAGE } from 'seniors-first-commons/utils/constants'

const DirectOffers = ({
  directOffers,
  user,
  loading,
  interestedReasons,
  getDirectProposals
}) => {
  const history = useHistory()
  const query = useQuery()
  const selectedTab = query.get('tab')

  const fetchOffers = useCallback(() => {
    getDirectProposals({
      page: 1,
      per_page: ITEMS_PER_PAGE,
      status: selectedTab
    })
  }, [getDirectProposals, selectedTab])

  useEffect(() => {
    fetchOffers()
  }, [fetchOffers])

  const onTabChange = (item) => {
    routingService.push(history, `${ROUTE.DIRECT_OFFERS}?tab=${item.value}`)
  }

  const handlePageChange = (e) => {
    if (e !== directOffers.currentPage) {
      getDirectProposals({
        page: e,
        per_page: ITEMS_PER_PAGE,
        status: selectedTab
      })
    }
    else return
  }

  return (
    <AppContainer name='Direct offers'>
      <FullWidthLayout>
        <DirectOffersBody
          actions={{
            handlePageChange,
            onTabChange
          }}
          directOffers={directOffers}
          loading={{
            loading,
            loadingInterestedReasons: interestedReasons.loadingReasons
          }}
          selectedTab={selectedTab}
          user={user}
        />
      </FullWidthLayout>
    </AppContainer>
  )
}

const mapStateToProps = (state) => {
  return {
    directOffers: state.offers.directOffers,
    interestedReasons: state.helpers.interestedReasons,
    loading: state.offers.loading,
    responseLoading: state.offers.responseLoading,
    user: state.user.user.data
  }
}

const mapDispatchToProps = {
  getDirectProposals
}

export default connect(mapStateToProps, mapDispatchToProps)(DirectOffers)
