import React from 'react'
import { path, pathOr } from 'ramda'
import { useHistory } from 'react-router-dom'
import { FormattedMessageWithClass, useFormatMessage } from 'seniors-first-commons/shared/store/translations/effects'
import routingService from 'seniors-first-commons/services/routingService'
import UploadImage from 'seniors-first-commons/components/atoms/UploadImage'
import Rating from 'seniors-first-commons/components/atoms/Rating'
import {
  VideoIcon,
  ArrowRightIcon,
  ShieldIcon
} from 'seniors-first-commons/components/atoms/images/icons'
import ProgressBar from 'seniors-first-commons/components/molecules/ProgressBar'
import userPhoto from 'seniors-first-commons/components/atoms/images/user.png'
import classes from './index.module.css'


const UploadVideoButton = ({ ...props }) => {
  return (
    <button
      className='button-text is-flex is-paddingless-lr'
      disabled={props.uploadingProgress < 100 || props.uploadingVideo}
      onClick={props.onOpenVideoUploadZone}
      style={{ alignItems: 'center' }}
    >
      <VideoIcon
        className='button-text-icon'
        fill='none'
        height='21'
        width='21'
      />
      <span className='p3-bold' style={{ marginLeft: '5px' }}>
        {props.uploadedStatusText}
      </span>
    </button>
  )
}

const CaregiverProfileDetails = ({
  profile: {
    bio,
    rating,
    experience_ratio
  },
  username,
  completionPercentage,
  itemsList,
  onPhotoClick,
  onBioBlur,
  onBioChange,
  onOpenVideoUploadZone,
  ...props
}) => {
  const history = useHistory()
  const translations = {
    bio: useFormatMessage('carer.home.profileheader.typeBio')
  }

  return (
    <div className='card is-borderless' style={{ overflow: 'visible' }}>
      <div className={`card-header ${classes.header}`}>
        <div className='columns is-mobile'>
          <div className='column is-narrow is-relative'>
            <UploadImage
              additionalClass='is-96x96'
              image={pathOr(userPhoto, ['photo'], bio)}
              onClick={onPhotoClick}
            />
            {experience_ratio && experience_ratio >= 0.5 &&
              <div className={`custom-tooltip ${classes.validIconWrapper}`}>
                <ShieldIcon
                  height='20'
                  stroke='#26DCF8'
                  width='20'
                />
                <FormattedMessageWithClass
                  className='tooltip-content p3-bold white'
                  id='carer.home.profileheader.validExp'
                  placement='top'
                />
              </div>
            }
          </div>
          <div className='column is-three-fifths'>
            <p className='t3-bold ellipsis'>{username}</p>
            <div style={{ marginBottom: '5px', marginTop: '5px' }}>
              <Rating rating={rating} />
            </div>
            <UploadVideoButton
              {...props}
              onOpenVideoUploadZone={onOpenVideoUploadZone}
            />
          </div>
        </div>
        <div
          className={`p3-regular has-margin-b-7 ${classes.bio}`}
          contentEditable='true'
          dataplaceholder={translations['bio']}
          onBlur={onBioBlur}
          onKeyDown={onBioChange}
          suppressContentEditableWarning={true}
        >
          {path(['bio'], bio)}
        </div>
      </div>
      <div className='has-text-left'>
        <ul>
          {itemsList.map((item, index) => {
            return (
              <li key={index}>
                {index === 0 && <hr className='divider' />}
                <button
                  className={`button-text ${classes.listItem}`}
                  disabled={!item.enabled}
                  onClick={() => routingService.push(history, `/boarding/${item.route}`)}
                >
                  <FormattedMessageWithClass className='p2-bold' id={item.text} />
                  <ArrowRightIcon className='button-text-icon' height='24px' width='24px' />
                </button>

                {index !== (itemsList.length - 1) && <hr className='divider' />}
              </li>
            )
          })}
        </ul>
      </div>
      <div className={`has-text-left has-margin-t-6 ${classes.cardFooter}`}>
        <p className='white is-flex'>
          <FormattedMessageWithClass
            className='p1-regular'
            id='carer.home.profileheader.profileCompleted.text1'
          />
          <span className='t4-bold'>
            %{completionPercentage}
          </span>
        </p>
        <ProgressBar bgcolor='#0080F5' completed={completionPercentage} />
        <p className='white p1-regular'>
          <FormattedMessageWithClass id='carer.home.profileheader.profileCompleted.text2' />
        </p>
      </div>
    </div>
  )
}

export default CaregiverProfileDetails
