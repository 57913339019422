import React from 'react'
import JobOfferCard from 'components/organisms/JobOfferCard'
import RejectedOfferBody from 'components/molecules/RejectedOfferBody'


const Rejected = ({ matchesMobile, item, actions }) => {
  return (
    <JobOfferCard
      data={{...item, posted_until: null}}
      matchesMobile={matchesMobile}
      onOpenDaysAndShiftsModal={actions.onOpenDaysAndShiftsModal}
    >
      <RejectedOfferBody />
    </JobOfferCard>
  )
}

export default Rejected
