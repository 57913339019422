import React from 'react'
import NotFoundPage from 'seniors-first-commons/components/templates/NotFound'

const ProfileBoardingLayout = ({
  step,
  steps,
  actions,
  ...props
}) => {
  if (!step) {
    return null
  }

  const Form = steps[step] ? steps[step].form : NotFoundPage

  return (
    <div className='card' style={{ overflow: 'visible' }}>
      <div className='card-content'>
        <div style={{ marginBottom: '5rem' }}>
          <div className='columns is-centered'>
            <div className={`column ${step === 'experiences' ? 'is-full' : step === 'experience' ? 'is-half' : 'is-three-fifths-tablet is-half-widescreen'}`}>
              <Form
                step={step}
                steps={steps}
                {...actions}
                {...props}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProfileBoardingLayout
