export default class ExperienceFeedback {
  constructor() {
    this.trustworthy = ''
    this.rating = ''
    this.tasks_completed = ''
    this.termination_reason = ''
    this.termination_reason_other = ''
    this.strengths = ''
    this.strengths_other = ''
    this.weaknesses = ''
    this.weaknesses_other = ''
    this.comments = ''
  }
}
