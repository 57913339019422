import * as Yup from 'yup'


export const FormationSchema = Yup.object().shape({
  formation_type: Yup.string().required('validations.required'),
  other_formation_type: Yup.string().when('formation_type', {
    is: '5',
    otherwise: Yup.string().notRequired(),
    then: Yup.string().required('validations.required')
  })
})
