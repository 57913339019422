import React from 'react'
import { path } from 'ramda'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import AcceptedOfferBody from 'components/molecules/AcceptedOfferBody'
import InterestedOfferBody from 'components/molecules/InterestedOfferBody'
import RejectedOfferBody from 'components/molecules/RejectedOfferBody'
import classes from '../index.module.css'


const DirectOfferStatus = ({
  status,
  offer,
  onAcceptDirectOffer,
  onShowInterest,
  onRejectDirectOffer,
  onViewInterestedReasons
}) => {
  const hasInterestReasons = path(['job_proposition', 'interested_reasons', 0], offer)
  switch (status) {
  case 'pending':
    return (
      <>
        <button
          className={`filled ${classes.actionButton}`}
          onClick={() => onAcceptDirectOffer(offer?.id)}
        >
          <FormattedMessageWithClass id='carer.directoffers.actions.accept.button' />
        </button>
        <button
          className={`outlined has-margin-t-7 ${classes.actionButton}`}
          onClick={() => onShowInterest(offer?.id)}
        >
          <FormattedMessageWithClass id='carer.directoffers.actions.interested.button' />
        </button>
        <button
          className={`button-text has-margin-t-7 ${classes.actionButton}`}
          onClick={() => onRejectDirectOffer(offer?.id)}
        >
          <FormattedMessageWithClass
            className='p2-bold danger'
            id='carer.directoffers.actions.reject.button'
          />
        </button>
      </>
    )
  case 'accepted':
    return <AcceptedOfferBody fromInterest={!!hasInterestReasons} />

  case 'interested':
    return (
      <InterestedOfferBody
        item={offer}
        loading={false}
        onViewReasons={onViewInterestedReasons}
      />
    )

  case 'rejected':
    return <RejectedOfferBody />
  default:
    return null
  }
}


export default DirectOfferStatus
