import * as Yup from 'yup'


export const ExperienceSchema = Yup.object().shape({
  confirmation: Yup.bool().oneOf([true], 'validations.required').required('validations.required'),
  from_date: Yup.date()
    .typeError('validations.required')
    .required('validations.required'),
  reference_email: Yup.string()
    .email('validations.invalidemail')
    .nullable(),
  reference_name: Yup.string()
    .required('validations.required')
    .nullable(),
  reference_phone: Yup.string().min(4, 'validations.phonetooshort').required('validations.required'),
  service_categories: Yup.array()
    .min(1, 'validations.selectoneservice')
    .required('validations.required'),
  to_date: Yup.date().typeError('validations.required')
    .min(Yup.ref('from_date'),'validations.endBeforeStartHour')
    .required('validations.required')
})
