import { combineReducers } from 'redux'
import authReducer from 'seniors-first-commons/shared/store/auth/reducer'
import offersReducer from './offers/reducer'
import layoutsReducer from './layouts/reducer'
import profileReducer from './profile/reducer'
import helpersReducer from 'seniors-first-commons/shared/store/helpers/reducer'
import intl from 'seniors-first-commons/shared/store/translations/reducer'
import userReducer from 'seniors-first-commons/shared/store/user/reducer'
import notificationsReducer from 'seniors-first-commons/shared/store/notifications/reducer'
import uiReducer from 'seniors-first-commons/shared/store/ui/reducer'

const caregiverReducer = combineReducers({
  auth: authReducer,
  helpers: helpersReducer,
  intl,
  layouts: layoutsReducer,
  notifications: notificationsReducer,
  offers: offersReducer,
  profile: profileReducer,
  ui: uiReducer,
  user: userReducer
})

const rootReducer = (state, action) => {
  if (action.type === 'shared/auth/blackList/fulfilled') {
    return caregiverReducer({ intl: state.intl }, action)
  }
  return caregiverReducer(state, action)
}

export default rootReducer
