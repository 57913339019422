import React from 'react'
import classes from './index.module.css'


const AdvancedCheckbox = ({
  field,
  label,
  ...props
}) => {

  return (
    <>
      <label
        className={`
          p2-bold has-text-centered
          ${classes.checkbox} 
          ${props.checked ? classes.active : '' }
        `}
        htmlFor={props.id}
      >
        {label}

        <label className='radio'>
          <input
            type='checkbox'
            {...field}
            {...props}
          />
        </label>
      </label>
    </>
  )
}

export default AdvancedCheckbox
