import React from 'react'
import { path } from 'ramda'
import { useHistory } from 'react-router'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { FormattedMessageWithClass, useFormatMessage } from 'seniors-first-commons/shared/store/translations/effects'
import WorkingLocationInitValues from 'models/workingLocationModal'
import workingLocationSchema from 'schemas/workingLocationModal'
import FieldLabel from 'seniors-first-commons/components/atoms/FieldLabel'
import ErrorHandler from 'seniors-first-commons/components/atoms/ErrorHandler'
import { AddressInput, RadioInput } from './inputs'
import MapContainer from 'seniors-first-commons/components/organisms/Map'
import CustomModal from 'seniors-first-commons/components/atoms/CustomModal'
import { InfoIcon } from 'seniors-first-commons/components/atoms/images/icons'
import classes from './index.module.css'


const WorkingLocationModal = ({
  open,
  profile,
  matchesMobile,
  userId,
  loading,
  onClose,
  getCarer,
  updateWorkingLocation,
  userMustCorrectAddress
}) => {
  const history = useHistory()
  const selectedCountry = path(['profile', 'country'], profile)

  const translations =  {
    locationHint: useFormatMessage('carer.home.workingLocationCard.hint')
  }

  const mapContainerStyles = {
    height: '250px',
    marginTop: matchesMobile ? '10px' : '0px',
    position: 'relative',
    width: '100%'
  }

  const mapStyles = {
    borderRadius: '15px'
  }

  const handleSubmit = (values, { setStatus, setSubmitting }) => {
    updateWorkingLocation({
      lat: values.address.address_lat,
      lng: values.address.address_lng,
      radio_km: values.radio_km,
      working_location: values.address.formatted_address
    })
      .unwrap()
      .then(() => {
        onClose()
        setStatus({ success: true })
        setSubmitting(false)
        getCarer({ carer_id: userId })
      })
      .then(() => history.push({ search: '' }))
  }

  return (
    <CustomModal
      cardBodyStyles={{ overflow: 'auto' }}
      hasCloseButton={!userMustCorrectAddress}
      onClose={onClose}
      open={open}
      shouldCloseOnOverlayClick={!userMustCorrectAddress}
      styles={{ maxWidth: '720px', overflow: 'auto', width: '95%' }}
    >
      <Formik
        enableReinitialize
        initialValues={new WorkingLocationInitValues(profile)}
        onSubmit={handleSubmit}
        validationSchema={workingLocationSchema}
      >
        {({ values, dirty }) => {
          return (
            <Form>
              <h3 className='t2-bold primary has-text-centered'>
                <FormattedMessageWithClass id='carer.home.workingLocationCard.title' />
              </h3>

              <p className='p2-regular grey has-text-centered has-margin-t-7 has-margin-b-3'>
                <FormattedMessageWithClass id={userMustCorrectAddress ? 'carer.home.workingLocationCard.genericLocation' : 'app.boarding.availability.form.distance'} />
              </p>

              <div className='columns is-5 has-margin-t-4'>
                <div className='column'>
                  <>
                    <FieldLabel
                      bottomSpace={0}
                      centered={false}
                      labelName='carer.home.workingLocationCard.address'
                      labelType='p1-bold'
                      topSpace={5}
                    />
                    <Field
                      className={classes.addressInput}
                      component={AddressInput}
                      country={selectedCountry}
                      id='address'
                      name='address'
                      placeholder={translations.locationHint}
                    />
                    <ErrorMessage component={ErrorHandler} name='address' />
                  </>

                  <>
                    <FieldLabel
                      bottomSpace={6}
                      centered={false}
                      labelName='carer.home.workingLocationCard.radio'
                      labelType='p1-bold'
                      topSpace={5}
                    />
                    <Field
                      component={RadioInput}
                      id='radio_km'
                      name='radio_km'
                    />
                  </>
                  <div className={`has-margin-t-2 ${classes.infoText}` }>
                    <InfoIcon
                      fill='none'
                      height='24'
                      stroke='#0040AA'
                      width='24'
                    />
                    <FormattedMessageWithClass
                      className='p3-regular primary has-margin-l-7 has-text-left'
                      id='carer.home.workingLocationCard.text2'
                    />
                  </div>
                </div>

                <div className='column'>
                  <MapContainer
                    hasMarker={values.address.address_lat ? true : false}
                    location={{
                      lat: Number(values.address.address_lat || '48.5000'),
                      lng: Number(values.address.address_lng || '23.3833')
                    }}
                    options={{
                      containerStyle: mapContainerStyles,
                      style: mapStyles
                    }}
                    radius={values.radio_km * 1000}
                    zoom={6}
                  />
                </div>
              </div>

              <div className='has-text-centered has-margin-t-4'>
                <button
                  className={`button filled ${loading ? 'is-loading' : '' } ${classes.confirmButton}`}
                  disabled={loading || !dirty}
                  type='submit'
                >
                  <FormattedMessageWithClass id='carer.home.workingLocationCard.button' />
                </button>
              </div>
            </Form>
          )
        }}
      </Formik>
    </CustomModal>
  )
}

export default WorkingLocationModal
