import React from 'react'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import Services from 'seniors-first-commons/components/molecules/OfferCardParts/Services'
import Modality from 'seniors-first-commons/components/molecules/OfferCardParts/Modality'
import Address from 'seniors-first-commons/components/molecules/OfferCardParts/Address'
import Duration from 'seniors-first-commons/components/molecules/OfferCardParts/Duration'
import Contract from 'seniors-first-commons/components/molecules/OfferCardParts/Contract'
import Retribution from 'seniors-first-commons/components/molecules/OfferCardParts/Retribution'
import classes from './index.module.css'


const DataLevel = ({ isMarginless=false, children, ...props }) => {
  return (
    <div className={!isMarginless ? 'has-margin-b-6' : '' } {...props}>
      {children}
    </div>
  )
}

const JobOfferCard = ({
  matchesMobile,
  data,
  onOpenDaysAndShiftsModal,
  ...props
}) => {
  return (
    <div className='card is-borderless has-margin-bt-7'>
      <div className='card-content has-padding-bt-3'>
        {props.header &&
          <div className={`primary-light-background ${classes.header}`}>
            <span className='p2-bold white'>
              {props.header}
            </span>
          </div>
        }

        {props.descriptionText &&
          <DataLevel>
            <p className='p1-regular has-margin-t-5'>
              <FormattedMessageWithClass id={props.descriptionText} />
            </p>
          </DataLevel>
        }

        <div className='columns is-vcentered'>
          <div className='column is-two-thirds'>
            <DataLevel isMarginless={matchesMobile} style={{ height: matchesMobile ? '65px' : 'auto' }}>
              <Services services={data ? data.service_categories : []} />
            </DataLevel>

            <DataLevel>
              <Modality
                data={data}
                matchesMobile={matchesMobile}
                onOpenDaysAndShiftsModal={onOpenDaysAndShiftsModal}
              />
            </DataLevel>

            <DataLevel>
              <Address data={data} />
            </DataLevel>

            <DataLevel>
              <Contract data={data} />
            </DataLevel>

            <DataLevel>
              <Duration data={data}/>
            </DataLevel>

            <DataLevel isMarginless>
              <Retribution currency={data?.currency} data={data} />
            </DataLevel>
          </div>

          <div className='column is-one-third'>
            {props.children}
          </div>
        </div>
      </div>
    </div>
  )
}


export default JobOfferCard
