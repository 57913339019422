import React from 'react'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import { ArrowRightIcon } from 'seniors-first-commons/components/atoms/images/icons'
import classes from './index.module.css'


const HomeCard = ({
  title,
  onOpen,
  children
}) => {
  return (
    <div className={`card bordered-card has-margin-t-4 ${classes.card}`}>
      <div className={`full-width has-padding-l-5 has-padding-r-7 has-padding-t-7 ${classes.listItem}`}>
        <FormattedMessageWithClass className='l-bold primary' id={title} />

        <button
          className='icon-button'
          onClick={onOpen}
        >
          <ArrowRightIcon
            height='24px'
            width='24px'
          />
        </button>
      </div>

      {children}
    </div>
  )
}

export default HomeCard
