import React from 'react'
import RoundedTabs from 'seniors-first-commons/components/atoms/RoundedTabs'


const DirectOffersFilterTabs = ({
  matchesMobile,
  selectedTab,
  onTabChange
}) => {
  const items = [
    {
      label: 'carer.directoffers.filterTabs.pending',
      value: 'pending'
    },
    {
      label: 'carer.directoffers.filterTabs.accepted',
      value: 'accepted'
    },
    {
      label: 'carer.directoffers.filterTabs.interested',
      value: 'interested'
    },
    {
      label: 'carer.directoffers.filterTabs.rejected',
      value: 'rejected'
    }
  ]

  return (
    <RoundedTabs
      items={items}
      matchesMobile={matchesMobile}
      onChangeTab={onTabChange}
      selected={selectedTab}
    />
  )
}


export default DirectOffersFilterTabs
