import React, { useState } from 'react'
import { connect } from 'react-redux'
import { pathOr } from 'ramda'
import {
  applyToJobPost,
  respondToOffer,
  respondToOfferFromDetails,
  applyToJobPostFromDetails
} from 'store/offers/actions'
import { getInterestedReasons } from 'seniors-first-commons/shared/store/helpers/actions'
import ShiftsModal from 'seniors-first-commons/components/organisms/ShiftsModal'
import ConfirmModal from 'seniors-first-commons/components/atoms/ConfirmModal'
import InterestedReasonsModal from 'seniors-first-commons/components/atoms/InterestedReasonsModal'
import InterestedReasonsForm from 'components/organisms/InterestedReasonsForm'
import { SuccessIcon, CloseCircle } from 'seniors-first-commons/components/atoms/images/icons'
import DaysAndShiftsTable from 'seniors-first-commons/components/molecules/DaysAndShiftsTable'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'

const mapStateToProps = (state) => ({
  interestedReasons: state.helpers.interestedReasons,
  loadingResponse: state.offers.offerApplyLoading || state.offers.responseLoading,
  profile: state.profile.profile,
  user: state.user.user.data
})

const mapDispatchToProps = {
  applyToJobPost,
  applyToJobPostFromDetails,
  getInterestedReasons,
  respondToOffer,
  respondToOfferFromDetails
}

const withJobOffer = (WrappedComponent) => {
  const InnerComponent = ({
    comingFrom='',
    profile,
    user,
    interestedReasons,
    loadingResponse,
    actions,
    applyToJobPost,
    respondToOffer,
    getInterestedReasons,
    respondToOfferFromDetails,
    applyToJobPostFromDetails,
    ...props
  }) => {
    const [selectedOffer, setSelectedOffer] = useState(null)
    const [offerToShowItsDaysAndTimes, setOfferToShowItsDaysAndTimes] = useState(null)
    const [confirmType, setConfirmType] = useState('')
    const [showInterestedReasonsForm, setShowInterestedReasonsForm] = useState(false)
    const [showInterestedReasonsModal, setShowInterestedReasonsModal] = useState(false)
    const [showCheckWorkPermitModal, setShowCheckWorkPermitModal] = useState(false)

    const hasWorkPermit = pathOr(true, ['data', 'hiring_condition', 'has_work_permit'], profile)

    const onOpenDaysAndShiftsModal = (item) => {
      setOfferToShowItsDaysAndTimes(item)
    }

    const onApplyToPost = (id) => {
      if (hasWorkPermit) {
        setSelectedOffer(id)
        setConfirmType('apply')
      }
      else {
        setShowCheckWorkPermitModal(true)
      }
    }

    const onAcceptDirectOffer = (id) => {
      setSelectedOffer(id)
      setConfirmType('accept')
    }

    const onRejectDirectOffer = (id) => {
      setSelectedOffer(id)
      setConfirmType('reject')
    }

    const onShowInterest = (id) => {
      setSelectedOffer(id)
      setShowInterestedReasonsForm(true)
    }

    const onViewInterestedReasons = (id) => {
      setSelectedOffer(id)
      getInterestedReasons({
        caregiver_id: user?.id,
        job_offer_id: id
      })
        .unwrap()
        .then(() => {
          setShowInterestedReasonsModal(true)
          setSelectedOffer(null)
        })
    }

    const onCloseConfirmModal = () => {
      setConfirmType('')
    }

    const onCloseWorkPermitModal = () => {
      setShowCheckWorkPermitModal(false)
    }

    const onCloseInterestedReasonsModal = () => {
      setShowInterestedReasonsModal(false)
    }

    const onCloseInterestedReasonsForm = () => {
      setShowInterestedReasonsForm(false)
    }

    const onSubmitInterestedReasons = (values, { resetForm }) => {
      if (comingFrom === 'details') {
        respondToOfferFromDetails({
          reasons: values.reasons.join(','),
          service_sheet_id: selectedOffer,
          status: 'interested'
        })
          .unwrap()
          .then(() => {
            setShowInterestedReasonsForm(false)
            setSelectedOffer(null)
          })
      }
      else {
        respondToOffer({
          reasons: values.reasons.join(','),
          service_sheet_id: selectedOffer,
          status: 'interested'
        })
          .unwrap()
          .then(() => {
            resetForm()
            setShowInterestedReasonsForm(false)
            setSelectedOffer(null)
          })
      }
    }

    const onConfirm = () => {
      switch(confirmType) {
      case 'apply':
        if (comingFrom === 'details') {
          applyToJobPostFromDetails({ sheet_id: selectedOffer })
            .unwrap()
            .then(() => {
              setConfirmType('')
              setSelectedOffer(null)
            })
        }
        else {
          applyToJobPost({ sheet_id: selectedOffer })
            .unwrap()
            .then(() => {
              setConfirmType('')
              setSelectedOffer(null)
            })
        }
        break
      case 'accept':
        if (comingFrom === 'details') {
          respondToOfferFromDetails({
            service_sheet_id: selectedOffer,
            status: 'accepted'
          })
            .unwrap()
            .then(() => {
              setConfirmType('')
              setSelectedOffer(null)
            })
        }
        else {
          respondToOffer({
            service_sheet_id: selectedOffer,
            status: 'accepted'
          })
            .unwrap()
            .then(() => {
              setConfirmType('')
              setSelectedOffer(null)
            })
        }
        break
      default:
        if (comingFrom === 'details') {
          respondToOfferFromDetails({
            service_sheet_id: selectedOffer,
            status: 'rejected'
          })
            .unwrap()
            .then(() => {
              setConfirmType('')
              setSelectedOffer(null)
            })
        }
        else {
          respondToOffer({
            service_sheet_id: selectedOffer,
            status: 'rejected'
          })
            .unwrap()
            .then(() => {
              setConfirmType('')
              setSelectedOffer(null)
            })
        }
      }
    }

    return (
      <>
        <WrappedComponent
          {...props}
          actions={{
            ...actions,
            onAcceptDirectOffer,
            onApplyToPost,
            onOpenDaysAndShiftsModal,
            onRejectDirectOffer,
            onShowInterest,
            onViewInterestedReasons
          }}
          loadingResponse={loadingResponse}
          selectedOffer={selectedOffer}
        />
        <ShiftsModal
          active={!!offerToShowItsDaysAndTimes}
          offer={offerToShowItsDaysAndTimes}
          onClose={() => setOfferToShowItsDaysAndTimes(null)}
        />
        <ConfirmModal
          details={confirmType !== 'apply' && confirmType !== 'accept' &&
          'carer.directoffers.rejectModal.text'}
          Icon={confirmType === 'apply' || confirmType === 'accept' ? SuccessIcon : CloseCircle}
          loading={loadingResponse}
          onCancel={onCloseConfirmModal}
          onClose={onCloseConfirmModal}
          onConfirm={onConfirm}
          show={!!confirmType}
          title={confirmType === 'apply' ? 'carer.home.post.applyBefore.modal.title'
            : confirmType === 'accept' ? 'carer.directoffers.acceptModal.title'
              : 'carer.directoffers.rejectModal.title'
          }
        >
          {confirmType === 'apply' && (
            <div className='has-margin-t-6'>
              <FormattedMessageWithClass
                className='p2-bold'
                id='carer.home.post.shiftsTable.title'
              />
              <DaysAndShiftsTable
                className='has-margin-t-7 has-text-left'
                offer={props?.jobPosts?.items?.find(post => post.id === selectedOffer)}
              />
            </div>
          )}
        </ConfirmModal>
        <ConfirmModal
          confirm='carer.home.post.workPermit.modal.confirm'
          details='carer.home.post.workPermit.modal.text'
          hascancelButton={false}
          Icon={CloseCircle}
          onClose={onCloseWorkPermitModal}
          onConfirm={onCloseWorkPermitModal}
          show={showCheckWorkPermitModal}
          title='carer.home.post.workPermit.modal.title'
        />
        <InterestedReasonsForm
          loading={loadingResponse}
          onClose={onCloseInterestedReasonsForm}
          onSubmit={onSubmitInterestedReasons}
          open={showInterestedReasonsForm}
        />
        <InterestedReasonsModal
          onClose={onCloseInterestedReasonsModal}
          open={showInterestedReasonsModal}
          reasons={interestedReasons.items || []}
          title='carer.directoffers.interestedModal.title'
        />
      </>
    )
  }

  const EnhancedComponent = connect(mapStateToProps, mapDispatchToProps)(InnerComponent)
  return EnhancedComponent
}

export default withJobOffer
