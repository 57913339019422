import React from 'react'


const Switch = ({
  field,
  columnClass = '',
  className = '',
  ...props
}) => {
  return (
    <div key={`switch-${field.name}`}>
      <div className='columns'>
        <div className={`column ${columnClass}`}>
          <input
            checked={field.value ? 'checked' : ''}
            className='switch'
            id={field.name}
            key={`input-${field.name}`}
            type='checkbox'
            {...field}
            {...props}
          />
          <label
            className={`
              button-shadow has-text-centered has-margin-b-6 is-fullwidth 
              ${className}
            `}
            htmlFor={field.name}
          ></label>
        </div>
      </div>
    </div>
  )
}

export default Switch
