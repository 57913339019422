import { createReducer } from '@reduxjs/toolkit'

import {
  addDirtyForm,
  clearDirtyForms,
  removeDirtyForm
} from './actions'

const initialState = {
  dirtyForms: []
}

const modalReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(addDirtyForm, (state, action) => {
      const formName = action.payload
      const formIsAlreadyDirty = state.dirtyForms.includes(formName)
      if (!formIsAlreadyDirty) {
        state.dirtyForms = [...state.dirtyForms, formName]
      }
    })
    .addCase(clearDirtyForms, (state) => {
      state.dirtyForms = []
    })
    .addCase(removeDirtyForm, (state, action) => {
      const formName = action.payload
      const formIsAlreadyDirty = state.dirtyForms.includes(formName)
      if (formIsAlreadyDirty) {
        state.dirtyForms = state.dirtyForms.filter(form => form !== formName)
      }
    })
})

export default modalReducer
