import React from 'react'
import withJobOffer from 'hoc/withJobOffer'
import Header from 'components/atoms/PendingDirectOffersCard'
import PostedOffer from 'components/organisms/PostedOffer'
import { PostHeader, PostFooter } from 'components/molecules/PostStatus'
import Pagination from 'seniors-first-commons/components/molecules/Pagination'
import NoJobPosts from '../../components/molecules/NoJobPosts'
import classes from './index.module.css'

const SecondColumn = ({
  matchesDesktop,
  matchesTablet,
  matchesMobile,
  pendingOffers,
  jobPosts,
  handlePageChange,
  actions
}) => {
  return (
    <div className='has-navbar-fixed-bottom'>
      <div className={`has-margin-b-6 ${matchesMobile ? `${classes.headerWrapper}` : ''}`}>
        <Header
          matchesMobile={matchesMobile}
          matchesTablet={matchesTablet}
          pendingOffers={pendingOffers}
        />
      </div>

      <div className='columns is-multiline is-relative'>
        {!jobPosts.loading && jobPosts.currentPage ?
          jobPosts.total ? jobPosts.items.map((post, index) => (
            <div className='column is-half-desktop is-full' key={index}>
              <PostedOffer
                actions={actions}
                additionalContentClass='is-paddingless-b'
                data={post}
                footer={(
                  <PostFooter
                    id={post.id}
                    onApply={actions.onApplyToPost}
                    posted_until={post.posted_until}
                    status={post.post_applicant_status}
                  />
                )}
                header={(
                  <PostHeader
                    matchesDesktop={matchesDesktop}
                    matchesMobile={matchesMobile}
                    posted_until={post.posted_until}
                    status={post.post_applicant_status}
                  />
                )}
              />
            </div>
          )) : (
            <div className='column is-full'>
              <NoJobPosts />
            </div>
          )
          : null}
        {jobPosts.total ?
          <Pagination
            additionalClasses={classes.pagination}
            currentPage={jobPosts.currentPage}
            data={jobPosts.total}
            itemsPerPage={8}
            onPageChange={handlePageChange}
          /> : ''
        }
      </div>
    </div>
  )
}

export default withJobOffer(SecondColumn)
