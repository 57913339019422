import { pathOr } from 'ramda'

export default class ProfileSettings {
  constructor(profile) {
    this.username = pathOr('' , ['username'], profile)
    this.email = pathOr('' , ['email'], profile)
    this.firstname = pathOr('' , ['firstname'], profile)
    this.lastname = pathOr('' , ['lastname'], profile)
    this.gender = pathOr('' , ['profile' , 'gender'], profile)
    this.birthday = pathOr(null, ['profile' , 'birthday'], profile)
    this.receiveNotifications = pathOr(false, ['receive_notifications'], profile)
    this.receiveEmailNotifications = pathOr(true, ['receive_email_notifications'], profile)
  }
}
