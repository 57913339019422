import * as Yup from 'yup'

const WorkingLocationSchema = Yup.object().shape({
  address: Yup.object().shape({
    formatted_address: Yup.string().required('validations.required').test('testing address exists', 'validations.clients.createOffer.address', (value) => {
      let validAddress = true
      if (value && (value.match(/,/g) || []).length < 2) {
        validAddress = false
      }
      return validAddress
    })
  }),
  radio_km: Yup.number()
})

export default WorkingLocationSchema
