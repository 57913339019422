import React, { useEffect, useState } from 'react'
import { Field } from 'formik'
import { path } from 'ramda'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import ServicesInitValues from 'models/services'
import { OfferedServicesSchema, ModalitySchema, AvailabilitySchema } from 'schemas/services'
import Wizard from 'components/organisms/ProfileWizard'
import ProfileBoardingFooter from '../ProfileBoardingFooter'
import {
  OfferedServices,
  AvaialableDays,
  AvaialableShifts,
  AvailabilityDaysShifts,
  DailyHoursSlider,
  WorkingLocation,
  RadioSlider
} from './input'
import AdvancedCheckbox from 'seniors-first-commons/components/atoms/AdvancedCheckbox'
import MapContainer from 'seniors-first-commons/components/organisms/Map'
import { InternalModal, ExternalModal } from 'seniors-first-commons/components/molecules/HowToModals'
import { IconIn, IconOut } from 'seniors-first-commons/components/atoms/images'
import classes from './index.module.css'


const Modality = ({
  external_mode,
  internal_mode,
  onOpenExternalModal,
  onOpenInternalModal,
  ...props
}) => {
  return (
    <div className='has-text-centered'>
      <div className='columns is-marginless-b'>
        <div className='column has-text-centered'>
          <Field
            checked={external_mode}
            component={AdvancedCheckbox}
            label={(
              <div className={classes.iconContainer}>
                <span className='icon is-medium'>
                  <img alt='icon_external' src={IconIn} />
                </span>
                <FormattedMessageWithClass id='app.boarding.offeredservices.externalmodal.title' />
              </div>
            )}
            name='external_mode'
          />
          <button
            className='button-text'
            onClick={onOpenExternalModal}
            type='button'
          >
            <FormattedMessageWithClass
              className='p3-bold'
              id='app.boarding.offeredservices.howform.link'
            />
          </button>
        </div>

        <div className='column has-text-centered'>
          <Field
            checked={internal_mode}
            component={AdvancedCheckbox}
            label={(
              <div className={classes.iconContainer}>
                <span className='icon is-medium'>
                  <img alt='icon_internal' src={IconOut} />
                </span>
                <FormattedMessageWithClass id='app.boarding.offeredservices.internalmodal.title' />
              </div>
            )}
            name='internal_mode'
          />
          <button
            className='button-text'
            onClick={onOpenInternalModal}
            type='button'
          >
            <FormattedMessageWithClass
              className='p3-bold'
              id='app.boarding.offeredservices.howform.link'
            />
          </button>
        </div>
      </div>

      {props.errors.external_mode || props.errors.internal_mode
        ? (
          <FormattedMessageWithClass
            className='p3-regular danger'
            id='validations.selectone'
          />
        )
        : null
      }
    </div>
  )
}

const Footer = ({ onGoBack, ...props }) => {
  return (
    <ProfileBoardingFooter
      onBack={onGoBack}
      step={2}
      {...props}
    />
  )
}

const WizardStep = ({ children, ...props }) => children(props)

const ServicesForm = ({
  matchesMobile,
  services,
  profile,
  isSubmitting,
  getServices,
  ...props
}) => {
  const [showMoreDetails, setShowMoreDetails] = useState({
    external_mode: false,
    internal_mode: false
  })

  useEffect(() => {
    if (!services) getServices()
    // eslint-disable-next-line
  }, [getServices])


  const onToggleExternalModal = () => {
    setShowMoreDetails({ ...showMoreDetails, external_mode: !showMoreDetails.external_mode })
  }

  const onToggleInternalModal = () => {
    setShowMoreDetails({ ...showMoreDetails, internal_mode: !showMoreDetails.internal_mode })
  }

  const onSubmit = (values) => {
    // eslint-disable-next-line no-unused-vars
    const { availableDays, availableShifts, ...restServices } = values
    props.updateServices({ ...restServices })
      .unwrap()
      .then(() => props.moveToNextStep())
  }

  return (
    <>
      <Wizard
        enableReinitialize={true}
        Footer={Footer}
        initialValues={new ServicesInitValues(profile)}
        loading={isSubmitting}
        onSubmit={onSubmit}
        {...props}
      >
        <WizardStep tab={0} validationSchema={OfferedServicesSchema}>
          {({ parentState: { values } }) => {
            return (
              <>
                <p className='t1-bold primary has-text-centered has-padding-b-3'>
                  <FormattedMessageWithClass id='app.boarding.routes.yourservices' />
                </p>

                <p className='p2-bold has-text-centered grey-light has-padding-b-7'>
                  <FormattedMessageWithClass id='app.boarding.offeredservices.form.title' />
                </p>

                <OfferedServices
                  all_services={services || {1: [], 2: [], 3: [], 4: []}}
                  services={values.services}
                />
              </>
            )
          }}
        </WizardStep>

        <WizardStep tab={1} validationSchema={ModalitySchema}>
          {({ parentState: { values, ...formik } }) => {
            return (
              <>
                <p className='has-padding-b-3 has-text-centered primary t1-bold'>
                  <FormattedMessageWithClass id='app.boarding.routes.wichoption' />
                </p>

                <Modality
                  {...formik}
                  external_mode={values.external_mode}
                  internal_mode={values.internal_mode}
                  onOpenExternalModal={onToggleExternalModal}
                  onOpenInternalModal={onToggleInternalModal}
                />

                <ExternalModal
                  isOpen={showMoreDetails.external_mode}
                  onClose={onToggleExternalModal}
                />

                <InternalModal
                  isOpen={showMoreDetails.internal_mode}
                  onClose={onToggleInternalModal}
                />
              </>
            )
          }}
        </WizardStep>

        <WizardStep tab={2} validationSchema={AvailabilitySchema}>
          {({ parentState: { values, ...formik } }) => {
            return (
              <>
                <p className='primary has-text-centered t1-bold has-padding-b-3'>
                  <FormattedMessageWithClass id='app.boarding.routes.availability' />
                </p>

                <AvaialableDays availableDays={values.availableDays} />

                <AvaialableShifts
                  availableShifts={values.availableShifts}
                  {...formik}
                />

                <AvailabilityDaysShifts
                  availableDays={values.availableDays}
                  availableShifts={values.availableShifts}
                  day_times={values.day_times}
                  matchesMobile={matchesMobile}
                  {...formik}
                />

                <DailyHoursSlider hiring_min_hours={values.hiring_min_hours} />

                <WorkingLocation
                  country_of_residence={path(['profile', 'country', 'code'], profile)}
                />

                <RadioSlider />

                <MapContainer
                  hasMarker={values.working_location.address_lat ? true : false}
                  location={{
                    lat: values.working_location.address_lat || 48.5000,
                    lng: values.working_location.address_lng || 23.3833
                  }}
                  options={{
                    containerStyle: {
                      height: '300px',
                      marginTop: '4rem',
                      position: 'relative',
                      width: '100%'
                    },
                    style: { borderRadius: '15px' }
                  }}
                  radius={values.radio_km * 1000}
                  zoom={8}
                />
              </>
            )
          }}
        </WizardStep>
      </Wizard>
    </>
  )
}

export default ServicesForm
