import React from 'react'
import { path } from 'ramda'
import moment from 'moment'
import withJobOffer from 'hoc/withJobOffer'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import JobOfferCard from '../JobOfferCard'
import { PostedOfferDetails } from 'components/molecules/PostStatus'
import DirectOfferStatus from 'screens/OfferDetails/DirectOfferStatus'
import { getDirectOfferDescriptionText, getPostParts } from './offerExtras'


const OfferDetailsHeader = () => {
  return (
    <h1 className='t1-bold primary'>
      <FormattedMessageWithClass id='carer.offerDetails.title' />
    </h1>
  )
}

const OfferDetailsBody = ({
  offerType,
  offerDetails,
  responseLoading,
  actions
}) => {
  const postStatus = path(['index', 'post_application', 'status'], offerDetails)
  const postedUntil = path(['index', 'posted_until'], offerDetails)
  const directOfferStatus = path(['index', 'job_proposition', 'status'], offerDetails)
  const directOfferDescription = getDirectOfferDescriptionText(directOfferStatus)
  const postParts = getPostParts(postStatus, postedUntil)
  const header = postParts.header
  const description = postParts.descriptionText

  return (
    <>
      <OfferDetailsHeader />

      {offerDetails.loading || !offerDetails.index ? null :
        <>
          {offerType === 'post' && offerDetails.index.posted_until &&
            <JobOfferCard
              data={offerDetails.index}
              descriptionText={description}
              header={header}
              onOpenDaysAndShiftsModal={actions.onOpenDaysAndShiftsModal}
            >
              <PostedOfferDetails
                id={offerDetails.index.id}
                posted_until={moment(postedUntil)}
                responseLoading={responseLoading}
                status={postStatus}
                {...actions}
              />
            </JobOfferCard>
          }

          {offerType === 'findsend' && offerDetails.index.job_proposition.status &&
            <JobOfferCard
              data={offerDetails.index}
              descriptionText={directOfferDescription}
              header={<FormattedMessageWithClass id='carer.offerDetails.directOffer.title' />}
              onOpenDaysAndShiftsModal={actions.onOpenDaysAndShiftsModal}
            >
              <DirectOfferStatus
                offer={offerDetails.index}
                status={offerDetails.index.job_proposition.status}
                {...actions}
              />
            </JobOfferCard>
          }
        </>
      }
    </>
  )
}

export default withJobOffer(OfferDetailsBody)
