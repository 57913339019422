import React, { useState } from 'react'
import { useFormatMessage } from 'seniors-first-commons/shared/store/translations/effects'
import { ServiceBox } from 'seniors-first-commons/components/organisms/Services/ServiceBox'
import CaregiverServicesModals from './modals'
import {
  CuidadoIcon,
  CompaniaIcon,
  EnfermeriaIcon,
  HogarIcon
} from 'seniors-first-commons/components/atoms/images/'
import classes from './index.module.css'


const CaregiverServices = ({
  field,
  form,
  twoColumns=true,
  services,
  all_services,
  additionalClasses=''
}) => {
  const [activeModal, setActiveModal] = useState('')

  const BoxMap = {
    compania: {
      icon: CompaniaIcon,
      text: useFormatMessage('app.boarding.offeredservices.form.2')
    },
    cuidado: {
      icon: CuidadoIcon,
      text: useFormatMessage('app.boarding.offeredservices.form.3')
    },
    enfermeria: {
      icon: EnfermeriaIcon,
      text: useFormatMessage('app.boarding.offeredservices.form.4')
    },
    hogar: {
      icon: HogarIcon,
      text: useFormatMessage('app.boarding.offeredservices.form.1')
    }
  }

  const onModalClick = (modal) => setActiveModal(modal)

  const onClose = () => {
    setActiveModal('')
  }

  const onSelect = (e) => {
    if (
      !field.value[e.service_category_id]
        .map(item => item.id)
        .includes(e.id)
    ) {
      form.setFieldValue(field.name, {
        ...field.value, [e.service_category_id]: [...field.value[e.service_category_id], e]
      })
    }
  }

  const onSelectAll = (e, service_category_id) => {
    if (e.length === field.value[service_category_id].length) {
      form.setFieldValue(field.name, {
        ...field.value,
        [service_category_id]: []
      })
    }
    else {
      form.setFieldValue(field.name, {
        ...field.value,
        [service_category_id]: e
      })
    }
  }

  const onRemove = (id, service_category_id) => {
    form.setFieldValue(field.name, {
      ...field.value,
      [service_category_id]: field.value[service_category_id].filter(item => item.id !== id)
    })
  }

  return (
    <>
      <div className='columns'>
        <div className='column'>
          <ServiceBox
            additionalClasses={`${classes.item} ${additionalClasses}`}
            id='home'
            image={BoxMap['hogar']['icon']}
            isCompleted={services['1'].length}
            key='sb-home'
            onClick={onModalClick}
            title={BoxMap['hogar']['text']}
          />

          {twoColumns &&
            <ServiceBox
              additionalClasses={`${classes.item} ${additionalClasses}`}
              id='company'
              image={BoxMap['compania']['icon']}
              isCompleted={services['2'].length}
              key='sb-company'
              onClick={onModalClick}
              title={BoxMap['compania']['text']}
            />
          }
        </div>

        {!twoColumns &&
          <div className='column'>
            <ServiceBox
              additionalClasses={`${classes.item} ${additionalClasses}`}
              id='company'
              image={BoxMap['compania']['icon']}
              isCompleted={services['2'].length}
              key='sb-company'
              onClick={onModalClick}
              title={BoxMap['compania']['text']}
            />
          </div>
        }

        {!twoColumns &&
          <div className='column'>
            <ServiceBox
              additionalClasses={`${classes.item} ${additionalClasses}`}
              id='personal'
              image={BoxMap['cuidado']['icon']}
              isCompleted={services['3'].length}
              key='sb-personal'
              onClick={onModalClick}
              title={BoxMap['cuidado']['text']}
            />
          </div>
        }

        <div className='column'>
          {twoColumns &&
            <ServiceBox
              additionalClasses={`${classes.item} ${additionalClasses}`}
              id='personal'
              image={BoxMap['cuidado']['icon']}
              isCompleted={services['3'].length}
              key='sb-personal'
              onClick={onModalClick}
              title={BoxMap['cuidado']['text']}
            />
          }

          <ServiceBox
            additionalClasses={`${classes.item} ${additionalClasses}`}
            id='enfermeria'
            image={BoxMap['enfermeria']['icon']}
            isCompleted={services['4'].length}
            key='sb-enfermeria'
            onClick={onModalClick}
            title={BoxMap['enfermeria']['text']}
          />
        </div>
      </div>

      <CaregiverServicesModals
        activeModal={activeModal}
        all_services={all_services}
        onClose={onClose}
        onRemove={onRemove}
        onSelect={onSelect}
        onSelectAll={onSelectAll}
        services={services}
      />
    </>
  )
}

export default CaregiverServices
