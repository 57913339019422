import React from 'react'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import { VideoIcon } from 'seniors-first-commons/components/atoms/images/icons'


const ExampleVideoButton = ({ onOpenExampleVideo }) => {
  return (
    <div className='has-margin-b-6'>
      <button
        className='button-text'
        onClick={onOpenExampleVideo}
        style={{ alignItems: 'center', display: 'flex' }}
        type='button'
      >
        <VideoIcon
          className='button-text-icon'
          fill='none'
          height='16'
          width='16'
        />
        <FormattedMessageWithClass
          className='p2-bold has-margin-l-7'
          id='app.boarding.videocv.example.text'
        />
      </button>
    </div>
  )
}


export default ExampleVideoButton
