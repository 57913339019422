import { pathOr, path } from 'ramda'
import rawCountries from 'seniors-first-commons/utils/rawCountries'

export default class Experience {
  constructor(experience, profile) {
    this.from_date = pathOr(null, ['from_date'], experience)
    this.to_date = pathOr(null, ['to_date'], experience)
    this.service_categories = pathOr([], ['service_categories'], experience)
    this.reference_email = pathOr('', ['reference_email'], experience)
    this.reference_name = pathOr('', ['reference_name'], experience)
    this.reference_phone = !path(['reference_phone'], experience) ? path(['profile', 'country', 'code'], profile)
      ? `${rawCountries.find(item => item[2] === pathOr('', ['profile', 'country', 'code'], profile).toLowerCase())[3]}`
      : '' : path(['reference_phone'], experience)
    this.confirmation = !!experience
  }
}
