import React, { useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import useMediaQuery from 'seniors-first-commons/services/useMediaQuery'
import {
  getNotifications,
  changeNotificationReadStatus,
  deleteNotification
} from 'seniors-first-commons/shared/store/notifications/actions'
import AppContainer from '../../components/organisms/AppContainer'
import NotificationsLayout from 'seniors-first-commons/components/templates/NotificationsLayout'
import { ITEMS_PER_PAGE } from 'seniors-first-commons/utils/constants'
import { BREAKPOINTS } from 'seniors-first-commons/styles/constants'

const Notifications = ({
  notifications,
  getNotifications,
  changeNotificationReadStatus,
  deleteNotification
}) => {
  const mediaQuery = useMediaQuery()
  const matchesMobile = mediaQuery[0] <= BREAKPOINTS.MOBILE_MAX

  const fetchNotifications = useCallback(() => {
    getNotifications({ page: 1, per_page: ITEMS_PER_PAGE })
  }, [getNotifications])

  useEffect(() => {
    fetchNotifications()
  }, [fetchNotifications])

  const handleChangeReadStatus = (id) => {
    changeNotificationReadStatus({ notificacion_id: id })
  }

  const handleChangeArchivedStatus = (id, read) => {
    deleteNotification({ notificacion_id: id, read })
  }

  const handlePageChange = (e) => {
    getNotifications({ page: e, per_page: ITEMS_PER_PAGE })
  }

  return (
    <div className={`${!matchesMobile ? 'full-content-caregiver' : ''}`}>
      <AppContainer
        name='Notifications'
        shouldCallNotifications={false}
      >
        <div className={`container section ${matchesMobile ? 'is-paddingless is-marginless' : ''}`}>
          <NotificationsLayout
            getNotifications={fetchNotifications}
            handleChangeArchivedStatus={handleChangeArchivedStatus}
            handleChangeReadStatus={handleChangeReadStatus}
            handlePageChange={handlePageChange}
            matchesMobile={matchesMobile}
            notifications={notifications}
          />
        </div>
      </AppContainer>
    </div>
  )
}

const mapStateToProps = (state) => ({
  notifications: state.notifications.data
})

const mapDispatchToProps = {
  changeNotificationReadStatus,
  deleteNotification,
  getNotifications
}

export default connect(mapStateToProps, mapDispatchToProps)(Notifications)
