import React from 'react'
import classes from './index.module.css'


const CustomModal = ({
  open,
  styles={},
  cardBodyStyles={},
  shouldCloseOnOverlayClick=false,
  hasCloseButton=true,
  showBackdrop = true,
  children,
  onClose
}) => {
  const onOverlayClick = () => {
    if (shouldCloseOnOverlayClick) onClose()
    else return
  }

  return (
    <div className={`modal ${open ? 'is-active' : ''}`}>
      {showBackdrop && <div className='modal-background' onClick={onOverlayClick}></div>}
      <div
        className={`modal-card ${(!showBackdrop && classes.modalWithoutBackdrop) || ''}`}
        style={{ maxWidth: '95%', overflow: 'visible', ...styles }}
      >
        {hasCloseButton &&
          <button
            aria-label='close'
            className={`delete ${classes.closeButton}`}
            onClick={() => onClose()}
            type='button'
          >
          </button>
        }

        <section className='modal-card-body' style={{ borderRadius: '14px', overflow: 'visible', ...cardBodyStyles }}>
          {children}
        </section>
      </div>
    </div>
  )
}

export default CustomModal
