import React from 'react'
import moment from 'moment'
import { ErrorMessage } from 'formik'
import HandleError from 'seniors-first-commons/components/atoms/ErrorHandler'
import Radios from 'seniors-first-commons/components/atoms/Radios'
import Switch from 'seniors-first-commons/components/atoms/Switch'
import DatePicker from 'seniors-first-commons/components/molecules/DatePicker'
import { PencilEdit, MaleIcon, FemaleIcon } from 'seniors-first-commons/components/atoms/images/icons'
import ErrorHandler from 'seniors-first-commons/components/atoms/ErrorHandler'
import Input from 'seniors-first-commons/components/atoms/Input'
import FieldLabel from 'seniors-first-commons/components/atoms/FieldLabel'


export const Username = (props) => {
  return (
    <>
      <FieldLabel
        active={false}
        bottomSpace={0}
        centered={false}
        id='username'
        labelName='carer.accountSettings.username'
        labelType='p1-bold'
        topSpace={3}
      />

      <Input
        className='input'
        disabled
        icon={(
          <PencilEdit
            fill='none'
            height='16'
            stroke='#A6A6A6'
            width='16'
          />
        )}
        {...props}
      />
    </>
  )
}

export const Email = (props) => {
  return (
    <>
      <FieldLabel
        active={false}
        bottomSpace={0}
        centered={false}
        id='email'
        labelName='carer.accountSettings.email'
        labelType='p1-bold'
        topSpace={3}
      />
      <Input
        className='input'
        disabled
        icon={(
          <PencilEdit
            fill='none'
            height='16'
            stroke='#A6A6A6'
            width='16'
          />
        )}
        {...props}
      />
    </>
  )
}

export const Firstname = (props) => {
  return (
    <>
      <FieldLabel
        bottomSpace={0}
        centered={false}
        id='firstname'
        labelName='carer.accountSettings.firstname'
        labelType='p1-bold'
        topSpace={3}
      />
      <Input
        className='input'
        icon={(
          <PencilEdit
            fill='none'
            height='16'
            stroke='#0080F5'
            width='16'
          />
        )}
        {...props}
      />
      <ErrorMessage
        centered={false}
        component={HandleError}
        name='firstname'
        spaceTop={0}
      />
    </>
  )
}

export const Lastname = (props) => {
  return (
    <>
      <FieldLabel
        bottomSpace={0}
        centered={false}
        id='lastname'
        labelName='carer.accountSettings.lastname'
        labelType='p1-bold'
        topSpace={3}
      />

      <Input
        className='input'
        icon={(
          <PencilEdit
            fill='none'
            height='16'
            stroke='#0080F5'
            width='16'
          />
        )}
        {...props}
      />

      <ErrorMessage
        centered={false}
        component={HandleError}
        name='lastname'
        spaceTop={0}
      />
    </>
  )
}

export const Birthday = (props) => {
  return (
    <div className='full-width'>
      <FieldLabel
        centered={false}
        id={props.field.name}
        labelName='carer.accountSettings.birthday'
        labelType='p1-bold'
        topSpace={4}
      />

      <DatePicker
        id={props.field.name}
        isOutsideRange={day => day.isSameOrAfter(moment())}
        openDirection='up'
        shouldRenderMonthElement
        {...props}
      />

      <ErrorMessage
        component={ErrorHandler}
        name={props.field.name}
      />
    </div>
  )
}

export const Gender = ({ gender }) => {
  const genders = [
    {
      Icon: MaleIcon,
      name: 'gender',
      text: 'carer.accountSettings.gender.male',
      value: 'm'
    },
    {
      Icon: FemaleIcon,
      name: 'gender',
      text: 'carer.accountSettings.gender.female',
      value: 'f'
    }
  ]

  return (
    <>
      <FieldLabel
        centered={false}
        id='gender'
        labelName='carer.accountSettings.gender'
        labelType='p1-bold'
        topSpace={3}
      />
      <Radios
        content={genders}
        input={gender}
      />
      <ErrorMessage
        component={ErrorHandler}
        name='gender'
      />
    </>
  )
}

export const NotificationsToggle = (props) => {
  return (
    <>
      <FieldLabel
        centered={false}
        id={props.field.name}
        labelName='carer.accountSettings.whatsappToggleLabel'
        labelType='p1-bold'
        topSpace={3}
      />
      <Switch {...props} />
    </>
  )
}

export const EmailNotificationsToggle = (props) => {
  return (
    <>
      <FieldLabel
        centered={false}
        id={props.field.name}
        labelName='carer.accountSettings.emailToggleLabel'
        labelType='p1-bold'
        topSpace={3}
      />
      <Switch {...props} />
    </>
  )
}
