import React from 'react'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import { CloseCircle } from 'seniors-first-commons/components/atoms/images/icons'


const RejectedOfferBody = () => {
  return (
    <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
      <CloseCircle
        fill='none'
        height='45'
        stroke='#ED0202'
        width='45'
      />
      <FormattedMessageWithClass
        className='p1-bold danger has-padding-t-7'
        id='carer.directoffers.actions.reject.text'
      />
    </div>
  )
}

export default RejectedOfferBody
