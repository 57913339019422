import React from 'react'
import { Redirect } from 'react-router-dom'
import routingService from 'seniors-first-commons/services/routingService'
import AppNavbar from 'components/molecules/AppNavbar'
import { ROUTE } from 'root/constants'

const WelcomeLayout = ({
  matchesMobile,
  renderNewHome,
  user,
  onLogout,
  children
}) => {
  if (renderNewHome) {
    return <Redirect to={routingService.getFinalRoute(ROUTE.HOME)} />
  }

  return (
    <div className='has-navbar-fixed-top has-navbar-fixed-bottom'>
      <AppNavbar
        changeRoute={() => {}}
        matchesMobile={matchesMobile}
        onLogout={onLogout}
        user={user}
      />
      <section>
        <div className='hero layout-default is-medium is-fullheight'>
          <div className={`hero-body ${matchesMobile ? 'is-paddingless-lr' : ''}`}>
            <div className='container'>
              {children}
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}


export default WelcomeLayout
