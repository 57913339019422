import React, { useState } from 'react'
import AdvancedCheckbox from 'seniors-first-commons/components/atoms/AdvancedCheckbox'
import ErrorHandler from 'seniors-first-commons/components/atoms/ErrorHandler'
import FieldLabel from 'seniors-first-commons/components/atoms/FieldLabel'
import Input from 'seniors-first-commons/components/atoms/Input'
import MinimumWageInfoModal from 'seniors-first-commons/components/organisms/MinimumWageInfoModal'
import Radios from 'seniors-first-commons/components/atoms/Radios'
import Select from 'seniors-first-commons/components/molecules/Select'
import Slider from 'seniors-first-commons/components/atoms/Slider'
import Switch from 'seniors-first-commons/components/atoms/Switch'
import { Field, ErrorMessage } from 'formik'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import { getMinimumWageInfo } from 'seniors-first-commons/utils/getMinimumWageInfo'

import {
  UsersIcon,
  PersonIcon,
  LiftIcon,
  ManualCarryIcon
} from 'seniors-first-commons/components/atoms/images/icons'
import classes from './index.module.css'


export const WorkType = ({
  shouldAskWorkPermit,
  accepts_work_with_autonomous,
  accepts_work_with_company,
  ...props
}) => {
  return (
    <div className='has-text-centered'>
      <FieldLabel
        bottomSpace={0}
        labelName='app.boarding.hiring.hiring.acceptwork'
        labelType='p2-bold'
        topSpace={shouldAskWorkPermit ? 1 : 7}
      />
      <p className='p3-bold grey-light has-text-centered has-padding-b-7'>
        <FormattedMessageWithClass id='app.boarding.hiring.hiring.acceptwork.option' />
      </p>
      <div className='columns is-marginless'>
        <div className='column'>
          <Field
            checked={accepts_work_with_company}
            component={AdvancedCheckbox}
            id='accepts_work_with_company'
            label={(
              <div className={classes.iconValueWrapper}>
                <UsersIcon
                  height='22'
                  stroke='#1DDDFA'
                  width='22'
                />
                <FormattedMessageWithClass id='client.createOffer.steps.contractType.company' />
              </div>
            )}
            name='accepts_work_with_company'
          />
        </div>
        <div className='column'>
          <Field
            checked={accepts_work_with_autonomous}
            component={AdvancedCheckbox}
            id='accepts_work_with_autonomous'
            label={(
              <div className={classes.iconValueWrapper}>
                <PersonIcon
                  fill='none'
                  height='22'
                  stroke='#1DDDFA'
                  width='22'
                />
                <FormattedMessageWithClass id='client.createOffer.steps.contractType.autonomus' />
              </div>
            )}
            name='accepts_work_with_autonomous'
          />
        </div>
      </div>

      {props.errors.accepts_work_with_autonomous || props.errors.accepts_work_with_company
        ? (
          <FormattedMessageWithClass
            className='p3-regular danger'
            id='validations.selectone'
          />
        )
        : null
      }
    </div>
  )
}

export const CriminalRecord = () => {

  return (
    <>
      <FieldLabel
        id='has_criminal_record_certificate'
        labelName='app.boarding.hiring.hiring.criminalrecord'
        labelType='p2-bold'
        topSpace={1}
      />

      <Field
        component={Switch}
        id='has_criminal_record_certificate'
        name='has_criminal_record_certificate'
      />
    </>
  )
}

export const WorkingAbroad = () => {

  return (
    <>
      <FieldLabel
        id='working_abroad'
        labelName='app.boarding.hiring.hiring.relocation'
        labelType='p2-bold'
        topSpace={1}
      />

      <Field
        component={Switch}
        id='working_abroad'
        name='working_abroad'
      />
    </>
  )
}

export const CountriesInterested = ({
  countries_interested,
  countries
}) => {
  return (
    <>
      <FieldLabel
        id='countries_interested'
        labelName='app.boarding.hiring.hiring.relocation.countries'
        labelType='p2-bold'
        topSpace={1}
      />
      <Field
        addStyles={{
          menu: (base) => ({
            ...base,
            zIndex: 31
          })
        }}
        component={Select}
        defaults={countries_interested}
        isMulti
        name='countries_interested'
        options={countries}
        placeholder=''
        shouldTranslate={false}
      />
      <ErrorMessage
        component={ErrorHandler}
        name='countries_interested'
      />
    </>
  )
}

export const EconomicConditions = ({ economic_conditions, profileCountryId }) => {
  const minWageInfo = getMinimumWageInfo(profileCountryId)

  const [wageModalOpen, setWageModalOpen] = useState(false)
  const closeWageModal = () => {
    setWageModalOpen(false)
  }
  const openWageModal = () => {
    setWageModalOpen(true)
  }

  const economicConditions = [
    {
      name: 'economic_conditions',
      text: 'app.boarding.hiring.salary.acceptwage',
      value: 'min_wage'
    },
    {
      name: 'economic_conditions',
      text: 'app.boarding.hiring.salary.setwage',
      value: 'custom'
    }
  ]

  return (
    <div className='has-margin-b-1 has-text-centered'>
      <Radios
        content={economicConditions}
        input={economic_conditions}
      />

      {!!minWageInfo && (
        <>
          <div className='has-text-centered full-width'>
            <a
              className='p2-bold primary-light'
              href={'#'}
              onClick={openWageModal}
              rel='noopener noreferrer'
            >
              <FormattedMessageWithClass id='client.createOffer.steps.retribution.learn-about-min-wage' />
            </a>
          </div>
          <MinimumWageInfoModal
            countryCode={minWageInfo.code}
            onClose={closeWageModal}
            open={wageModalOpen}
          />
        </>
      )}

    </div>
  )
}

const SalaryInput = (props) => {
  return (
    <Input
      className='input'
      icon={<span className='black'>{props.currency}</span>}
      min={0}
      type='number'
      wrapperClass='is-marginless-b'
      {...props}
    />
  )
}

export const MonthlyRate = ({ currency }) => {
  return (
    <>
      <FieldLabel
        bottomSpace={7}
        centered={false}
        id='salary'
        labelName='app.boarding.hiring.salary.salary'
        labelType=''
        topSpace={3}
      />
      <Field
        component={SalaryInput}
        currency={currency}
        name='salary'
      />
      <ErrorMessage
        component={ErrorHandler}
        name='salary'
      />
    </>
  )
}

export const HourlyRate = ({ currency }) => {
  return (
    <>
      <FieldLabel
        bottomSpace={7}
        centered={false}
        id='hourly_rate'
        labelName='app.boarding.hiring.salary.rate'
        labelType=''
        topSpace={3}
      />
      <Field
        component={SalaryInput}
        currency={currency}
        name='hourly_rate'
      />
      <ErrorMessage
        component={ErrorHandler}
        name='hourly_rate'
      />
    </>
  )
}

export const CustomSwitch = ({ name, labelName, ...props }) => {
  return (
    <>
      <FieldLabel
        id={name}
        labelName={labelName}
        labelType='p2-bold'
        topSpace={3}
      />

      <Field
        component={Switch}
        name={name}
        {...props}
      />
    </>
  )
}

export const PersonalCare = ({ personal_hygiene }) => {
  const personalHygiene = [
    {
      name: 'personal_hygiene',
      text: 'app.boarding.hiring.conditions.personalcare.yes',
      value: 'yes'
    },
    {
      name: 'personal_hygiene',
      text: 'app.boarding.hiring.conditions.personalcare.partially',
      value: 'partial'
    },
    {
      name: 'personal_hygiene',
      text: 'app.boarding.hiring.conditions.personalcare.no',
      value: 'no'
    }
  ]

  return (
    <>
      <FieldLabel
        id='personal_hygiene'
        labelName='app.boarding.hiring.conditions.personalcare'
        labelType='p2-bold'
        topSpace={1}
      />
      <Radios
        content={personalHygiene}
        input={personal_hygiene}
      />
      <ErrorMessage
        component={ErrorHandler}
        name='personal_hygiene'
      />
    </>
  )
}

export const MobilizationsType = ({
  movilization_crane,
  movilization_bodily,
  ...props
}) => {
  return (
    <div className='has-text-centered'>
      <FieldLabel
        id='movilization_type'
        labelName='app.boarding.hiring.conditions.mobilisation.whatkind'
        labelType='p2-bold'
        topSpace={1}
      />

      <div className='columns'>
        <div className='column'>
          <Field
            checked={movilization_crane}
            component={AdvancedCheckbox}
            id='movilization_crane'
            label={(
              <div className={classes.iconValueWrapper}>
                <LiftIcon
                  fill='#1DDDFA'
                  height='22'
                  stroke='#1DDDFA'
                  width='22'
                />
                <FormattedMessageWithClass id='app.boarding.hiring.conditions.mobilisation.lift' />
              </div>
            )}
            name='movilization_crane'
          />
        </div>
        <div className='column'>
          <Field
            checked={movilization_bodily}
            component={AdvancedCheckbox}
            id='movilization_bodily'
            label={(
              <div className={classes.iconValueWrapper}>
                <ManualCarryIcon
                  fill='#1DDDFA'
                  height='22'
                  stroke='#1DDDFA'
                  width='22'
                />
                <FormattedMessageWithClass id='app.boarding.hiring.conditions.mobilisation.manual' />
              </div>
            )}
            name='movilization_bodily'
          />
        </div>
      </div>
      {props.errors.movilization_bodily || props.errors.movilization_crane ?
        (
          <FormattedMessageWithClass
            className='p3-regular danger'
            id='app.boarding.hiring.conditions.mobilisation.options'
          />
        ): null
      }
    </div>
  )
}

export const CarryAbility = ({ movilization_max }) => {
  return (
    <>
      <FieldLabel
        id='movilization_max'
        labelName='app.boarding.hiring.conditions.movilisation.weight'
        labelType='p2-bold'
        topSpace={1}
      />

      <Field
        component={Slider}
        defaultValue={movilization_max || 60}
        max={120}
        min={5}
        name='movilization_max'
        sliderText='kg'
      />
    </>
  )
}
