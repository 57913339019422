import React from 'react'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import { SuccessIcon } from 'seniors-first-commons/components/atoms/images/icons'
import classes from './index.module.css'
import { useIntl } from 'react-intl'

const SuccessScreen = ({ title='', text='' }) => {

  const {locale} = useIntl()

  return (
    <div className={`${classes.message}`}>
      <SuccessIcon
        fill='none'
        height='88'
        stroke='#0080F5'
        width='88'
      />
      <h3 className={`t1-bold primary has-margin-t-4 ${classes.mainText}`}>
        <FormattedMessageWithClass id={title} />
      </h3>
      <p className={`t2-regular grey ${classes.secondaryText}`}>
        <FormattedMessageWithClass id={text} />
      </p>
      <a
        className={`filled primary has-margin-t-1 ${classes.button}`}
        href={`https://seniorsfirst.care/${locale}`}
      >
        <FormattedMessageWithClass id='apps.general.goBack.home' />
      </a>
    </div>
  )
}

export default SuccessScreen
