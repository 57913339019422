import { pathOr } from 'ramda'


export default class PersonalData {
  constructor(profile) {
    // personal data
    this.username = pathOr('', ['username'], profile)
    this.firstname = pathOr('', ['firstname'], profile)
    this.lastname = pathOr('', ['lastname'], profile)
    this.email = pathOr('', ['email'], profile)
    this.gender = pathOr('m', ['profile', 'gender'], profile)
    this.birthday = pathOr(null, ['profile', 'birthday'], profile)
    this.nationalities = pathOr([], ['profile', 'nationalities'], profile)
    this.has_driving_license = pathOr(false, ['profile', 'has_driving_license'], profile)

    // contact details
    this.country = pathOr({
      label: '',
      value: ''
    }, ['profile', 'country'], profile)
    this.phone = pathOr('', ['profile', 'phone'], profile)
    this.city = {
      address_lat: pathOr(0, ['profile', 'city_lat'], profile),
      address_lng: pathOr(0, ['profile', 'city_lng'], profile),
      formatted_address: pathOr('', ['profile', 'city'], profile)
    }
  }
}
