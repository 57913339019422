export const BREAKPOINTS = {
  DESKTOP_MAX: 1215,
  DESKTOP_MIN: 1024,
  FULLHD_MAX: 1999,
  FULLHD_MIN: 1408,
  MOBILE_MAX: 768,
  MOBILE_MIN: 320,
  TABLET_MAX: 1023,
  TABLET_MIN: 769,
  WIDESCREEN_MAX: 1407,
  WIDESCREEN_MIN: 1216
}
