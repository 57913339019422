import React from 'react'
import { useFormatMessage } from 'seniors-first-commons/shared/store/translations/effects'
import { UploadProgress } from 'components/atoms/UploadProgress'
import { videoIcon } from 'seniors-first-commons/components/atoms/images'


const VideoUploadBar = ({
  text,
  progress = 0,
  startedUploading,
  isUploading,
  onClick
}) => {
  const handleInputClick = () => {
    if(isUploading || startedUploading) return
    onClick()
  }

  const messages = {
    upload: useFormatMessage('app.boarding.videocv.uploadbar.upload'),
    uploading: useFormatMessage('app.boarding.videocv.uploadbar.uploading')
  }

  return (
    <div className=''>
      <p className='p2-bold has-padding-7'>
        {text}
      </p>

      <div
        className='button-shadow-footer columns is-mobile'
        onClick={handleInputClick}
        style={{ cursor: 'pointer' }}
      >
        {progress ? (
          <UploadProgress progress={progress} />
        ) : (
          <>
            <div className='column'>
              <span className='p2-bold'>
                {startedUploading ? messages.uploading : messages.upload}
              </span>
            </div>

            <div className='column has-text-right'>
              <span className='icon is-small'>
                <img alt='videoIcon' src={videoIcon} />
              </span>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default VideoUploadBar
