import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import routingService from 'seniors-first-commons/services/routingService'
import { useQuery } from 'seniors-first-commons/shared/hooks'
import useMediaQuery from 'seniors-first-commons/services/useMediaQuery'
import { shouldAskWorkPermit } from 'store/profile/selector'
import {
  getServices,
  getLanguages,
  getIllnesses
} from 'seniors-first-commons/shared/store/helpers/actions'
import {
  clearDirtyForms
} from 'seniors-first-commons/shared/store/ui/actions'
import {
  updateProfile,
  updateServices,
  updateHiringConditions,
  updateFormation,
  updateIllnessesExperience,
  getExperience,
  createExperience,
  deleteExperience,
  updateExperience,
  updateUsernameInProfile
} from 'store/profile/actions'
import { updateUser } from 'seniors-first-commons/shared/store/user/actions'
import AppContainer from 'components/organisms/AppContainer'
import ProfileBoardingLayout from 'components/templates/ProfileBoardingLayout'
import PersonalDataForm from 'components/organisms/PersonalDataForm'
import ServicesForm from 'components/organisms/ServicesForm'
import HiringConditionsForm from 'components/organisms/HiringConditionsForm'
import FormationForm from 'components/organisms/FormationForm'
import IllnessesExperience from 'components/organisms/IllnessesExperience'
import Experiences from 'components/organisms/Experiences'
import ExperienceForm from 'components/organisms/ExperiencesForm'
import VideoCv from 'components/organisms/VideoCv'
import { ROUTE } from 'root/constants'


const BOARDING_STEPS = {
  'experience': {
    form: ExperienceForm,
    next: 'experiences'
  },
  'experiences': {
    form: Experiences,
    next: 'video-cv'
  },
  'formation': {
    form: FormationForm,
    next: 'illnesses-experience'
  },
  'hiring-conditions': {
    form: HiringConditionsForm,
    next: 'formation'
  },
  'illnesses-experience': {
    form: IllnessesExperience,
    next: 'experiences'
  },
  'personal-data': {
    form: PersonalDataForm,
    next: 'services'
  },
  'services': {
    form: ServicesForm,
    next: 'hiring-conditions'
  },
  'video-cv': {
    form: VideoCv,
    next: '/'
  }
}

const ProfileBoarding = ({
  countries,
  languages,
  services,
  illnesses,
  profile,
  videos,
  user,
  shouldAskWorkPermit,
  isSubmitting,
  clearDirtyForms,
  ...props
}) => {
  const [showAddExperiencesModal, setShowAddExperiencesModal] = useState(false)
  const [showNotPermittedModal, setShowNotPermittedModal] = useState(false)
  const [showAddAnotherExpModal, setShowAddAnotherExpModal] = useState(false)
  const history = useHistory()
  const query = useQuery()
  const mediaQuery = useMediaQuery()
  const comingFrom = query.get('from')
  const matchesMobile = mediaQuery[0] <= 768
  const step = props.match.params.step

  const moveToNextStep = () => {
    //this particular steps must go to the next instead of home because they are related as one "module"
    const shouldNotGoHome = ['experiences', 'illnesses-experience'].includes(step)
    if (comingFrom === 'home' && !shouldNotGoHome) {
      goHome()
    } else {
      routingService.push(history, `${ROUTE.BOARDING}/${BOARDING_STEPS[step].next}${comingFrom ? `?from=${comingFrom}` : ''}`)
    }
  }
  const goHome = () => {
    routingService.push(history, `${ROUTE.HOME}`)
  }
  const onAddExperience = () => {
    routingService.push(history, `${ROUTE.BOARDING}/experience${comingFrom ? `?from=${comingFrom}` : ''}`)
  }
  const onEditExperience = (id) => {
    routingService.push(history, `${ROUTE.BOARDING}/experience?experience_id=${id}${comingFrom ? `&from=${comingFrom}` : ''}`)
  }
  const continueToExperiences = () => {
    routingService.push(history, `${ROUTE.BOARDING}/experiences${comingFrom ? `?from=${comingFrom}` : ''}`)
  }
  const onContinueFromWorkPermitModal = () => {
    setShowNotPermittedModal(false)
    routingService.push(history, `${ROUTE.BOARDING}/formation`)
  }
  const onContinueLaterFromWorkPermitModal = () => {
    setShowNotPermittedModal(false)
    routingService.push(history, `${ROUTE.HOME}`)
  }
  const onConfirmFromAddNewExpModal = () => {
    setShowAddExperiencesModal(false)
    routingService.push(history, `${ROUTE.BOARDING}/illnesses-experience`)
  }
  const onSkipAddNewExpModal = () => {
    setShowAddExperiencesModal(false)
    routingService.push(history, `${ROUTE.BOARDING}/video-cv`)
  }

  return (
    <AppContainer name='Boarding'>
      <div className='hero layout-default is-medium is-fullheight'>
        <div className={`hero-body ${matchesMobile ? 'is-paddingless-lr' : ''}`}>
          <div className={'container'}>
            <ProfileBoardingLayout
              actions={{
                clearDirtyForms,
                continueToExperiences,
                goHome,
                moveToNextStep,
                onAddExperience,
                onConfirmFromAddNewExpModal,
                onContinueFromWorkPermitModal,
                onContinueLaterFromWorkPermitModal,
                onEditExperience,
                onSkipAddNewExpModal,
                ...props
              }}
              comingFrom={comingFrom}
              countries={countries}
              illnesses={illnesses}
              isSubmitting={isSubmitting}
              languages={languages}
              matchesMobile={matchesMobile}
              modalsState={{
                setShowAddAnotherExpModal,
                setShowAddExperiencesModal,
                setShowNotPermittedModal,
                showAddAnotherExpModal,
                showAddExperiencesModal,
                showNotPermittedModal
              }}
              profile={profile}
              services={services}
              shouldAskWorkPermit={shouldAskWorkPermit}
              step={step}
              steps={BOARDING_STEPS}
              user={user}
              videos={videos}
            />
          </div>
        </div>
      </div>
    </AppContainer>
  )
}

const mapStateToProps = (state) => {
  return {
    countries: state.helpers.helpers.countries,
    illnesses: state.helpers.helpers.illnesses,
    isSubmitting: state.profile.profile.updatingProfileData ||
    state.profile.videos.uploadingVideo ||
    state.profile.videos.deletingVideo,
    languages: state.helpers.helpers.languages,
    profile: state.profile.profile.data,
    services: state.helpers.helpers.services,
    shouldAskWorkPermit: shouldAskWorkPermit(state),
    user: state.user.user.data,
    videos: state.profile.videos.data
  }
}

const mapDispatchToProps = {
  clearDirtyForms,
  createExperience,
  deleteExperience,
  getExperience,
  getIllnesses,
  getLanguages,
  getServices,
  updateExperience,
  updateFormation,
  updateHiringConditions,
  updateIllnessesExperience,
  updateProfile,
  updateServices,
  updateUser,
  updateUsernameInProfile
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileBoarding)
