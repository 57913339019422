import { createReducer } from '@reduxjs/toolkit'
import {
  toggleChatModal,
  chatWithClient,
  changeErrorMessageStatus,
  setInbox,
  getNotReadMessages,
  toggleRightSidebar,
  toggleLeftSidebar
} from './actions'

const initValues = {
  chat: {
    data: null,
    loading: false,
    notReadMessages: 0,
    showModal: false
  },
  error: false,
  inbox: false
}

const reducer = createReducer(initValues, (builder) => {

  builder.addCase(toggleRightSidebar, (state, action) => {
    state.showRightSidebar = action.payload
  })

  builder.addCase(toggleLeftSidebar, (state, action) => {
    state.showLeftSidebar = action.payload
  })

  builder.addCase(toggleChatModal, (state, action) => {
    state.chat.showModal = action.payload
  })

  builder.addCase(chatWithClient.fulfilled, (state, action) => {
    state.chat.data = action.payload
  })

  builder.addCase(chatWithClient.pending, (state) => {
    state.chat.loading = true
  })

  builder.addCase(changeErrorMessageStatus, (state, action) => {
    state.error = action.payload
  })

  builder.addCase(setInbox, (state, action) => {
    state.inbox = action.payload
  })

  builder.addCase(getNotReadMessages, (state, action) => {
    state.chat.notReadMessages = action.payload
  })

  builder.addMatcher((action) => [
    chatWithClient.fulfilled.toString(),
    chatWithClient.rejected.toString()
  ].includes(action.type), (state) => {
    state.chat.loading = false
  })
})

export default reducer
