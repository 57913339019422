import React from 'react'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import JobOfferCard from 'components/organisms/JobOfferCard'
import classes from '../index.module.css'


const Pending = ({
  matchesMobile,
  item,
  actions
}) => {
  return (
    <JobOfferCard
      data={item}
      matchesMobile={matchesMobile}
      onOpenDaysAndShiftsModal={actions.onOpenDaysAndShiftsModal}
    >
      <div className='full-width'>
        <button
          className={`filled ${classes.submit}`}
          onClick={() => actions.onAcceptDirectOffer(item?.id)}
        >
          <FormattedMessageWithClass id='carer.directoffers.actions.accept.button' />
        </button>
      </div>

      <div className='full-width'>
        <button
          className={`outlined has-margin-t-7 ${classes.submit}`}
          onClick={() => actions.onShowInterest(item?.id)}
        >
          <FormattedMessageWithClass id='carer.directoffers.actions.interested.button' />
        </button>
      </div>

      <div className='full-width'>
        <button
          className={`button-text has-margin-t-7 ${classes.submit}`}
          onClick={() => actions.onRejectDirectOffer(item?.id)}
        >
          <FormattedMessageWithClass
            className='danger p2-bold'
            id='carer.directoffers.actions.reject.button'
          />
        </button>
      </div>
    </JobOfferCard>
  )
}

export default Pending
