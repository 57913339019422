import { pathOr } from 'ramda'


export default class AvailabilityModal {
  constructor(profile) {
    this.hiring_min_hours = pathOr(0, ['offered_service', 'hiring_min_hours'], profile)
    this.day_times = pathOr([], ['offered_service', 'day_times'], profile)
    this.external_mode = pathOr(false, ['offered_service', 'external_mode'], profile)
    this.internal_mode = pathOr(false, ['offered_service', 'internal_mode'], profile)
  }
}
