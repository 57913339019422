import React from 'react'
import CustomModal from 'seniors-first-commons/components/atoms/CustomModal'
import minimumWageInfoRawData from 'seniors-first-commons/utils/minimumWageInfoRawData'
import { FormattedMessageWithClass, useFormatMessage } from 'seniors-first-commons/shared/store/translations/effects'

const MinimumWageInfoModal = ({ open, countryCode, onClose }) => {

  const minimunWageInfoUrlUK = useFormatMessage('client.createOffer.steps.retribution.minWageTable.disclaimerLink.UK')

  const DisclaimerMessage = () => {
    switch (countryCode) {
    case 'ES':
      return <DisclaimerMessageES />
    case 'UK':
      return <DisclaimerMessageUK />
    default:
      return null
    }
  }

  const DisclaimerMessageES = () => {
    return  <FormattedMessageWithClass id='client.createOffer.steps.retribution.minWageTable.disclaimer.ES' />
  }

  const DisclaimerMessageUK = () => {
    return (
      <>
        <FormattedMessageWithClass id='client.createOffer.steps.retribution.minWageTable.disclaimer.UK' />
        &nbsp;
        <a
          href={minimunWageInfoUrlUK}
          rel='noreferrer'
          target='_blank'
        >
          <FormattedMessageWithClass id='client.createOffer.steps.retribution.minWageTable.disclaimerLink.UK' />
        </a>
        &nbsp;
        <FormattedMessageWithClass id='client.createOffer.steps.retribution.minWageTable.orContactUs.UK' />
      </>
    )
  }

  return (
    <CustomModal
      cardBodyStyles={{
        maxHeight: '80vh',
        overflow: 'auto'
      }}
      onClose={onClose}
      open={open}
      shouldCloseOnOverlayClick
    >
      <h1 className='t2-bold grey has-text-centered has-margin-b-4'>
        <FormattedMessageWithClass id='client.createOffer.steps.retribution.min-wage' />
      </h1>
      <table className='table grey is-striped table-primary is-fullwidth'>
        <thead>
          <tr className='is-borderless'>
            <th>
              <FormattedMessageWithClass id='client.createOffer.steps.retribution.minWageTable.hoursPerWeek' />
            </th>
            <th>
              <FormattedMessageWithClass id='client.createOffer.steps.retribution.minWageTable.hourlyRate' />
            </th>
            <th>
              <FormattedMessageWithClass id='client.createOffer.steps.retribution.minWageTable.monthlyGrossIncome' />
            </th>
          </tr>
        </thead>
        <tbody>
          {minimumWageInfoRawData[countryCode].map((data, index) =>
            <tr
              className='is-borderless'
              key={index}
            >
              <td>{data.hoursPerWeek}</td>
              <td>{data.hourlyRate}</td>
              <td>{data.monthlyGrossIncome}</td>
            </tr>
          )}
        </tbody>
      </table>
      <p>
        <DisclaimerMessage />
      </p>
    </CustomModal>
  )
}

export default MinimumWageInfoModal
