import React, { useEffect } from 'react'
import { Field } from 'formik'
import Wizard from 'components/organisms/ProfileWizard'
import IllnessesExperiencesInitValues from 'models/illnessesExperiences'
import ProfileBoardingFooter from '../ProfileBoardingFooter'
import { Illnesses } from './inputs'


const WizardStep = ({ children, ...props }) => children(props)

const Footer = ({ onGoBack, ...props }) => {
  return (
    <ProfileBoardingFooter
      onBack={onGoBack}
      step={5}
      {...props}
      laststepText='app.boarding.layout.footer.next'
    />
  )
}

const IllnessesExperience = ({
  profile,
  illnesses,
  isSubmitting,
  getIllnesses,
  ...props
}) => {

  useEffect(() => {
    if (!illnesses) getIllnesses()
    // eslint-disable-next-line
  }, [getIllnesses])

  const onSubmit = (values) => {
    const { illnesses } = values
    props.updateIllnessesExperience(illnesses)
      .unwrap()
      .then(() => props.moveToNextStep())
  }

  return (
    <Wizard
      enableReinitialize={true}
      Footer={Footer}
      initialValues={new IllnessesExperiencesInitValues(profile)}
      loading={isSubmitting}
      onSubmit={onSubmit}
      {...props}
    >
      <WizardStep>
        {() => {
          return (
            <Field
              component={Illnesses}
              name='illnesses'
              options={illnesses || []}
            />
          )
        }}
      </WizardStep>
    </Wizard>
  )
}

export default IllnessesExperience
