import { pathOr } from 'ramda'


export default class Services {
  constructor(profile) {
    // offered services
    this.services = pathOr({
      1: [],
      2: [],
      3: [],
      4: []
    }, ['offered_service', 'formatted_services'], profile)

    // modality
    this.external_mode = pathOr(false, ['offered_service', 'external_mode'], profile)
    this.internal_mode = pathOr(false, ['offered_service', 'internal_mode'], profile)

    //  availability
    this.availableDays = ''
    this.availableShifts = []
    this.day_times = pathOr([], ['offered_service', 'day_times'], profile)
    this.hiring_min_hours = pathOr(1, ['offered_service', 'hiring_min_hours'], profile)
    this.working_location = {
      address_lat: pathOr(pathOr(0, ['profile', 'city_lat'], profile), ['offered_service', 'lat'], profile),
      address_lng: pathOr(pathOr(0, ['profile', 'city_lng'], profile), ['offered_service', 'lng'], profile),
      formatted_address: pathOr(pathOr('', ['profile', 'city'], profile), ['offered_service', 'working_location'], profile)
    }
    this.radio_km = pathOr(10, ['offered_service', 'radio_km'], profile)
  }
}
