import React from 'react'
import { FormattedMessage } from 'react-intl'
import { TimesIcon } from 'seniors-first-commons/components/atoms/images/icons'


const Option = ({ isSelected, value, description, onSelect, onRemove }) => (
  <li className={`${isSelected ? 'selected' : ''}`}>
    {/* eslint-disable-next-line */}
    <a onClick={!isSelected ? onSelect : null}>
      {value}
      {description && <span className='small is-size-7'>{description}</span>}
    </a>
    {isSelected && (
      <span
        className='icon is-large'
        onClick={onRemove}
      >
        <TimesIcon
          height='22'
          stroke='#fff'
          strokeWidth='3'
          width='22'
        />
      </span>
    )}
  </li>
)

const SelectMultiSelect = ({
  options = [],
  selected = [],
  onSelectAll,
  onSelect,
  onRemove
}) => {
  return (
    <ul className='select-list'>
      <li className='select-all'>
        {/* eslint-disable-next-line */}
        <a onClick={onSelectAll}>
          <FormattedMessage id='app.components.multipleselect.all' />
        </a>
      </li>
      {options.map(({ id, value, description, ...props }) => {
        const onRemoveItem = () => onRemove(id)
        const onSelectItem = () => onSelect({id, value, ...props})
        return (
          <Option
            description={description}
            isSelected={selected.find(opt => opt.id === id) !== undefined}
            key={`opt-${value}`}
            onRemove={onRemoveItem}
            onSelect={onSelectItem}
            value={value}
          />
        )
      })}
    </ul>
  )
}


export default SelectMultiSelect
