import React from 'react'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import { BriefCase } from 'seniors-first-commons/components/atoms/images/icons'
import classes from './index.module.css'


const NoJobPosts = () => {
  return (
    <div className={classes.container}>
      <BriefCase
        className='primary-light-icon'
        height='100px'
        width='101px'
      />
      <div className='t4-bold primary'>
        <FormattedMessageWithClass id='carer.home.notPosts' />
      </div>
    </div>
  )
}

export default NoJobPosts
