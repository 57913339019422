import React from 'react'
import { Field, ErrorMessage } from 'formik'
import moment from 'moment'
import ErrorHandler from 'seniors-first-commons/components/atoms/ErrorHandler'
import FieldLabel from 'seniors-first-commons/components/atoms/FieldLabel'
import Input from 'seniors-first-commons/components/atoms/Input'
import Radios from 'seniors-first-commons/components/atoms/Radios'
import DatePicker from 'seniors-first-commons/components/molecules/DatePicker'
import Select from 'seniors-first-commons/components/molecules/Select'
import Switch from 'seniors-first-commons/components/atoms/Switch'
import PhoneInput from 'seniors-first-commons/components/atoms/PhoneInput'
import rawCountries from 'seniors-first-commons/utils/rawCountries'
import AddressAutoSuggest from 'seniors-first-commons/components/atoms/AddressAutoSuggest'
import { PencilEdit, MaleIcon, FemaleIcon } from 'seniors-first-commons/components/atoms/images/icons'
import classes from './index.module.css'


export const Username = (props) => {
  return (
    <Input
      {...props}
      className='input'
      disabled
      icon={(
        <PencilEdit
          fill='none'
          height='16'
          stroke='#A6A6A6'
          width='16'
        />
      )}
      maxLength={255}
    />
  )
}

export const Email = (props) => {
  return (
    <Input
      {...props}
      className='input'
      disabled
      icon={(
        <PencilEdit
          fill='none'
          height='16'
          stroke='#A6A6A6'
          width='16'
        />
      )}
      maxLength={255}
    />
  )
}

export const Firstname = (props) => {
  return (
    <Input
      {...props}
      className={`input ${classes.input}`}
      icon={(
        <PencilEdit
          fill='none'
          height='16'
          stroke='#0080F5'
          width='16'
        />
      )}
      maxLength={255}
    />
  )
}

export const Lastname = (props) => {
  return (
    <Input
      {...props}
      className={`input ${classes.input}`}
      icon={(
        <PencilEdit
          fill='none'
          height='16'
          stroke='#0080F5'
          width='16'
        />
      )}
      maxLength={255}
    />
  )
}

export const Birthday = (props) => {
  return (
    <div className='full-width'>
      <FieldLabel
        id={props.field.name}
        labelName='app.boarding.personaldata.form.dateofbirth'
        labelType='p1-bold'
        topSpace={1}
      />

      <DatePicker
        id={props.field.name}
        isOutsideRange={day => day.isSameOrAfter(moment())}
        openDirection='up'
        shouldRenderMonthElement
        {...props}
      />

      <ErrorMessage
        component={ErrorHandler}
        name={props.field.name}
      />
    </div>
  )
}

export const Gender = ({ gender }) => {
  const genders = [
    {
      Icon: MaleIcon,
      name: 'gender',
      text: 'app.boarding.personaldata.form.male',
      value: 'm'
    },
    {
      Icon: FemaleIcon,
      name: 'gender',
      text: 'app.boarding.personaldata.form.female',
      value: 'f'
    }
  ]

  return (
    <>
      <FieldLabel
        id='gender'
        labelName='app.boarding.personaldata.form.sex'
        labelType='p1-bold'
        topSpace={1}
      />
      <Radios
        content={genders}
        input={gender}
      />
      <ErrorMessage
        component={ErrorHandler}
        name='gender'
      />
    </>
  )
}

export const Nationality = ({
  nationalities,
  countries,
  ...props
}) => {
  return (
    <>
      <FieldLabel
        id={props.field.name}
        labelName='app.boarding.personaldata.form.nationality'
        labelType='p1-bold'
        topSpace={1}
      />
      <Select
        component={Select}
        defaults={nationalities}
        isMulti
        options={countries}
        placeholder=''
        shouldTranslate={false}
        {...props}
      />
      <ErrorMessage
        component={ErrorHandler}
        name={props.field.name}
      />
    </>
  )
}

export const DrivingLicense = (props) => {
  return (
    <>
      <FieldLabel
        id={props.field.name}
        labelName='app.boarding.personaldata.form.hasdrivinglicense'
        labelType='p1-bold'
        topSpace={1}
      />
      <Switch {...props} />
    </>
  )
}

export const Country = ({
  countries,
  values,
  ...props
}) => {
  const onChangeCountry = (e) => {
    const country = rawCountries.find(item => item[2].toLowerCase() === e.code.toLowerCase())
    if (values.country.value !== e.value) {
      props.form.setFieldValue('phone', country[3])
      props.form.setFieldValue('city', { address_lat: 0, address_lng: 0, formatted_address: '' })
      props.form.setFieldValue('country', e)
    }
  }

  return (
    <>
      <FieldLabel
        id='country'
        labelName='app.boarding.personaldata.form.country'
        labelType='p1-bold'
      />
      <Field
        component={Select}
        id='country'
        name='country'
        onChange={(e) => onChangeCountry(e)}
        options={countries}
        placeholder=''
        shouldTranslate={false}
        value={values.country && values.country.value ? values.country : null}
      />
      <ErrorMessage
        component={ErrorHandler}
        name='country'
      />
    </>
  )
}

export const Phone = () => {
  return (
    <>
      <FieldLabel
        id='phone'
        labelName='app.boarding.personaldata.form.phonenumber'
        labelType='p1-bold'
        topSpace={1}
      />
      <Field
        component={PhoneInput}
        name='phone'
      />
      <ErrorMessage
        component={ErrorHandler}
        name='phone'
      />
    </>
  )
}

const AddressInput = (props) => {
  return (
    <AddressAutoSuggest
      icon={(
        <PencilEdit
          fill='none'
          height='16'
          stroke='#0080F5'
          width='16'
        />
      )}
      inputClassName='input'
      placeholder=''
      {...props}
    />
  )
}

export const Location = ({ country, ...props }) => {
  return (
    <>
      <FieldLabel
        id={props.field.name}
        labelName='app.boarding.personaldata.form.location'
        labelType='p1-bold'
        topSpace={1}
      />
      <AddressInput
        country={country ? country.code : null}
        {...props}
      />
      <ErrorMessage
        component={ErrorHandler}
        name={props.field.name}
      />
    </>
  )
}
