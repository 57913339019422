import React from 'react'
import { pathOr } from 'ramda'
import { Formik, Form, Field } from 'formik'
import {
  FormattedMessageWithClass,
  useFormatMessage
} from 'seniors-first-commons/shared/store/translations/effects'
import withPhoto from 'seniors-first-commons/hoc/withPhoto'
import WelcomeBox from 'components/molecules/WelcomeBox'
import UploadImage from 'seniors-first-commons/components/atoms/UploadImage'
import TextField from 'seniors-first-commons/components/atoms/TextField'


const WelcomeFirstScreen = ({
  firstSteps,
  profile,
  completionPercentage,
  matchesMobile,
  onPhotoClick,
  actions
}) => {
  const firstname = pathOr('', ['data', 'firstname'], profile)
  const lastname = pathOr('', ['data', 'lastname'], profile)
  const photo = pathOr(null, ['data', 'bio', 'photo'], profile)
  const bio = pathOr('', ['data', 'bio', 'bio'], profile)
  const translations = {
    bioPlaceholder: useFormatMessage('app.home.profileinfo.bio')
  }

  return (
    <>
      <div
        className='container section'
        style={{ paddingLeft: !matchesMobile ? '4rem' : '' }}
      >
        <article className={`columns has-padding-b-3 has-padding-lrt-${matchesMobile ? '7' : '1'}`}>
          <div className={`column ${!matchesMobile ? 'is-narrow' : ''}`}>
            <div style={{ minWidth: '160px' }}>
              <UploadImage
                image={photo}
                onClick={onPhotoClick}
              />
            </div>
          </div>
          <div className='column is-6'>
            <p className='primary t1-bold' style={{ wordBreak: 'break-all' }}>
              {`${firstname  } ${  lastname}`}
            </p>

            <Formik
              enableReinitialize
              initialValues={{ bio }}
              onSubmit={actions.onSubmitBio}
            >
              {({ isSubmitting, dirty, isValid }) => {
                return (
                  <Form>
                    <Field
                      component={TextField}
                      name='bio'
                      placeholder={translations.bioPlaceholder}
                      rows={2}
                    />
                    <button
                      className='button-text is-paddingless-lr'
                      disabled={isSubmitting || !(dirty && isValid)}
                      type='submit'
                    >
                      <FormattedMessageWithClass
                        className='p2-bold'
                        id='app.home.profileinfo.save'
                      />
                    </button>
                  </Form>
                )
              }}
            </Formik>
          </div>
          <div className='column is-2 is-offset-1'>
            <div
              className='card has-padding-bt-7 is-flex white primary-background'
              style={{
                alignItems: 'center',
                flexDirection: 'column',
                height: '100px',
                justifyContent: 'space-between'
              }}
            >
              <FormattedMessageWithClass
                className='p3-bold'
                id='app.home.profilepercentage.profile'
              />
              <span className='p1-bold'>{completionPercentage}%</span>
              <FormattedMessageWithClass
                className='p3-bold'
                id='app.home.profilepercentage.complete'
              />
            </div>
          </div>
        </article>
      </div>
      <section className='profile-bg' style={{ minHeight: '45vh' }}>
        <div className={'hero-body'}>
          <div className='container'>
            <WelcomeBox
              steps={firstSteps}
            />
          </div>
        </div>
      </section>
    </>
  )
}

export default withPhoto(WelcomeFirstScreen)
