import React from 'react'
import { connect } from 'react-redux'
import { toast } from 'bulma-toast'
import { injectIntl } from 'react-intl'
import { toggleSuccessStatus as toggleOffersSuccess } from 'store/offers/actions'
import { toggleSuccessStatus as toggleAuthSuccessStatus } from 'seniors-first-commons/shared/store/auth/actions'
import { toggleSuccessStatus as toggleProfileSuccessStatus } from 'store/profile/actions'


const SuccessSnackbar = ({
  successMessage,
  toggleOffersSuccess,
  toggleAuthSuccessStatus,
  toggleProfileSuccessStatus,
  ...props
}) => {
  if(successMessage){
    setTimeout(() => {
      toggleOffersSuccess('')
      toggleAuthSuccessStatus('')
      toggleProfileSuccessStatus('')
    }, 3000)

    toast({
      closeOnClick: false,
      dismissible: true,
      message: `<span class='p1-bold white'>${props.intl.formatMessage({ id: successMessage })}</span>`,
      pauseOnHover: true,
      position: 'bottom-center',
      type: 'is-success'
    })

    const closeButton = document.querySelector('.delete')
    closeButton.addEventListener('click', () => {
      toggleOffersSuccess('')
      toggleAuthSuccessStatus('')
      toggleProfileSuccessStatus('')
    })
  }
  return <></>
}

const mapStateToProps = (state) => {
  return {
    successMessage: state.offers.success ||
    state.auth.success ||
    state.profile.success
  }
}

const mapDispatchToProps = {
  toggleAuthSuccessStatus,
  toggleOffersSuccess,
  toggleProfileSuccessStatus
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SuccessSnackbar))
