import React from 'react'
import LanguageSwitch from 'seniors-first-commons/components/organisms/LanguageSwitch'
import ClientsLogo from 'seniors-first-commons/components/atoms/images/logo2.svg'
import classes from './index.module.css'


const PublicNavbar = ({ onRedirectToHome }) => {
  return (
    <nav className={`navbar is-fixed-top level has-padding-t-5 ${classes.navbar}`}>
      <div className='level-item is-hidden-touch'></div>
      <div className='level-item has-text-centered'>
        <img
          alt='Seniors First'
          className={classes.logo}
          onClick={onRedirectToHome}
          src={ClientsLogo}
        />
      </div>
      <div className='level-item has-text-centered'>
        <div>
          <LanguageSwitch additionalClasses={classes.languagesSwitch} />
        </div>
      </div>
    </nav>
  )
}

export default PublicNavbar
