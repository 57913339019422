import React from 'react'
import { ErrorMessage } from 'formik'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import FieldLabel from 'seniors-first-commons/components/atoms/FieldLabel'
import ErrorHandler from 'seniors-first-commons/components/atoms/ErrorHandler'
import Radios from 'seniors-first-commons/components/atoms/Radios'
import Input from 'seniors-first-commons/components/atoms/Input'
import Select from 'seniors-first-commons/components/molecules/Select'


export const FormationType = ({
  formation_type,
  username=''
}) => {

  const formation_types = [
    {
      name: 'formation_type',
      text: 'app.boarding.education.primary',
      value: '1'
    },
    {
      name: 'formation_type',
      text: 'app.boarding.education.secondary',
      value: '2'
    },
    {
      name: 'formation_type',
      text: 'app.boarding.education.professional',
      value: '3'
    },
    {
      name: 'formation_type',
      text: 'app.boarding.education.university',
      value: '4'
    },
    {
      name: 'formation_type',
      text: 'app.boarding.education.other',
      value: '5'
    }
  ]

  return (
    <>
      <p className='p1-bold primary has-text-centered has-margin-b-7 has-margin-t-7 ellipsis'>
        <FormattedMessageWithClass id='app.boarding.education.title' />
        {' '}{username}?
      </p>

      <p className='p2-bold grey-light has-text-centered has-padding-b-5'>
        <FormattedMessageWithClass id='app.boarding.education.text' />
      </p>

      <Radios
        content={formation_types}
        input={formation_type}
      />

      <ErrorMessage
        component={ErrorHandler}
        name='formation_type'
      />
    </>
  )
}

export const OtherFormationType = (props) => {
  return (
    <>
      <FieldLabel
        labelName='app.boarding.education.otherformationtype'
        labelType='p3-bold'
        topSpace={3}
      />

      <Input
        className='input'
        maxLength={10}
        placeholder='Max: 10'
        {...props}
      />

      <ErrorMessage
        component={ErrorHandler}
        name='other_formation_type'
      />
    </>
  )
}

export const SpecificFormation = (props) => {
  return (
    <>
      <FieldLabel
        labelName='app.boarding.education.otherspecificformation'
        labelType='p2-bold'
        topSpace={1}
      />

      <Input
        className='input'
        maxLength={150}
        placeholder='Max: 150'
        {...props}
      />
    </>
  )
}

export const SpokenLanguages = (props) => {
  return (
    <>
      <FieldLabel
        id='languages'
        labelName='app.boarding.education.languages'
        labelType='p2-bold'
        topSpace={1}
      />

      <Select
        addStyles={{
          menu: (base) => ({
            ...base,
            zIndex: 31
          })
        }}
        id='languages'
        shouldTranslate={false}
        {...props}
      />
    </>
  )
}
