import React, { useEffect } from 'react'
import { pathOr } from 'ramda'
import { Field, FieldArray } from 'formik'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import FormationInitValues from 'models/formation'
import { FormationSchema } from 'schemas/formation'
import Wizard from 'components/organisms/ProfileWizard'
import AddExperiencesModal from 'components/atoms/AddExperiencesModal'
import {
  FormationType,
  OtherFormationType,
  SpecificFormation,
  SpokenLanguages
} from './inputs'
import ProfileBoardingFooter from '../ProfileBoardingFooter'
import { SelectableTagGroup } from 'seniors-first-commons/components/atoms/SelectableTag'


const WizardStep = ({ children, ...props }) => children(props)

const Footer = ({ onGoBack, ...props }) => {
  return (
    <ProfileBoardingFooter
      onBack={onGoBack}
      step={4}
      {...props}
    />
  )
}

const FormationForm = ({
  comingFrom,
  matchesMobile,
  profile,
  languages,
  modalsState: {
    showAddExperiencesModal,
    setShowAddExperiencesModal
  },
  isSubmitting,
  getLanguages,
  ...props
}) => {
  const firstname = pathOr('', ['firstname'], profile)

  useEffect(() => {
    if (!languages) getLanguages()
    // eslint-disable-next-line
  }, [getLanguages])

  const onCloseAddExpModal = () => {
    setShowAddExperiencesModal(false)
  }

  const onSubmit = (values) => {
    props.updateFormation({ ...values })
      .unwrap()
      .then(() => comingFrom === 'home' ? props.moveToNextStep() : setShowAddExperiencesModal(true))
  }

  return (
    <>
      <Wizard
        comingFrom={comingFrom}
        enableReinitialize={true}
        Footer={Footer}
        initialValues={new FormationInitValues(profile)}
        loading={isSubmitting}
        onSubmit={onSubmit}
        {...props}
      >
        <WizardStep validationSchema={FormationSchema}>
          {({ parentState: { values } }) => {
            return (
              <>
                <p className='t1-bold primary has-text-centered has-padding-b-3'>
                  <FormattedMessageWithClass id='app.boarding.routes.education' />
                </p>

                <FormationType
                  formation_type={values.formation_type}
                  username={firstname}
                />

                {values.formation_type === '5'
                  ? (
                    <Field
                      component={OtherFormationType}
                      name='other_formation_type'
                    />
                  ) : null
                }

                <Field
                  component={SpecificFormation}
                  name='other_specific_formation'
                  placeholder='Max: 150'
                />

                <Field
                  component={SpokenLanguages}
                  controlShouldRenderValue={false}
                  isClearable={false}
                  isMulti
                  name='languages'
                  options={languages ? languages.map(item => ({ ...item, level: '1' })) : []}
                  placeholder=''
                />

                <FieldArray
                  name='languages'
                  render={({ remove }) => (
                    <div className='has-margin-t-7'>
                      <SelectableTagGroup
                        languages={values.languages}
                        matchesMobile={matchesMobile}
                        onDeleteLanguage={remove}
                      />
                    </div>
                  )}
                />
              </>
            )
          }}
        </WizardStep>
      </Wizard>

      <AddExperiencesModal
        isOpen={showAddExperiencesModal}
        onClose={onCloseAddExpModal}
        onConfirm={props.onConfirmFromAddNewExpModal}
        onSkip={props.onSkipAddNewExpModal}
      />
    </>
  )
}

export default FormationForm
