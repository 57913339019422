import React from 'react'
import { Formik, Form } from 'formik'
import { pathOr } from 'ramda'
import { useFormatMessage, FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import EFInitValues from 'models/experienceFeedback'
import EFSchema from 'schemas/experienceFeedback'
import {
  FirstQuestionInput,
  SecondQuestionInput,
  ThirdQuestionInput,
  FourthQuestionInput,
  SeventhQuestion,
  SixthQuestionInput,
  FifthQuestionInput
} from './inputs'
import userPhoto from 'seniors-first-commons/components/atoms/images/user.png'
import classes from './index.module.css'


const TextQuestion = ({number, text, required=false}) => {
  return (
    <p className={`t4-bold grey has-margin-t-1 ${classes.question}`}>
      <span className='has-margin-r-7'>{number}. {text}</span>
      {required ? <span className='is-size-5 danger'>*</span> : ''}
    </p>
  )
}

const Header = ({ ...props }) => {
  const photo = pathOr(userPhoto, ['caregiver', 'bio.photo'], props)

  return (
    <div className='columns has-margin-t-5 has-margin-b-1 is-vcentered'>
      <div className='column is-one-quarter'>
        <div className={`image ${classes.caregiverImage}`}>
          <img
            alt=''
            className='is-rounded'
            src={photo}
          />
        </div>
      </div>

      <div className='column is-three-quarters'>
        <p className='t3-bold grey'>
          <FormattedMessageWithClass id='carer.feedback.header.title' />
        </p>

        <p className='t1-bold primary has-margin-bt-6'>
          {props.firstname} {props.lastname}
        </p>
        <p className='p1-bold'>
          <FormattedMessageWithClass
            className='grey'
            id='carer.feedback.header.service'
          />:
          <span className='has-margin-l-7 has-text-weight-light'>
            {props.from_date}
            <FormattedMessageWithClass
              className='has-margin-lr-7'
              id='carer.feedback.header.to'
            />
            {props.to_date}
          </span>
        </p>
      </div>
    </div>
  )
}


const ExperienceFeedbackForm = ({ onFormSubmit, ...props }) => {
  const translations = {
    fifthQuestion: useFormatMessage('carer.feedback.content.fifthquestion'),
    firstquestion: useFormatMessage('carer.feedback.content.firstquestion'),
    fourthQuestion: useFormatMessage('carer.feedback.content.fourthquestion'),
    secondQuestion: useFormatMessage('carer.feedback.content.secondquestion'),
    send: useFormatMessage('carer.feedback.button.sendbutton'),
    seventhQuestion: useFormatMessage('carer.feedback.content.seventhquestion'),
    sixthQuestion: useFormatMessage('carer.feedback.content.sixthquestion'),
    thirdQuestion: useFormatMessage('carer.feedback.content.thirdquestion')
  }

  return (
    <>
      <Header {...props} />

      <Formik
        initialErrors={{ force: 'force_error' }}
        initialValues={new EFInitValues()}
        onSubmit={onFormSubmit}
        validationSchema={EFSchema}
      >
        {({ isSubmitting, isValid }) => {
          return (
            <Form>
              <>
                <TextQuestion
                  number='1'
                  required={true}
                  text={translations.firstquestion}
                />
                <div className='field-body'>
                  <div>
                    <div className='control'>
                      <FirstQuestionInput />
                    </div>
                  </div>
                </div>
              </>

              <>
                <TextQuestion
                  number='2'
                  required={true}
                  text={translations.secondQuestion}
                />
                <div className='has-margin-t-3'>
                  <div className={classes.level}>
                    <FormattedMessageWithClass
                      className='primary p1-regular'
                      id='carer.feedback.options.secondquestion.firstoption'
                    />

                    <SecondQuestionInput />

                    <FormattedMessageWithClass
                      className='primary p1-regular'
                      id='carer.feedback.options.seciondquestion.secondquestion'
                    />
                  </div>
                </div>
              </>

              <>
                <TextQuestion
                  number='3'
                  text={translations.thirdQuestion}
                />
                <div className='field-body'>
                  <div>
                    <div className='control'>
                      <ThirdQuestionInput />
                    </div>
                  </div>
                </div>
              </>

              <>
                <TextQuestion
                  number='4'
                  text={translations.fourthQuestion}
                />
                <FourthQuestionInput />
              </>

              <>
                <TextQuestion
                  number='5'
                  text={translations.fifthQuestion}
                />
                <div className='field-body'>
                  <div>
                    <div className='control'>
                      <FifthQuestionInput />
                    </div>
                  </div>
                </div>
              </>

              <>
                <TextQuestion
                  number='6'
                  text={translations.sixthQuestion}
                />
                <div className='field-body'>
                  <div>
                    <div className='control'>
                      <SixthQuestionInput />
                    </div>
                  </div>
                </div>
              </>

              <>
                <TextQuestion
                  number='7'
                  text={translations.seventhQuestion}
                />
                <SeventhQuestion />
              </>

              <button
                className={`button filled has-margin-t-1 ${isSubmitting ? 'is-loading' : ''} ${classes.submitButton}`}
                disabled={ isSubmitting || !isValid }
                type='submit'
              >
                {translations.send}
              </button>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

export default ExperienceFeedbackForm
