import React from 'react'
import useMediaQuery from 'seniors-first-commons/services/useMediaQuery'
import { BREAKPOINTS } from 'seniors-first-commons/styles/constants'

const TwoColumnsLayout = ({
  firstColumn,
  secondColumn,
  isHiddenFirstColumn=false,
  isHiddenSecondColumn=false
}) => {
  const mediaQuery = useMediaQuery()
  const matchesMobile = mediaQuery[0] <= BREAKPOINTS.MOBILE_MAX

  return (
    <div className='full-content-caregiver'>
      <div className={`container section is-fullhd ${matchesMobile ? 'has-padding-lr-7' : ''}`}>
        <div className='columns'>
          <div className={`column is-one-third-desktop is-two-fifths-tablet ${isHiddenFirstColumn ? 'is-hidden' : ''}`}>
            {firstColumn}
          </div>
          <div className={`column is-two-thirds-desktop is-three-fifths-tablet ${isHiddenSecondColumn ? 'is-hidden' : ''}`}>
            {secondColumn}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TwoColumnsLayout
