import React from 'react'
import { SwiperSlide } from 'swiper/react'
import { FormattedMessageWithClass } from 'seniors-first-commons/shared/store/translations/effects'
import DirectOffersFilterTabs from 'components/molecules/DirectOffersFilterTabs'
import GoBackOrHome from 'seniors-first-commons/components/atoms/GoBackOrHome'
import Pagination from 'seniors-first-commons/components/molecules/Pagination'
import EmptyBox from 'seniors-first-commons/components/atoms/EmptyBox'
import CustomSwiper from 'seniors-first-commons/components/atoms/Swiper'
import Pending from './Pending'
import Accepted from './Accepted'
import Rejected from './Rejected'
import Interested from './Interested'
import { ITEMS_PER_PAGE } from 'seniors-first-commons/utils/constants'
import withJobOffer from 'hoc/withJobOffer'
import { BREAKPOINTS } from 'seniors-first-commons/styles/constants'
import useMediaQuery from 'seniors-first-commons/services/useMediaQuery'


const DirectOffersCards = ({
  item,
  matchesMobile,
  selectedTab,
  selectedOffer,
  loadingInterestedReasons,
  actions
}) => {
  switch(selectedTab) {
  case 'pending':
    return (
      <Pending
        actions={actions}
        item={item}
        matchesMobile={matchesMobile}
      />
    )
  case 'accepted':
    return (
      <Accepted
        actions={actions}
        item={item}
        matchesMobile={matchesMobile}
      />
    )
  case 'interested':
    return (
      <Interested
        actions={actions}
        item={item}
        loading={loadingInterestedReasons}
        matchesMobile={matchesMobile}
        selectedOffer={selectedOffer}
      />
    )
  case 'rejected':
    return (
      <Rejected
        actions={actions}
        item={item}
        matchesMobile={matchesMobile}
      />
    )
  default:
    return null
  }
}

const DirectOffersBody = ({
  directOffers,
  selectedTab,
  loading: { loading, loadingInterestedReasons },
  actions,
  ...props
}) => {
  const mediaQuery = useMediaQuery()
  const matchesMobile = mediaQuery[0] <= BREAKPOINTS.MOBILE_MAX

  return (
    <div className='has-navbar-fixed-bottom'>
      <div className={`${matchesMobile ? 'has-padding-lr-7' : ''}`}>
        <GoBackOrHome className='is-paddingless-l has-margin-b-7' />

        <h1 className='t1-bold primary has-margin-b-6'>
          <FormattedMessageWithClass id='carer.directoffers.title' />
        </h1>

        <DirectOffersFilterTabs
          matchesMobile={matchesMobile}
          onTabChange={actions.onTabChange}
          selectedTab={selectedTab}
        />
      </div>

      {!loading ?
        directOffers.total
          ? matchesMobile
            ? <CustomSwiper
              autoplay={false}
              data={directOffers.items}
              loop={false}
            >
              {directOffers.items.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <DirectOffersCards
                      actions={actions}
                      item={item}
                      loadingInterestedReasons={loadingInterestedReasons}
                      matchesMobile={matchesMobile}
                      selectedOffer={props.selectedOffer}
                      selectedTab={selectedTab}
                    />
                  </SwiperSlide>
                )
              })}
            </CustomSwiper>
            : directOffers.items.map((item, index) => {
              return (
                <DirectOffersCards
                  actions={actions}
                  item={item}
                  key={index}
                  loadingInterestedReasons={loadingInterestedReasons}
                  matchesMobile={matchesMobile}
                  selectedOffer={props.selectedOffer}
                  selectedTab={selectedTab}
                />
              )
            })
          : <EmptyBox />
        : null
      }

      <Pagination
        currentPage={directOffers.currentPage}
        data={directOffers.total}
        itemsPerPage={ITEMS_PER_PAGE}
        onPageChange={actions.handlePageChange}
      />
    </div>
  )
}


export default withJobOffer(DirectOffersBody)
